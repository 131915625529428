import React, { Component } from "react";
import { getSynthList, downloadPatch, getSynthWhitelist } from "services/synthwsao";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  selectFilter,
  textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactHowler from "react-howler";
import ReactGA from "react-ga";
import FadeIn from "react-fade-in";
import "./Library.css";
import NotificationEngine from "engines/notification/NotificationEngine";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

class Library extends Component {
  /* REACT FUNCTIONS */
  constructor(props) {
    super(props);

    this.state = {
      synthList: [],
      preview: {
        playing: false,
        preload: false,
        url: "placeholder"
      },
      synthesizers: [{label:'place', value:'holder'}] // weird hack, don't judge me
    };

    this.downloadSynth = this.downloadSynth.bind(this);
    this.previewFormatter = this.previewFormatter.bind(this);
    this.playPreview = this.playPreview.bind(this);
  }

  componentDidMount() {
    getSynthList()
      .then(synthList => {
        this.setState({ synthList });
      })
      .catch(() => {
        NotificationEngine.error("Couldn't load the list! :(");
        NotificationEngine.error("Tweet me @deztructicus and I'll fix it! ^__^");
      });

    getSynthWhitelist().then(whitelist => {

      // convert to set to remove duplicate names
      let synthNames = new Set(
        whitelist.map(synth => synth.name[0])
      );

      // map the names to the value and label for filtering on
      let synths = [...synthNames].map(name => ({
        value: name,
        label: name
      }));

      // order the array
      synths.sort((s1, s2) => {
        let label1 = s1.label.toUpperCase();
        let label2 = s2.label.toUpperCase();
        if (label1 < label2) {
          return -1;
        }
        if (label2 > label1) {
          return 1;
        }
        // equal position (should never happen though)
        return 0;
      });
      
      this.setState({
        synthesizers: synths
      });

    }).catch((e) => {
      console.log(e)
      NotificationEngine.error("Something went wrong loading the search app :(");
      NotificationEngine.error("Tweet me @deztructicus and I'll fix it! ^__^");
    });
  }
  /* END REACT FUNCTIONS */

  /* CUSTOM FUNCTIONS */
  downloadSynth(synthInfo) {
    // fire click interaction event
    ReactGA.event({
      category: "engagement",
      action: "downloadPatch",
      label: synthInfo.name,
      value: 1
    });
    // call download API
    downloadPatch(synthInfo.s3Key).catch(() => {
      NotificationEngine.error("Oopsie doopsie! I couldn't get the file!");
      NotificationEngine.error("Tweet me @deztructicus and he'll fix this! :-)");
    });
  }

  // really need to redo this function, its trash...
  previewFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      // this syntax is LEGIT ass...

      <div>

        {row.s3KeyPreview && <Link to={`patch/${row.s3Key}/preview`} onClick={(e) => {
          e.preventDefault();
          formatExtraData.playPreview(row.s3KeyPreview);

          // fire click interaction event
          ReactGA.event({
            category: "engagement",
            action: "previewPatch",
            label: row.name,
            value: 1
          });
        }}>
          <FontAwesomeIcon icon={faVolumeUp} />
        </Link>
        }
        &nbsp;&nbsp;&nbsp;
        <Link to={`patch/${row.s3Key}`} onClick={(e) => {
          e.preventDefault();

          this.setState({
            preview: {
              playing: false,
              preload: false,
              url: "placeholder"
            }
          });

          formatExtraData.dl(row);

          // fire click interaction event
          ReactGA.event({
            category: "engagement",
            action: "previewPatch",
            label: row.name,
            value: 1
          });
        }}>
          <FontAwesomeIcon icon={faDownload} />
        </Link>
      </div>
    );
  }

  // Initializes sound player component (ReactHowler) and then plays sound
  playPreview(s3KeyPreview) {
    this.setState({
      preview: {
        preload: true,
        playing: true,
        url: process.env.REACT_APP_AWS_S3_PATCHPREVIEWBUCKET + s3KeyPreview
      }
    });
  }

  render() {
    const synthesizers = this.state.synthesizers;

    const columns = [
      {
        dataField: "name",
        text: "Name",
        filter: textFilter()
      },
      {
        dataField: "type",
        text: "Synth Type",
        filter: selectFilter({
          options: {
            pad: "pad",
            bass: "bass",
            pluck: "pluck",
            drums: "drums",
            other: "other"
          },
          caseSensitive: false
        })
      },
      {
        dataField: "synthesizer",
        text: "Synthesizer",
        filter: selectFilter({
          options: synthesizers,
          caseSensitive: false
        })
      },
      {
        dataField: "s3KeyPreview",
        text: "Actions",
        formatter: this.previewFormatter,
        formatExtraData: {
          playPreview: this.playPreview,
          dl: this.downloadSynth
        }
      }
    ];

    return (
      <FadeIn>
        <section id="synth-search">
          <div className="container">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <h2 className="page-header-title">synthLibrary</h2>
              </div>
            </div>
            <div className="widget has-shadow">
              <div className="row">
                <div className="widget-body col-12 hero-content">
                  <ReactHowler
                    src={this.state.preview.url}
                    preload={this.state.preview.preload}
                    playing={this.state.preview.playing}
                  />
                  {/* TODO Write up loading logic */}
                  <BootstrapTable
                    keyField="s3Key"
                    data={this.state.synthList}
                    columns={columns}
                    bordered={false}
                    noDataIndication={<div>There's none! Be the first to <Link to="/synthShare">upload one</Link>😎</div>}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    bootstrap4={true}
                    hover
                  />
                </div>{" "}
                {/* END col-12*/}
                <div className="col-12 mt-5" /> {/* END col-12*/}
              </div>{" "}
              {/* END row*/}
            </div>{" "}
            {/* END container*/}
          </div>{" "}
          {/* END header*/}
        </section>
      </FadeIn>
    );
  }
}

export default Library;
