import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import defaultCollectionImg from "assets/img/albums/collection_default.jpg";
import { Link } from "react-router-dom";
import "./CollectionsTab.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getUserCollection, deleteCollection } from 'services/collectionwsao';
import { FormattedMessage } from 'react-intl';
import CreateCollectionModal from 'components/collections/CreateCollectionModal';
import Modal from 'components/common/Modal';
import NotificationEngine from 'engines/notification/NotificationEngine';
import { getPatchesForUser } from 'services/synthwsao';
import EditCollectionModal from 'components/collections/EditCollectionModal';
import { withRouter } from 'react-router-dom';

class CollectionsTab extends Component {

    _isMounted = false;

    state = {
        collections: [],
        hasNext: false,
        hasPrevious: false,
        next: null,
        patches: []
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCollections();
        this.getUserPatches();
    }

    fetchCollections() {

        getUserCollection(this.props.user.username, this.state.next)
            .then(page => {
                let updatedCollections = this.state.collections;
                updatedCollections.push(...page.results);

                if (this._isMounted) {
                    this.setState({
                        collections: updatedCollections,
                        hasNext: page.hasNext,
                        hasPrevious: page.hasPrevious,
                        next: page.next,
                    });
                }
            }).catch(() => NotificationEngine.error("Couldn't load next page of collections"));
    }

    getUserPatches() {
        getPatchesForUser(this.props.user.username, null, true)
            .then(page => {
                if (this._isMounted) {
                    this.setState({
                        patches: page.results
                    });
                }
            }).catch(() => NotificationEngine.error("Something went wrong while loading your patches"));
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let collections = this.state.collections;
        let CollectionWidget = (props) => {

            let uri = "/collection/" + props._id;
            let deleteId = "delete-" + props._id;
            let editId = "edit-" + props._id;

            let removeCollection = () => {
                deleteCollection(props._id).then(() => {
                    NotificationEngine.success('Collection Deleted');

                    // TODO IMPROVE THIS this is a bad hack cuz i can't be arsed to manage state properly
                    // redirect to about page
                    this.props.history.push('about');
                }).catch(() => {
                    NotificationEngine.error("Something went wrong while deleting your collection :(");
                });

            }
            return (
                <div className="col-xl-3 col-md-4 col-sm-6">
                    <Modal
                        modalId={deleteId}
                        title="Confirm"
                        body="Are you sure you want to delete this collection?"
                        onAccept={removeCollection}
                    />
                    <EditCollectionModal {...props} patches={this.state.patches} modalId={editId} />
                    <Link to={uri}>
                        <div className="widget has-shadow">
                            <figure className="img-hover-01">
                                <img src={props.img || defaultCollectionImg} className="img-fluid collection-cover" alt="..." />
                                <div>
                                    {/* <Link to={uri + "/like"}>
                                    <span>{(props.likes && props.likes.length) || 0}</span>
                                    <i className="la la-heart-o" />
                                </Link> */}
                                    {/* <Link to="assets/img/albums/01.jpg" data-lity data-lity-desc="..."> */}
                                    <Link to={uri}>
                                        <i className="la la-expand" />
                                    </Link>

                                    {
                                        props.editable && <span>
                                            <Link to={uri + "/edit"} data-toggle="modal" data-target={"#" + editId}>
                                                <i className="la la-pencil" />
                                            </Link>
                                            <Link to={uri + "/delete"} data-toggle="modal" data-target={"#" + deleteId}>
                                                <i className="la la-trash-o" />
                                            </Link>
                                        </span>
                                    }

                                    {/* <a href="#">
                                <i className="la la-ellipsis-v" />
                            </a> */}
                                </div>
                            </figure>
                            <div className="widget-body text-center">
                                <h3 className="mt-3 mb-3"><Link to={uri}>{props.name || "Collection not named"}</Link></h3>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }

        let CollectionWidgets = () => {
            let collectionList = [];
            for (let i = 0; i < collections.length; i++) {
                collectionList.push(<CollectionWidget {...collections[i]} key={i} editable={this.props.editable} />);
            }

            return collectionList;
        }

        return (

            <FadeIn>



                {/* Begin Editing Modal */}
                <CreateCollectionModal patches={this.state.patches} />
                {/* End Editing Modal */}

                <div className="row justify-content-center">
                    <div className="col-xl-12">

                        {this.props.editable && <div className="page-header pr-0 pl-0">
                            <div className="d-flex align-items-center">
                                <div className="page-header-tools">
                                    <button type="button" className="btn btn-gradient-01" data-toggle="modal" data-target="#create-collection-modal" >New Collection</button>

                                </div>
                            </div>
                        </div>}

                        <div className="row">
                            <CollectionWidgets />
                        </div>
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={this.state.collections.length}
                    next={this.fetchCollections.bind(this)}
                    hasMore={this.state.hasNext}
                    loader={
                        <FadeIn>
                            <div className="col-xl-12">
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Loading...</h4>
                                    </div>
                                </div>
                            </div>
                        </FadeIn>}

                    endMessage={
                        // kinda messy but conditionally display a different message if there's nothing
                        this.state.collections.length === 0 ? <div className="col-xl-12">
                            <div className="widget has-shadow">
                                <div className="widget-header bordered no-actions d-flex align-items-center">
                                    <h4>The homie has no collections</h4>
                                </div>
                            </div>
                        </div> :
                            <div className="col-xl-12">
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4><FormattedMessage id="ProfilePage.nomorepatch" defaultMessage="No more to show! {emoji}" values={{ emoji: <span role="img" aria-label="smile">🙂</span> }} /></h4>
                                    </div>
                                </div>
                            </div>
                    }>

                </InfiniteScroll>
            </FadeIn>
        );
    }
}

export default withRouter(CollectionsTab);