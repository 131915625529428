import TimeFormat from "hh-mm-ss";
import React from "react";

function StartEndControls(props) {
    const song_start = props.start;
    const song_end = props.end;
    return (
        <div className="form-group row d-flex align-items-center mb-5">
            <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">
                <button className="btn btn-primary" onClick={(e) => {
                    e.preventDefault();
                    props.player.seekTo(song_start);
                }}>Jump To Start </button>
            </label>
            <div className="col-lg-2">
                <input type="text" value={TimeFormat.fromS(Math.floor(song_start), 'mm:ss')}
                       className="form-control" name="start" placeholder="start" required readOnly />
            </div>
            <label className="col-lg-2  form-control-label d-flex justify-content-lg-end">
                <button className="btn btn-primary" onClick={(e) => {
                    e.preventDefault();
                    props.player.seekTo(song_end - 2);
                }}>Jump To End </button>
            </label>
            <div className="col-lg-2">
                <input type="text" value={TimeFormat.fromS(Math.floor(song_end), 'mm:ss')}
                       className="form-control" name="end" placeholder="end" required readOnly />
            </div>
        </div>
    );
}

export default StartEndControls
