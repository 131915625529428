import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import { language } from 'config/localization';
// import { Link } from 'react-router-dom';
import ProfilePhoto from 'components/common/ProfilePhoto';

let ChallengeWidget = (props) => {

    let { user, requestedBy, title, recipes, _id, date_created } = { ...props.challenge };
    let { review, solve } = { ...props };

    // action when this button is clicked
    let action = 'solve';
    if (!recipes) {
        recipes = []
    }

    if (solve) {
        action = 'solve';
    } else if (review) {
        action = 'review';
    }

    let widget = <div className="widget has-shadow">
        <div className="widget-header d-flex align-items-center">
            <div className="user-image">{user && <ProfilePhoto user={user} width={55} clickable />}</div>

            <div className="d-flex flex-column mr-auto">
                <div className="title">
                    {/* <Link to={"/challenge/solve/" + _id}><span className="username">{title}</span></Link> */}
                    <a href={"/challenge/" + action + "/" + _id}><span className="username">{title}</span></a>
                </div>
                <div>{requestedBy}</div>
                <div className="time">{recipes.length} {recipes.length !== 1 ? "Recipes" : "Recipe"}</div>
                <div className="time">{date_created && <ReactTimeAgo date={new Date(date_created)} locale={language} />}</div>
            </div>

        </div>

    </div>
    return widget;

}

export default ChallengeWidget;