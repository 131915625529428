import axios from 'axios';
const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

/**
 * Loads trending users from API
 */
export async function getTrendingUsers() {

    let url = `${uri}trending/users`;

    try {
        // call notification access endpoint
        let citizens = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return citizens.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getTopSharers() {

    let url = `${uri}trending/sharers`;

    try {
        // call notification access endpoint
        let citizens = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return citizens.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getTopHelpers() {

    let url = `${uri}trending/helpers`;

    try {
        // call notification access endpoint
        let citizens = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return citizens.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}