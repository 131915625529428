export const SET_LANGUAGE = "settings:setLanguage";

export const setLanguage = languageCode => {
    return {
        type: SET_LANGUAGE,
        payload: {
            settings: {
                lang: languageCode
            }
        }
    };
};

export const onLanguageChange = languageCode => {
    return setLanguage(languageCode);
};