import React, { Component } from 'react';
import logo from 'assets/img/alt-logo.png';
import { logout as sessionLogout, userSignedIn, getCurrentUser } from "../../services/authentication-wsao";
import { connect } from "react-redux";
import { logout } from "actions/user-actions";
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import FadeIn from 'react-fade-in';

class LoginPage extends Component {

    state = {
        termsAccepted: false
    }

    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        let loginUrl = `https://${process.env.REACT_APP_AWS_AppWebDomain}/login?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AWS_RedirectUriSignIn}`;
        let signupURL = `https://${process.env.REACT_APP_AWS_AppWebDomain}/signup?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AWS_RedirectUriSignIn}`;

        let createAccountButtonClassnames = classnames("btn", "btn-lg", "btn-gradient-01", { "disabled": !this.state.termsAccepted });


        return (
            // if the user is logged in already, turn them over to front page
            userSignedIn() ? <Redirect to={this.props.redirect || "/"} /> :
                <FadeIn>
                    <div className="container-fluid no-padding h-100">
                        <div className="row flex-row h-100 bg-white">
                            {/* Begin Left Content */}

                            <div className="col-xl-8 col-lg-6 col-md-5 no-padding">
                                <div className="elisyam-bg background-06">
                                    <div className="elisyam-overlay overlay-06" />
                                    <div className="authentication-col-content mx-auto">
                                        <h1 className="gradient-text-01">
                                            The Community That's Revolutionizing Synthesizers
                                    </h1>
                                        <span className="description">
                                            Share Presets - Practice Sound Design. <a href="https://www.synthcity.io" target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-gradient-01" >How does it work? <span aria-label="surprised" role="img">🤔</span></a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* End Left Content */}
                            {/* Begin Right Content */}
                            <div className="col-xl-4 col-lg-6 col-md-7 my-auto no-padding">
                                {/* Begin Form */}
                                <div className="authentication-form mx-auto">
                                    <div className="logo-centered">
                                        {/* <a href="db-default.html"> */}
                                        <img src={logo} alt="logo" />
                                        {/* </a> */}
                                    </div>
                                    {/* <h3>Create An Account</h3>
                    <form>
                        <div className="group material-input">
                        <input type="text" required />
                        <span className="highlight" />
                        <span className="bar" />
                        <label>Email</label>
                        </div>
                        <div className="group material-input">
                        <input type="password" required />
                        <span className="highlight" />
                        <span className="bar" />
                        <label>Password</label>
                        </div>
                        <div className="group material-input">
                        <input type="password" required />
                        <span className="highlight" />
                        <span className="bar" />
                        <label>Confirm Password</label>
                        </div>
                    </form> */}
                                    <div className="row">
                                        <div className="col text-left">
                                            <div className="styled-checkbox">
                                                <input type="checkbox" name="termsAccepted" id="agree" onChange={this.handleChange.bind(this)} />
                                                <label htmlFor="agree">I have read and accept the <a href="https://www.mazinomedia.com/terms-privacy" data-lity> Terms and Conditions</a> </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-btn text-center">
                                        <a href={signupURL} className={createAccountButtonClassnames}>
                                            Create an account
                                        </a>
                                    </div>
                                    <div className="register">
                                        Already have an account?
                                        <br />
                                        <button className="btn btn-sm btn-gradient-03" onClick={(e) => {

                                            e.preventDefault();
                                            let hasCurrentUser = false;

                                            if (getCurrentUser()) {
                                                hasCurrentUser = true;
                                            }
                                            if (userSignedIn() || hasCurrentUser) {
                                                this.props.onLogoutRequest();
                                                sessionLogout();
                                            }
                                            else {
                                                window.open(loginUrl, "_self");
                                            }
                                        }}>Sign In</button>
                                    </div>
                                </div>
                                {/* End Form */}
                            </div>
                            {/* End Right Content */}
                        </div>
                        {/* End Row */}
                    </div>
                </FadeIn>
        );
    }
}

const mapActionsToProps = {
    onLogoutRequest: logout
}

export default connect(null, mapActionsToProps)(LoginPage);