import React, { Component } from 'react';
import ProfilePhoto from 'components/common/ProfilePhoto';
import defaultCover from 'assets/img/background/05.jpg';
import { Link } from 'react-router-dom';
import "./UserWidget.css";
import { loadUser } from 'services/userwsao';
import ShowMoreText from 'react-show-more-text';

class UserWidget extends Component {

    state = {
        user: {}
    }


    _isMounted = false;

    componentDidMount() {


        this._isMounted = true;
        if (this.props.user)
            return;

        loadUser(this.props.username)
            .then(user => {
                this._isMounted && this.setState({ user });
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let user = this.props.user || this.state.user;

        return (

            <div className={"col-xl-3 col-md-8 col-sm-6 col-remove"}>
                <div className="widget-image has-shadow">
                    <div className="contact-card-2">
                        <div className="cover-bg ">
                            <img src={defaultCover} className="img-fluid profileCover" alt={user.username + "'s profile"} />
                        </div>
                        {/* Begin Widget Body */}
                        <div className="widget-body">
                            {/* <div className="quick-actions hover">
                            <div className="dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle">
                                    <i className="la la-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <a href="#" className="dropdown-item remove">
                                        <i className="la la-trash" />Delete
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        <i className="la la-edit" />Edit
                                    </a>
                                </div>
                            </div>
                        </div> */}
                            <div className="cover-image mx-auto rythm-bass">
                                {user.profilePhoto && <ProfilePhoto user={user} />}
                            </div>
                            <h4 className="name rythm-high kern1"><Link to={"/citizen/" + user.username}>{user.displayName}</Link></h4>
                            <div className="job">@{user.username}</div>
                            <div className="social-friends owl-carousel mb-3">
                                <div className="item">
                                    <div className="stats">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col">
                                                <span className="counter">{user.rep ? user.rep : 0}</span>
                                                <span className="text">Rep</span>
                                            </div>
                                            <div className="col">
                                                <span className="counter">{user.shares ? user.shares : 0}</span>
                                                <span className="text">{(user.shares && user.shares.length !== 1) || !user.shares ? "Shares" : "Share"}</span>
                                            </div>
                                            <div className="col">
                                                <span className="counter">{user.badges ? user.badges.length : 0}</span>
                                                <span className="text">{(user.badges && user.badges.length !== 1) || !user.badges ? "Badges" : "Badge"}</span>
                                            </div>
                                            {/* <div className="col">
                                            <span className="counter">997</span>
                                            <span className="text">Videos</span>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="quick-about">
                                        <div className="row d-flex align-items-center blur1">
                                            {user.generalInfo && <div className="col-12">
                                                <h4>About</h4>
                                                <p>
                                                    <ShowMoreText lines={2}>{user.generalInfo.aboutMe}</ShowMoreText>
                                                </p>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center pt-5 pb-3 rythm-high vanish1">
                                <Link to={"/citizen/" + user.username} className="btn btn-gradient-01">Profile</Link>
                                {/* <a href="app-chat.html" className="btn btn-shadow">Message</a> */}
                            </div>
                        </div>
                        {/* End Widget Body */}
                    </div>
                </div>

            </div>
        );
    }
}

export default UserWidget;