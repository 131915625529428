import React, { Component } from 'react';
import { Switch, Link, Route, Redirect } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import Footer from 'components/common/Footer';
import ReactGA from 'react-ga';
import UserDetailsWidget from './UserDetailsWidget';
import BadgeCollectionWidget from './BadgeCollectionWidget';
import UploadHistoryTab from './UploadHistoryTab';
import { FormattedMessage } from 'react-intl';
import ProfilePhoto from 'components/common/ProfilePhoto';
import CollectionsTab from './CollectionsTab';
import FriendsTab from './FriendsTab';
import TitleController from 'components/common/TitleController';

class ProfilePage extends Component {

    render() {


        const AboutTab = () => {
            return (
                <FadeIn>
                    <div className="row flex-row">
                        {/* This is the widget on left side */}
                        <UserDetailsWidget user={this.props.user} />
                        {/* Begin Column */}
                        <div className="col-xl-7 column">
                            {/* Begin Education */}
                            {/* End Education */}


                            {/* Begin Eployement */}
                            {/* End Eployement */}



                            {/* Begin Badge Collection (previously Hobbies & Interest) */}
                            <BadgeCollectionWidget user={this.props.user} />
                            {/* End Hobbies & Interest */}
                        </div>
                        {/* End Column */}
                    </div>
                </FadeIn>
            );
        }

        const HeadProfile = () => {
            return (
                <div className="widget head-profile has-shadow">
                    <div className="widget-body pb-0">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-4 col-md-4 d-flex justify-content-lg-start justify-content-md-start justify-content-center">
                                <ul>
                                    <li>
                                        <div className="counter">{this.props.user.rep ? this.props.user.rep : 0}</div>
                                        <div className="heading">Rep</div>
                                    </li>
                                    <li>
                                        <div className="counter">{this.props.user.shares ? this.props.user.shares : 0}</div>
                                        <div className="heading">{(this.props.user.shares && this.props.user.shares.length !== 1) || !this.props.user.shares ? "Shares" : "Share"}</div>
                                    </li>
                                    <li>
                                        <div className="counter">{this.props.user.badges ? this.props.user.badges.length : 0}</div>
                                        <div className="heading">{(this.props.user.badges && this.props.user.badges.length !== 1) || !this.props.user.badges ? "Badges" : "Badge"}</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-md-4 d-flex justify-content-center">
                                <div className="image-default">
                                    <ProfilePhoto user={this.props.user} />
                                </div>
                                <div className="infos">
                                    <h2>{this.props.user.displayName}</h2>
                                    <div className="location">@{this.props.user.username}</div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 d-flex justify-content-lg-end justify-content-md-end justify-content-center">

                                {/* This is a giant disgusting if statement to control functionality based on who is viewing page */}
                                {this.props.preview ?

                                    /* Show this block if viewing another person's page */
                                    <div className="follow">
                                        {/* <Link className="btn btn-shadow" to="#"><i className="la la-user-plus" />Follow</Link> */}
                                        {/* <Link className="btn btn-shadow" to={this.props.user.username} onClick={() => {
                                           
                                        }}><i className="la la-user-plus" />Follow</Link> */}
                                        <div className="actions dark">
                                            {/* <div className="dropdown">
                                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle">
                                                <i className="la la-ellipsis-h" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <Link to="#" className="dropdown-item">
                                                    Report
                                                                </Link>
                                                <Link to="#" className="dropdown-item">
                                                    Block
                                                                </Link>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                    /* End preview block */
                                    :
                                    /* show this block if viewing your own page */
                                    <div className="follow">
                                        {/* <Link className="btn btn-shadow" to="#"><i className="la la-user-plus" />Follow</Link> */}
                                        <Link className="btn btn-shadow" to="/profile/edit" onClick={() => {
                                            ReactGA.event({
                                                category: 'Social',
                                                action: 'User Edited Profile',
                                                value: 1
                                            });
                                        }}><i className="la la-user-plus" /><FormattedMessage id="ProfilePage.editprofile" defaultMessage="Edit Profile" /></Link>
                                        <div className="actions dark">
                                            {/* <div className="dropdown">
                                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle">
                                                <i className="la la-ellipsis-h" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <Link to="#" className="dropdown-item">
                                                    Report
                                                                </Link>
                                                <Link to="#" className="dropdown-item">
                                                    Block
                                                                </Link>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                }{/* End disgusting if statement  */}

                            </div>
                        </div>
                        <ul className="head-nav nav nav-tabs justify-content-center">
                            {/* <li><Link to="db-social.html">Timeline</Link></li> */}
                            <li><Link to={`/citizen/${this.props.user.username}/uploads`}><FormattedMessage id="ProfilePage.uploads" defaultMessage="Uploads" /> </Link></li>

                            <li><Link className="active" to={`/citizen/${this.props.user.username}/about`}><FormattedMessage id="ProfilePage.about" defaultMessage="About" /></Link></li>
                            <li><Link className="active" to={`/citizen/${this.props.user.username}/collections`}>Collections</Link></li>

                            {/* <li><Link to="pages-friends.html">Following</Link></li> */}
                            {/* <li><Link to="friends">Friends</Link></li> */}
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <FadeIn>
                <TitleController title={this.props.user.username + "'s profile"} />
                <div className="page about">
                    {/* Begin Content */}
                    {/* Begin Jumbotron */}
                    <div className="jumbotron jumbotron-fluid" />
                    {/* End Jumbotron */}
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-11">
                                {/* Deets, badge collection etc */}
                                <HeadProfile />
                                <Switch>

                                    {/* Default Tab (currently uploads) */}
                                    <Route exact path={`/citizen/${this.props.user.username}/`} render={() => <Redirect to={`/citizen/${this.props.user.username}/uploads`} />} />

                                    {/* Uploads Tab and History */}
                                    <Route path={`/citizen/${this.props.user.username}/uploads`} render={() => <UploadHistoryTab user={this.props.user} editable={!this.props.preview} />} />

                                    <Route exact path={`/citizen/${this.props.user.username}/about`} render={() => <AboutTab />} />
                                    <Route exact path={`/citizen/${this.props.user.username}/collections`} render={() => <CollectionsTab user={this.props.user} editable={!this.props.preview} /> } />


                                    <Route exact path={`/citizen/${this.props.user.username}/friends`} render={() => <FadeIn><FriendsTab /> </FadeIn>} />

                                </Switch>
                                {/* End Row */}
                            </div>
                            {/* End Col */}
                        </div>
                        {/* End Row */}
                    </div>
                    {/* End Container */}
                    <Footer />
                    <Link to="#" className="go-top"><i className="la la-arrow-up" /></Link>
                </div>
                {/* End Content */}
            </FadeIn>
        );
    }
}

export default ProfilePage;