import React from 'react';
import { Link } from 'react-router-dom'

function Header(props) {

    let { text } = { ...props }
    return (

        <div className="row">
            <div className="page-header">
                <div className="d-flex align-items-center">
                    <h2 className="page-header-title">{text}</h2>
                    <div>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/"><i className="ti ti-home" /></Link></li>
                            <li className="breadcrumb-item active">{text}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;