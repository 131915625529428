import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';
const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

export async function createChallenge(payload) {

    try {
        let url = `${uri}challenge`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call request access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: payload
        });

        return res.data.id;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getChallenge(id = "random", hideUserCreated = true) {


    try {
        let url = `${uri}challenge/${id}?hideUserCreated=${hideUserCreated}`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function editChallenge(id, request) {


    try {
        let url = `${uri}challenge/${id}`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call request edit access endpoint
        let res = await axios({
            url: url,
            method: 'PATCH',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: request
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getChallengesForUser(username) {

    try {
        let url = `${uri}user/${username}/challenges/created`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}
export async function getSuggestedChallengesForUser(username) {

    try {
        let url = `${uri}user/${username}/challenges/suggested`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getSolvedChallengesForUser(username) {

    try {
        let url = `${uri}user/${username}/challenges/solved`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function associateChallengeWithPatch(id, patchId) {

    try {
        let url = `${uri}challenge/${id}/solution/`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call request access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: { patchId }
        });

        return res.data.id;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function submitReview(id, patchId, isCorrect) {

    try {
        let url = `${uri}challenge/${id}/solution/review`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call request access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: { isCorrect, patchId }
        });

        return res.data.id;
    } catch (e) {
        console.log(e);
        throw e
    }
}
