import React, { Component } from 'react';
import Select from 'react-select'
import NotificationEngine from 'engines/notification/NotificationEngine';
import { createCollection } from 'services/collectionwsao';
import { withRouter } from 'react-router-dom';

class CreateCollectionModal extends Component {


    state = {
        name: "",
        file: {},
        description: "",
        submitted: false,
        submitting: false
    }


    patchSelectComponent = React.createRef();
    closeButton = React.createRef();

    reset = () => {
        this.setState({
            name: '',
            file: {},
            description: '',
        });
        this.patchSelectComponent.current.state.value = [];
    }

    submit = () => {

        let patches = this.patchSelectComponent.current.state.value;
        let patch_ids = [];
        let valid = true;

        /* Begin validations */

        if (this.state.submitted) {
            NotificationEngine.warn("Can't submit twice");
            return;
        }
        if (!this.state.name) {
            valid = false;
            NotificationEngine.error("Name your collection homie!");
        }

        if (!patches || patches.length < 1) {
            valid = false;
            NotificationEngine.error("Can't create an empty collection homie!")
        }
        /* END VALIDATIONS */

        if (valid) {

            // extra s3key (id) names from patches
            for (let i = 0; i < patches.length; i++) {
                patch_ids.push(patches[i].value);
            }

            let payload = { ...this.state, patch_ids }

            this.setState({ submitting: true })

            // submit collection to api
            createCollection(payload).then((res) => {

                // prevent further submission
                this.setState({ submitted: true, submitting: false });
                NotificationEngine.success("Donezo!");


                // close modal window
                this.closeButton.current.click();

                // redirect to collection page
                this.props.history.push('/collection/' + res.id);

            }).catch((e) => {
                this.setState({ submitting: false });
                if (e.response && e.response.status === 403) {
                    NotificationEngine.warn("Max collections reached");
                } else {

                    NotificationEngine.error('Something went wrong creating your collection :(');
                }
            })
        }
    }

    handleFile = (e) => {
        if (e.target.files[0]) {
            this.setState({
                file: e.target.files[0]
            });
        }
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        let patches = [];

        for (let i = 0; i < this.props.patches.length; i++) {
            patches.push({ value: this.props.patches[i].s3Key, label: this.props.patches[i].name })
        }

        return (

            <div id="create-collection-modal" className="modal fade">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">New Collection</h4>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="needs-validation" noValidate>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Name *</label>
                                    <div className="col-lg-5">
                                        <input type="text" value={this.state.name} onChange={this.handleChange.bind(this)} className="form-control" name="name" placeholder="collection name" required />
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Collection Art</label>
                                    <div className="col-lg-5">
                                        <input type="file" onChange={this.handleFile} name="file" accept=".jpg,.jpeg" />
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Description</label>
                                    <div className="col-lg-5">
                                        <textarea rows="7" type="text" value={this.state.description} onChange={this.handleChange.bind(this)} className="form-control" name="description" placeholder="describe your collection" required />
                                    </div>
                                </div>


                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Patches *</label>
                                    <div className="col-lg-5">
                                        <Select options={patches} isMulti name="patches" ref={this.patchSelectComponent} />
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-shadow" data-dismiss="modal" onClick={this.reset.bind(this)} ref={this.closeButton}>Close</button>
                            <button type="button" className="btn btn-gradient-01" disabled={this.state.submitting} onClick={this.submit.bind(this)}>Create</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(CreateCollectionModal);