import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import BadgeEngine from 'engines/BadgeEngine';

// import { Link } from 'react-router-dom';

class BadgeCollectionWidget extends Component {
    render() {

        let BadgeList = function (props) {

            let list = <span>No badges yet! Clearly not awesome enough... <span role="img" aria-label="thinking emoji">🤔</span></span>;

            if (props.badges && props.badges.length > 0) {
                list = [];

                for (let i = 0; i < props.badges.length; i++) {

                    let icon = BadgeEngine.getBadgeImageByName(props.badges[i].name);

                    if (icon) {
                        list.push(<div className="hobbies" key={props.badges[i].name + '_' + i}>
                            <img src={icon} style={{ width: "80px" }} alt={props.badges[i].name + " badge"} />
                        </div>)
                    }
                }
            }

            return list;
        }
        return (
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Badges<sup className="text-danger"><strong>new!</strong></sup></h4>
                </div>
                <div className="widget-body">
                    <div className="d-flex justify-content">
                        <FadeIn>
                            <BadgeList badges={this.props.user.badges} />
                        </FadeIn>
                        {/* <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Photography">
                                    <i className="ion-camera" />
                                </Link>
                            </div>
                        </div>
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Music">
                                    <i className="ion-headphone" />
                                </Link>
                            </div>
                        </div>
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Travel">
                                    <i className="ion-map" />
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="d-flex justify-content-center">
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Food">
                                    <i className="ion-icecream" />
                                </Link>
                            </div>
                        </div>
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Paint">
                                    <i className="ion-paintbrush" />
                                </Link>
                            </div>
                        </div>
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Nature">
                                    <i className="ion-leaf" />
                                </Link>
                            </div>
                        </div>
                        <div className="hobbies">
                            <div className="hobbies-icon">
                                <Link to="#" title="Coffee">
                                    <i className="ion-coffee" />
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default BadgeCollectionWidget;