import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { FormattedMessage } from 'react-intl';
import Feed from './AllFeeds';
import PatronEasterEgg from 'components/eastereggs/PatronEasterEgg';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import UserWidget from 'components/profile/UserWidget';
import { withRouter } from 'react-router-dom';
import ProfilePhoto from 'components/common/ProfilePhoto';
import { getTopSharers, getTopHelpers } from 'services/trending-wsao';
const abbreviate = require('number-abbreviate');

class LandingPage extends Component {

    _isMounted = false;
    state = {
        showPatronEasterEgg: true,
        sharerLeaderboard: {},
        helperLeaderboard: {}
    }

    componentDidMount() {

        this._isMounted = true;
        getTopSharers().then((sharerLeaderboard) => {

            this._isMounted && this.setState({ sharerLeaderboard })
        }).catch();


        getTopHelpers().then((helperLeaderboard) => {

            this._isMounted && this.setState({ helperLeaderboard })
        }).catch();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {

        const InfluenceStats = (props) => {

            let { user } = { ...props };
            let { rep = 0, shares = 0, challengeStats } = { ...user };
            let { totalSolved = 0 } = { ...challengeStats };

            return (

                <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
                    <div className="widget widget-16 has-shadow">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
                                    <div className="counter">{abbreviate(rep, 2)}</div>
                                    <div className="total-views">Rep</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="widget widget-16 has-shadow">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
                                    <div className="counter">{shares}</div>
                                    <div className="total-views">Patches Shared</div>
                                    <div> <Link className="btn mt-3 mb-3 btn-gradient-01" to="/challenge/create?t=self">Share Recipe</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="widget widget-16 has-shadow">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
                                    <div className="counter">{totalSolved}</div>
                                    <div className="total-views">Citizens Helped</div>
                                    <div> <Link className="btn mt-3 mb-3 btn-gradient-01" to="/challenge/solve/random">Solve Challenge</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const LeaderBoard = (props) => {

            let { title, topThisWeek = {}, topAllTime = [{ challengeStats: {} }, { challengeStats: {} }, { challengeStats: {} }], suffix = '', type = "share" } = { ...props };

            let stat1 = type === "share" ? topAllTime[0].shares : topAllTime[0].challengeStats.totalSolved;
            let stat2 = type === "share" ? topAllTime[1].shares : topAllTime[1].challengeStats.totalSolved;
            let stat3 = type === "share" ? topAllTime[2].shares : topAllTime[2].challengeStats.totalSolved;

            return <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <div className="widget widget-18 has-shadow">
                    {/* Begin Widget Header */}
                    <div className="widget-header bordered d-flex align-items-center">
                        <h2>{title}</h2>
                        <div className="widget-options">
                            {/* <div className="dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle">
                                    <i className="la la-ellipsis-h" />
                                </button>
                                <div className="dropdown-menu">
                                    <a href="app-chat.html" className="dropdown-item">
                                        <i className="la la-comments" />New Message
                        </a>
                                    <a href="#" className="dropdown-item">
                                        <i className="la la-bell-slash" />Disable Alerts
                        </a>
                                    <a href="#" className="dropdown-item">
                                        <i className="la la-edit" />Edit Widget
                        </a>
                                    <a href="#" className="dropdown-item faq">
                                        <i className="la la-question-circle" />FAQ
                        </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* End Widget Header */}
                    <div className="widget-body">
                        {/* <div className="input-group">
                            <span className="input-group-addon pr-0 pl-0">
                                <a className="btn" href="#">
                                    <i className="la la-search la-2x" />
                                </a>
                            </span>
                            <input type="text" className="form-control no-ppading-right no-padding-left" placeholder="Search" />
                        </div> */}
                        {topThisWeek.username && <div className="new-message">
                            <div className="media">
                                <div className="media-left align-self-center mr-3">
                                    <ProfilePhoto user={topThisWeek} clickable />
                                </div>
                                <div className="media-body align-self-center">
                                    <div className="new-message-sender">{topThisWeek.username}</div>
                                    <div className="new-message-time">Top this week</div>
                                </div>
                                {/* <div className="media-right align-self-center">
                                    <div className="actions">
                                        <a href="app-chat.html"><i className="la la-reply reply" /></a>
                                    </div>
                                </div> */}
                            </div>
                        </div>}
                        <ul className="list-group w-100 mt-5">
                            <li className="list-group-item">
                                <div className="other-message">
                                    <div className="media">
                                        <div className="media-left align-self-center mr-3">
                                            <ProfilePhoto user={topAllTime[0]} clickable />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <div className="other-message-sender">{topAllTime[0].username}</div>
                                            <div className="other-message-time">#1 of all time</div>
                                            {type === "share" && <div className="other-message-time">{stat1 + " " + suffix}</div>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="other-message">
                                    <div className="media">
                                        <div className="media-left align-self-center mr-3">
                                            <ProfilePhoto user={topAllTime[1]} clickable />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <div className="other-message-sender">{topAllTime[1].username}</div>
                                            <div className="other-message-time">#2 of all time</div>
                                            {type === "share" && <div className="other-message-time">{stat2 + " " + suffix}</div>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="other-message">
                                    <div className="media">
                                        <div className="media-left align-self-center mr-3">
                                            <ProfilePhoto user={topAllTime[2]} clickable />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <div className="other-message-sender">{topAllTime[2].username}</div>
                                            <div className="other-message-time">#3 of all time</div>
                                            {type === "share" && <div className="other-message-time">{stat3 + " " + suffix}</div>}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        }

        const EyeCatcher = () => {


            let sharerStats = this.state.sharerLeaderboard || {};
            let helperStats = this.state.helperLeaderboard || {};

            return (
                <FadeIn>

                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="row justify-content-md-center">

                                    <InfluenceStats user={this.props.user} />
                                    <UserWidget username={this.props.user.username} />
                                    <LeaderBoard title="Sharers Leaderboard" topThisWeek={sharerStats.topThisWeek} topAllTime={sharerStats.topAllTime} suffix="Presets shared" type="share" />
                                    <LeaderBoard title="Helpers Leaderboard" topThisWeek={helperStats.topThisWeek} topAllTime={helperStats.topAllTime} suffix="Citizens helped" type="help" />
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeIn>);
        }

        const PageTitle = () => {
            return (
                <div className="page-header">
                    <div className="d-flex align-items-center">
                        <h1 className="page-header-title"><FormattedMessage
                            id="Home.header"
                            defaultMessage="Welcome synthCitizen!"
                        /></h1>
                    </div>
                </div>);
        }

        let user = this.props.user;
        return (
            <FadeIn>
                <section className="container-fluid">
                    {this.props.showPatronEasterEgg && <PatronEasterEgg />}
                    <PageTitle />
                    {/* <Welcome name={name} /> */}
                    <EyeCatcher />
                </section>
                <Feed user={user} />
            </FadeIn>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        showPatronEasterEgg: state.easterEggs.day1
    }
};

export default connect(mapStateToProps)(withRouter(LandingPage));