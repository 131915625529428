import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
// import { FormattedMessage } from "react-intl";
import { editPatch } from "../../services/synthwsao";
import ReactGA from "react-ga";
import NotificationEngine from "engines/notification/NotificationEngine";
import { FormattedMessage } from 'react-intl';

class PatchEditModal extends Component {

  acceptedPreviewFormats = ".mp3";

  state = {
    isSubmitting: false,
    _id: this.props.patch._id,
    name: this.props.patch.name,
    type: this.props.patch.type,
    s3Key: this.props.patch.s3Key,
    synthesizer: this.props.patch.synthesizer,
    description: this.props.patch.description,
    previewFile: {}
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePreviewFile = e => {
    if (e.target.files[0]) {
      this.setState({
        previewFile: e.target.files[0]
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    let invalid = false;
    if (this.state.type === "") {
      NotificationEngine.error(<FormattedMessage id="SynthShare.select" defaultMessage='Select a patch type' />)
      invalid = true;
    }
    if (this.state.name === "") {
      NotificationEngine.error(<FormattedMessage id="SynthShare.noname" defaultMessage="Please input a name for the patch" />);
      invalid = true;
    }
    if (Object.keys(this.state.previewFile).length === 0 && this.state.previewFile.constructor === Object) {
      // do nothing (omg this is shit code lol)
    }
    else if (!(this.acceptedPreviewFormats.toUpperCase() === "." + this.state.previewFile.name.split(".")[1].toUpperCase())) {
      NotificationEngine.error("You can only upload previews in mp3 format");
      invalid = true;
    }

    if (invalid) return;


    this.setState({ isSubmitting: true });

    // DEPRECATED
    // let isUploadingToastID = toast.info(
    //   <FormattedMessage
    //     id="SynthShare.editing"
    //     defaultMessage="Editing your patch, please wait..."
    //   />,
    //   { autoClose: false }
    // );

    NotificationEngine.info("Editing your preset, please wait...");

    let patchInfo = {
      name: this.state.name,
      type: this.state.type,
      synthesizer: this.state.synthesizer,
      uploadedBy: this.props.patch.uploadedBy,
      filetype: this.props.patch.filetype,
      description: this.state.description
    };

    editPatch(this.state.s3Key, patchInfo, this.state.previewFile)
      .then(() => {
        this.setState({
          isSubmitting: false
        });

        this.props.onPatchChange({ ...this.props.patch, ...patchInfo, });

        // DEPRECATED
        // toast(
        //   <h2>
        //     <FormattedMessage
        //       id="SynthShare.updated"
        //       defaultMessage="Preset updated!"
        //     />
        //   </h2>,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //     className: "db-social"
        //   }
        // );

        NotificationEngine.success({ text: "Preset updated!", clearOthers: true });

        ReactGA.event({
          category: "engagement",
          action: "editPatch",
          label: this.state.name,
          value: 1
        });
      })
      .catch(() => {

        // DEPRECATED
        // toast.error(
        //   <FormattedMessage
        //     id="SynthShare.error"
        //     defaultMessage="Yikes! Could not edit the patch!"
        //   />
        // );

        NotificationEngine.err("Yikes! Could not edit the preset!");
        this.setState({ isSubmitting: false });
      });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.patch !== nextProps.patch) {
      this.setState(nextProps.patch);
    }
  }

  render() {
    const { show, toggle } = this.props;
    return (
      show && (
        <Modal isOpen={show} toggle={toggle} centered={true}>
          <ModalHeader tag="h3" toggle={toggle}>
            Edit Preset
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="previewFile">Preview</Label> <br />
                <input type="file" onChange={this.handlePreviewFile} name="previewFile" accept={this.acceptedPreviewFormats} required />
              </FormGroup>
              <FormGroup>
                <Label for="type">Type</Label>
                <Input
                  type="select"
                  name="type"
                  value={this.state.type}
                  onChange={this.handleChange}
                >
                  <option value="pad">Pad</option>
                  <option value="bass">Bass</option>
                  <option value="pluck">Pluck</option>
                  <option value="drums">Drums</option>
                  <option value="lead">Lead</option>
                  <option value="other">Other</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="type">Description</Label>
                <Input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-shadow mb-3 btn-primary"
              data-dismiss="modal"
              onClick={this.handleSubmit}
              disabled={this.state.isSubmitting}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>
      )
    );
  }
}

export default PatchEditModal;
