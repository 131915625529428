import Noty from "noty";
import neutral from 'assets/audio/sfx/download.mp3';
import negative from 'assets/audio/sfx/error.mp3'
import positive from 'assets/audio/sfx/success.mp3';
import upload from 'assets/audio/sfx/upload.mp3';

const defaultSoundOnShow = neutral;
const defaultSoundOnHide = "https://www.myinstants.com/media/sounds/roblox-death-sound-effect.mp3";

const showNotification = ({ text, timeout = 3000, type = "info", layout = "topRight",
  soundOnShow = defaultSoundOnShow, soundOnHide = defaultSoundOnHide, killer = false }) => {
  let sounds = {
    sources: [soundOnShow, soundOnHide],
    volume: 0.2,
    conditions: ["docVisible", "docHidden"]
  };

  new Noty({ text, timeout, type, layout, sounds, visibilityControl: true, killer }).show();
};

const textExtractor = function (objectToExtractFrom) {

  // if only string passed in, set the text
  if (typeof (arguments[0]) === 'string') {
    return arguments[0];

  } else if (typeof (arguments[0]) === 'object' && !objectToExtractFrom.text) {
    // formatted message object
    return arguments[0].props.defaultMessage;
  }
  else {
    return objectToExtractFrom.text;
  }
}

const error = function ({ text, timeout, layout, clearOthers, sound = negative }) {

  text = textExtractor(arguments[0]);
  showNotification({ text, timeout, type: "error", layout, killer: clearOthers, soundOnShow: sound });
};

const warn = function ({ text, timeout, layout, clearOthers, sound = negative }) {

  text = textExtractor(arguments[0]);

  showNotification({ text, timeout, type: "warning", layout, killer: clearOthers, soundOnShow: sound });
};

const success = function ({ text, timeout, layout, clearOthers, sound = positive }) {

  text = textExtractor(arguments[0]);
  showNotification({ text, timeout, layout, type: "success", killer: clearOthers, soundOnShow: sound });

};

const info = function ({ text, timeout, layout, clearOthers, sound = neutral  }) {

  text = textExtractor(arguments[0]);
  showNotification({ text, timeout, layout, killer: clearOthers, soundOnShow: sound  });

};

const SoundEffect = {
  neutral, negative, positive, upload
}

export default {
  info,
  success,
  error,
  warn,
  err: error,
  SoundEffect
}