import React from 'react';
import {FormattedMessage} from 'react-intl';

const Footer = () => {
    return (

        /* Begin Page Footer*/
        <footer className="main-footer">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-center">
                    <p><FormattedMessage id="Footer.builtby" defaultMessage="Built by MazinoMedia" /></p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-center">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" target="_blank" rel="noopener noreferrer" data-lity href="https://www.mazinomedia.com/terms-privacy"><FormattedMessage id="Footer.policy" defaultMessage="policy" /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
        /* End Page Footer */
    );
};

export default Footer;