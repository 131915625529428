import React, { Component } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import steps from './firsttime.json'
// import a11yChecker from "a11y-checker";

class TutorialEngine extends Component {

    state = {
        run: false
    }

    handleJoyrideCallback = data => {
        const { status, type } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({ run: false });
        }

        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();
    };

    triggerTutorial = e => {
        e.preventDefault()
        this.setState({ run: true })
    }

    componentDidMount = () => {
        // a11yChecker();
    }

    render() {
        let { run } = this.state
        return (
            <div>
                {/* TODO GET RID OF THIS ITS FOR TESTING */}
                {/* <button onClick={this.triggerTutorial}>run tutorial</button> */}
                <Joyride run={run} callback={this.handleJoyrideCallback} steps={steps} scrollToFirstStep continuous showProgress showSkipButton styles={{
                    options: {
                        zIndex: 10000
                    }
                }} />
            </div>
        );
    }
}

export default TutorialEngine;