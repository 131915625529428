import React, { Component } from 'react';
import './App.css';
import Home from './components/home/HomePage';
import { connect } from 'react-redux';
import { login } from './actions/user-actions';
import { getUserSession, parseURLForTokenChallenge, initUserHandler, userSignedIn } from './services/authentication-wsao';
import queryString from 'query-string';
import DebugPanel from './components/common/debug/DebugPanel';
import ReactGA from 'react-ga';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import LoginPage from 'components/home/LoginPage';

class App extends Component {

  constructor(props) {
    super(props)
    this.onLogin = this.onLogin.bind(this);
  }

  onLogin(username) {
    this.props.onLogin(username);
  }

  componentDidMount = () => {

    // Initialize analytics tracker
    ReactGA.initialize('UA-146318354-1');
    ReactGA.pageview(window.location.pathname);

    // pass load user redux action to success callback
    initUserHandler(this.onLogin);

    // always check to see if we are given an auth token
    parseURLForTokenChallenge();

    // if url doesn't have code prefix (i.e, the user isn't back from an authentication redirect),
    // attempt to load the user session. This will either load the session from memory or direct the 
    // user to the cognito authentication page.
    getUserSession();

  }

  render() {

    // render components
    return (
      <div className="App">

        {/* Body  */}
        <Router>
          <Switch>
            <Route path="/login" render={() => <LoginPage />} />
            <Route path="/" render={() => {
              if (!userSignedIn() && !queryString.parse(window.location.search, { ignoreQueryPrefix: true }).code) {
                return <Redirect to="/login" />
              }
              if (this.props.loggedIn)
                return <Home />

              return null;

            }} />
          </Switch>
        </Router>
        {/* Do not render the Home page until user is loggedIn */}

        <DebugPanel disable={process.env.CREATE_REACT_APP_DEBUG} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  loggedIn: state.loggedIn
});

const mapActionsToProps = {
  onLogin: login
}

export default connect(mapStateToProps, mapActionsToProps)(App);
