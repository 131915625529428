import React, { Component } from 'react';
import PatchWidget from 'components/patch/PatchWidget';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { getUserFeed, getSearchFeed } from 'services/synthwsao';
import { generateStubPatches } from 'components/common/debug/DebugFunctions';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

class PresetFeed extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            next: null,
            hasNext: false,
            hasPrevious: false,
            patches: [],
            page: [],
        };

        this.fetchPatches = this.fetchPatches.bind(this);
        this.generateStub = this.generateStub.bind(this);
    }

    componentDidMount() {
        // this.generateStub();
        this._isMounted = true;
        this.fetchPatches(this.props.user.username);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    generateStub = () => {
        let patchStubList = generateStubPatches();

        let updatedPatches = this.state.patches;
        updatedPatches.push(...patchStubList);

        this.setState({ patches: updatedPatches })
    }
    fetchPatches = () => {


        let feedFunction = getUserFeed;
        let query = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).query

        // swap feed funtion to search funciton if search parameter is passed
        if (this.props.search) {
            feedFunction = getSearchFeed;
        }

        feedFunction(this.props.user.username, this.state.next, query)
            .then(patches => {
                let updatedPatches = this.state.patches;
                updatedPatches.push(...patches.results);

                if (this._isMounted) {
                    this.setState({
                        patches: updatedPatches,
                        page: patches.results,
                        hasNext: patches.hasNext,
                        hasPrevious: patches.hasPrevious,
                        next: patches.next
                    });
                }
            })
            .catch(e => { console.log('unable to load next page of patches') });
    };


    render() {

        let PatchList = () => {
            let list = [];

            // Null check for patches existing
            if (this.state.patches) {

                this.state.patches.forEach((patch, i) => {

                    // preset filters 
                    let filterType = this.props.filter_type || patch.type;
                    let filter_synthesizer = this.props.filter_synthesizer || patch.synthesizer;
                    // end filters

                    if (patch.type === filterType && patch.synthesizer === filter_synthesizer)
                        list.push(
                            <PatchWidget large patch={patch} key={i} user={this.props.user} onPatchChange={this.onPatchChange} editable={false} />
                        )
                });

            }

            return list;
        }

        return (
            <div className="col-xl-8">
                <PatchList />
                <InfiniteScroll
                    dataLength={this.state.patches.length}
                    next={this.fetchPatches}
                    hasMore={this.state.hasNext}
                    loader={
                        <div className="col-xl-12">
                            <div className="widget has-shadow">
                                <div className="widget-header bordered no-actions d-flex align-items-center">
                                    <h4>Loading...</h4>
                                </div>
                            </div>
                        </div>
                    }
                    endMessage={
                        // kinda messy but conditionally display a different message if there's nothing
                        this.state.patches.length === 0 && !this.state.hasNext ? <div className="col-xl-12">
                            <div className="widget has-shadow">
                                <div className="widget-header bordered no-actions d-flex align-items-center">

                                    <h4>No results</h4>
                                </div>
                            </div>
                        </div> :
                            <div className="col-xl-12">
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4><FormattedMessage id="ProfilePage.uploadprompt" defaultMessage="No more to show! {emoji}" values={{
                                            emoji: <span role="img" aria-label="smile">🙂</span>,
                                            expanding_it: <Link to="/synthShare"><b><FormattedMessage id="ProfilePage.expanding_it" defaultMessage="expanding it" /></b></Link>,
                                            emoji2: <span role="img" aria-label="smile">😲</span>
                                        }} /></h4>
                                    </div>
                                </div>
                            </div>
                    }>

                </InfiniteScroll>
            </div>);
    }
}

export default PresetFeed;