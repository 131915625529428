import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Sticky } from 'react-sticky';
import {getTrendingUsers} from 'services/trending-wsao';
import defaultProfilePhoto from "assets/img/no-photo.png";

class TrendingCitizens extends Component {

    state = {
        trendingUsers: []
    }

    componentDidMount() {
        getTrendingUsers().then(trendingUsers =>{if(trendingUsers)this.setState({trendingUsers})});
    }

    render() {

        let CitizenList = () => {
            let citizens = [];
            for (let i = 0; i < this.state.trendingUsers.length; i++) {
                let user = this.state.trendingUsers[i];

                citizens.push(<Link to={`citizen/${user.username}`} key={i}>
                    <img src={user.profilePhoto || defaultProfilePhoto} className="img-fluid rounded-circle" alt="..." />
                </Link>)
            }
            return citizens;
        }

        return (
            /* Begin Column */
            <div className="col-xl-1 column d-flex justify-content-center" style={{zIndex: 0}}>
                {/* Begin Friends */}
                <Sticky>

                    {/* The "sticky" and "is-sticky" classes below are what make it stick */}
                    {({ isSticky }) => {

                        let classes = 'friends-mini text-center';
                        if (isSticky) {
                            classes = 'friends-mini text-center sticky is-sticky';
                        }

                        return <div className={classes} ref="feature">
                            <span className="emphasize">Trending</span>
                            <CitizenList />

                            {/* Uncomment Below when you wanna have users able to add freinds or something */}
                            {/* <a href="sssda">
                                <i className="la la-user-plus" />
                            </a> */}
                        </div>;
                    }}
                </Sticky>

                {/* End Friends */}
            </div>
            /* End Column */
        );
    }
}

export default TrendingCitizens;