import React, { Component } from "react";
import { connect } from "react-redux";
import { loadRandomUser, updateUser, login } from "../../../actions/user-actions";
import * as NotificationActions from "actions/notificationActions";
import NotificationEngine from "engines/notification/NotificationEngine"
import { FormattedMessage } from "react-intl";
import { getUserSession } from 'services/authentication-wsao';
import { userSignedIn, retrieveAuthTokens , getCurrentUser} from 'services/authentication-wsao';
import SoundEngine from 'engines/sound/SoundEngine';
class DebugPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false, // this is just for the toggle, this doesn't determine if it shows on production or not
      notificationId: "5cff9ba0d255d275f06df7f3",
      tokens: {
        accessTokenFull: { payload: { exp: 69 } },
        idTokenFull: { payload: { exp: 69 } },
        username: "not loaded",
      }
    };
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onLoadRandomUser = this.onLoadRandomUser.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  onUpdateUser(e) {
    this.props.onUpdateUser(e.target.value);
  }

  onLoadRandomUser() {
    this.props.onLoadRandomUser();
  }

  onLogin(username) {
    this.props.onLogin(username);
  }

  updateTokens() {
    console.log('updating tokens')
    let tokens = retrieveAuthTokens();
    
    console.log(tokens);
    this.setState({ tokens });

  }

  printCurrentUser(){
    console.log(getCurrentUser());
  }

  render() {
    if (this.props.disable) {
      return null;
    }
    var panel = null;

    let UserButtons = () => {
      return <div className="mb-3 mt-4">

        <div className="row">
          <div className="col-sm">
            <h4>User Buttons</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button type="button" className="btn btn-primary col-12" onClick={this.onLoadRandomUser}>Mock User</button>
          </div>
          <div className="col-2">
            <button onClick={() => { localStorage.clear(); }} className="btn btn-primary col-12">Reset Local Storage</button>
          </div>
          <div className="col-2">
            <button onClick={() => { console.log('getting user session'); getUserSession() }} className="btn btn-primary col-12">refresh token</button>
          </div>
          <div className="col-2">
            <h4>Is user signed in?: <span className={`${userSignedIn() ? "text-success" : "text-danger"}`}>{'' + userSignedIn()}</span></h4>
          </div>
          <div className="col-2">
            <h4>Name: <span className={`${userSignedIn() ? "text-success" : "text-danger"}`}>{'' + this.props.user.name}</span></h4>
          </div>
          <div className="col-2">
            <h4>Username: <span className={`${userSignedIn() ? "text-success" : "text-danger"}`}>{'' + this.props.user.username}</span></h4>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button type="button" className="btn btn-primary col-12" onClick={this.updateTokens.bind(this)}>Update Tokens</button>
          </div>
          <div className="col-2" onClick={() => navigator.clipboard.writeText(this.state.tokens.accessToken)}>
            <h4>Access Expires: <span className={`${userSignedIn() ? "text-success" : "text-danger"}`}>{new Date(this.state.tokens.accessTokenFull.payload.exp * 1000).toString()}</span></h4>
          </div>
          <div className="col-2" onClick={() => navigator.clipboard.writeText(this.state.tokens.idToken)}>
            <h4>ID Token Expires: <span className={`${userSignedIn() ? "text-success" : "text-danger"}`}>{new Date(this.state.tokens.idTokenFull.payload.exp * 1000).toString()}</span></h4>
          </div>
          <div className="col-2">
            <button type="button" className="btn btn-primary col-12" onClick={this.printCurrentUser.bind(this)}>Print Current User</button>
          </div>
        </div>

      </div>
    }

    let APIButtons = () => {

      return (<div className="mb-3  mt-4">
        <div className="row">
          <div className="col-sm">
            <h4>API Notifications</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <button onClick={() => this.props.onNotificationSeen({ _id: this.state.notificationId })} className="btn btn-primary col-12">Mark Notification As Seen</button>
          </div>
        </div>

      </div>)
    }

    let ClientNotifications = () => {
      return <div className="mb-3 mt-4">
        <div className="row">
          <div className="col-sm">
            <h4>Client Notifications</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button className="btn btn-primary col-12" onClick={() => {
              NotificationEngine.info('Hello Friend')
            }} >
              Show notification
        </button>
          </div>
          <div className="col-2">
            <button className="btn btn-primary col-12" onClick={() => {
              NotificationEngine.success(<FormattedMessage
                id="SynthShare.editing"
                defaultMessage="Word up synthCitizen!"
              />)
            }} >
              Show Success
        </button>
          </div>
          <div className="col-2">
            <button className="btn btn-primary col-12" onClick={() => {
              NotificationEngine.error(<FormattedMessage
                id="SynthShare.editing"
                defaultMessage="Word up synthCitizen!"
              />)
            }} >
              Show Error
        </button>
          </div>
          <div className="col-2">
            <button className="btn btn-primary col-12" onClick={() => {
              NotificationEngine.warn(<FormattedMessage
                id="SynthShare.editing"
                defaultMessage="Word up synthCitizen!"
              />)
            }} >
              Show warning
            </button>
          </div>
          <div className="col-2">
            <button className="btn btn-primary col-12" onClick={() => {
              NotificationEngine.info({ sound: NotificationEngine.SoundEffect.upload, text: "Custom sound" })
            }} >
              With Custom SFX
            </button>
          </div>
        </div>
      </div>
    }

    let SoundEngineDebug = () => {
      return (
        <div className="mb-3  mt-4">
          <div className="row">
            <div className="col-sm">
              <h4>Sound Engine</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <button onClick={() => { SoundEngine.play("https://www.myinstants.com/media/sounds/26f8b9_sonic_ring_sound_effect.mp3") }} className="btn btn-primary col-12">play sound</button>
            </div>
          </div>

        </div>)
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      panel = (
        <div>
          <button className="btn btn-shadow btn-gradient-01 mt-3 mb-3" onClick={() => { this.setState({ show: !this.state.show }) }}>Show Debug Panel</button>
          <div className="mt-4" style={this.state.show ? {} : { display: 'none' }}>

            <UserButtons />
            <APIButtons />
            <ClientNotifications />
            <SoundEngineDebug />

          </div>
        </div>
      );
    }

    return <div className="container">{panel}</div>;
  }
}
function mapStateToProps(state) {
  return {
    user: state.user
  };
}

const mapActionsToProps = {
  onUpdateUser: updateUser,
  onLoadRandomUser: loadRandomUser,
  onLogin: login,
  onNotificationSeen: NotificationActions.onNotificationSeen
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DebugPanel);
