import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import SoundEngine from 'engines/sound/SoundEngine';
import easter_egg_hello from 'assets/audio/sfx/hello-friend.mp3';
import Modal from 'components/common/Modal';

class SideBarNav extends Component {


    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            timesClicked: 0
        }

    }

    render() {

        return (
            <div>
                <Modal
                    modalId="request-modal"
                    title="Closed Beta in Progress"
                    body={
                        <p>
                            This feature is currently in closed Beta. 
                            <br />
                            <br />
                            Wanna join the others in early access? Request <a href={"mailto:info@mazinomedia.com?subject=Preset Request/Challenge Beta&body=My Username Name is: " + this.props.user.username}>here</a>.
                        </p>
                    }
                    medium
                    confirmation
                />
                <Modal
                    modalId="request-modal-2"
                    title="Coming soon"
                    body={
                        <p>
                            You already qualify. You'll be notified when the feature is live
                        </p>
                    }
                    medium
                    confirmation
                />
                <div className="compact-sidebar light-sidebar has-shadow" id="profile">
                    {/* Begin Side Navbar */}
                    <nav className="side-navbar box-scroll sidebar-scroll">
                        {/* Begin Main Navigation */}
                        <ul className="list-unstyled">

                            {/* These two are just here for padding */}
                            {/* <li>
                                <span></span>
                            </li>
                            <li>
                                <span></span>
                            </li> */}
                            {/* End padding */}
                            {(this.props.user && this.props.user.permissions.includes('admin')) && <li>
                                <Link to="/admin" className="nav-link" id="profile2">
                                    <i className="ti ti-desktop" /><span>admin</span>
                                </Link>
                            </li>}

                            {/* <li>
                                <Link to="/search" className="nav-link" id="profile2">
                                    <i className="ti ti-search" /><span>search</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/challenge/overview" className="nav-link" id="nav-request">
                                    <i className="ti ti-comments-smiley" />
                                    <span style={{
                                        "paddingBottom": "30px",
                                        "paddingLeft": "42px",
                                        opacity: 1
                                    }}>
                                        <sup className="text-danger">new</sup>
                                    </span>
                                    <span>Request</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/challenge/overview" className="nav-link">
                                    <i className="ti ti-comments" /><span>Explore</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/synthShare" className="nav-link">
                                    <i><FontAwesomeIcon icon={faFileUpload} /> </i><span>upload</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/citizen/" + this.props.user.username} className="nav-link">
                                    <i className="ti ti-user" />
                                    <span style={{
                                        "paddingBottom": "30px",
                                        "paddingLeft": "42px",
                                        opacity: 1
                                    }}>
                                        {/* <sup className="text-danger">{intl.formatMessage({id:"Nav.new", defaultMessage:"new" })}</sup> */}
                                    </span>
                                    <span>
                                        <FormattedMessage id="Nav.profile" defaultMessage="profile" />
                                    </span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/" className="nav-link"
                                    onClick={e => {

                                        this.setState({ timesClicked: 1 + this.state.timesClicked });

                                        if (this.state.timesClicked === 6) {
                                            SoundEngine.play(easter_egg_hello);
                                        }
                                    }}>
                                    <i className="la la-angle-left" /><span>home</span>
                                </Link>
                            </li>

                            {/* <li>
                                <Link to="/synthLibrary" className="nav-link">
                                    <i className="ti ti-comments" /><span>Messages</span>
                                </Link>
                            </li>

                            <li>
                                <a href="pages-events.html">
                                    <i className="ti ti-calendar" /><span>Events</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="ti ti-headphone" /><span>Musics</span>
                                </a>
                            </li>
                            <li>
                                <a href="pages-albums.html">
                                    <i className="ti ti-gallery" /><span>Images</span>
                                </a>
                            </li>
                            <li>
                                <a href="pages-videos.html">
                                    <i className="ti ti-control-play" /><span>Videos</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <i className="ti ti-stats-up" /><span>Statistics</span>
                                </a>
                            </li> */}
                        </ul>
                        {/* End Main Navigation */}
                    </nav>
                    {/* End Side Navbar */}
                </div>
            </div>
            /* End Left Sidebar */
        );
    }
}

SideBarNav.propTypes = {
    user: PropTypes.object,
    intl: intlShape.isRequired
};

export default injectIntl(SideBarNav);