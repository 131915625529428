import React from 'react';

function SubHeader(props) {


    let { text } = { ...props }
    return (
        <div className="row">
            <div className="page-header">
                <div className="d-flex align-items-center">
                    <h3>{text}</h3>
                </div>
            </div>
        </div>
    );

}

export default SubHeader;