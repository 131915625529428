import axios from "axios";
import { loadUser, updateUserProfile} from "../services/userwsao";
import { logUserIn, logUserOut } from "../actions/authentication-actions";
import { updateNotifications } from "actions/notificationActions";

export const UPDATE_USER = "users:updateUser";
export const SHOW_ERROR = "users:showError";

export const updateUser = newUser => {
  // init variables that might be missing
  if (!newUser.generalInfo) {
    newUser.generalInfo = {};
  }
  return {
    type: UPDATE_USER,
    payload: {
      user: newUser
    }
  };
};

export function showError() {
  console.log("Fatal error occurred while trying to load user. Application is likely broken now");
  return {
    type: SHOW_ERROR,
    payload: {
      user: {
        name: "ERROR!!!",
        username: "ERROR USERNAME!!!",

        generalInfo: {},
        notifications: [],
        settings: {},
        permissions: []
      }
    }
  };
}

/*USER API ACTION DISPATCHES */
export function login(username) {
  // need the thunk middleware applied to use the dispatch like so
  return dispatch => {
    // get user info via synthcity api
    loadUser(username, true)
      .then(user => {

        // separate the user response into its component objects
        // let settings = user.settings;
        let notifications = user.notifications;

        // clear notifications object from user
        // user.notifications = [];
        dispatch(updateNotifications(notifications));

        // TODO Settings store update
        dispatch(updateUser(user));
      })
      .catch(err => {
        console.log(err)
        dispatch(showError());
      });
  };
}

export function logout() {
  return dispatch => {
    dispatch(updateUser({ generalInfo: {} }));
    dispatch(logUserOut());
  };
}

export function updateProfile(user) {
  return dispatch => {
    updateUserProfile(user)
      .then(updatedUser => {
        dispatch(updateUser(updatedUser));
      })
      .catch(err => {
        console.log(err);
        dispatch(showError());
      });
  };
}

export function loadRandomUser() {
  console.log("loading random user...");
  return dispatch => {
    axios
      .get("https://randomuser.me/api/")
      .then(res => {
        var data = res.data.results[0];
        let user = {
          username: data.login.username,
          name: data.name.first + " " + data.name.last,
          email: data.email,
          generalInfo: {
            aboutMe:
              "Hi! I'm Zee and I like to make Music. I kinda suck at sound design tbh :|. Heck... I suck so much I'm building an entire site to share synth patches so I can suck less lol :V.",
            birthday: "November 6th, 1992",
            location: "Newbury, England, UK",
            joinDate: "February 31st, 2018",
            website: "www.dadamugamazino.com",
            phone: "075123456789"
          },
          permissions: []
        };
        dispatch(updateUser(user));
        dispatch(logUserIn());
      })
      .catch(() => {
        console.log("something went wrong oops");
        // call dispatch to dispatch actions to redux store
        dispatch(
          updateUser({
            name: "AN ERROR OCCURED HERE NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO",
            username: "ARRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRGH!"
          })
        );
      });
  };
}
