import React, { Component } from 'react';
import defaultPhoto from "assets/img/albums/collection_default.jpg";
import FadeIn from 'react-fade-in';
import { Link } from "react-router-dom";
import PatchWidget from 'components/patch/PatchWidget';
import { getCollection } from 'services/collectionwsao';
import ProfilePhoto from 'components/common/ProfilePhoto';
class CollectionPage extends Component {

    _isMounted = false;

    state = {};

    componentDidMount() {
        this._isMounted = true;

        const { match: { params } } = this.props;

        params.id && getCollection(params.id).then(collection => {

            this._isMounted && this.setState({ ...collection });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    downloadCollection() {
        alert('downloading collection')
    }

    render() {

        let collection = this.state;

        let PatchList = () => {
            let list = [];

            // Null check for patches existing
            if (this.state.patches) {

                this.state.patches.forEach((patch, i) => {
                    list.push(
                        <PatchWidget large patch={patch} key={i} user={this.props.user} onPatchChange={this.onPatchChange} editable={this.props.editable} />
                    )
                });

            }

            return list;
        }

        return (

            <FadeIn>
                <div className="page">

                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-11">

                                {/* <div className="row flex-row">

                                    <div className="col-xl-12 column">
                                        <div className="widget has-shadow">
                                            <div className="widget-body">
                                                <div className="d-flex justify-content-center">
                                                    <button className="btn btn-shadow btn-gradient-01 " type="submit">edit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className="row flex-row">
                                    <div className="col-xl-3 column">
                                        <div className="widget has-shadow">
                                            {/* Download Button */}
                                            <div className="widget-body">
                                                <div className="d-flex justify-content-center">
                                                    {/* <button className="btn btn-shadow btn-gradient-01 " type="submit" onClick={this.downloadCollection.bind(this)}>download</button> */}
                                                </div>
                                            </div>
                                            {/* End Download Button */}
                                            {/* Display Photo */}
                                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                                <figure className="img-hover-01 has-shadow">
                                                    <Link to="#"> <img src={this.state.img || defaultPhoto} className="img-fluid collection-cover" alt={collection.name + " Cover"} /></Link>
                                                    <div>
                                                        {/* <Link to="#" onClick={e => e.preventDefault()}>
                                                            <span>{(this.state.likes && this.state.likes.length) || 0}</span>
                                                            <i className="la la-heart-o" />
                                                        </Link> */}
                                                        <Link to="#">
                                                            <i className="la la-camera"></i>
                                                        </Link>
                                                    </div>
                                                </figure>
                                            </div>
                                            {/* End Display Photo */}
                                            {/* Collection Info */}
                                            <div className="widget-body">
                                                <div className="d-flex justify-content">
                                                    <h2>{collection.name}</h2>
                                                </div>
                                            </div>


                                            <div className="widget-header d-flex align-items-centered">
                                                <div className="user-image">{collection.user && <ProfilePhoto user={collection.user} width={55} clickable />}</div>
                                                <div className="d-flex flex-column mr-auto title username">by {collection.uploadedBy}</div>
                                            </div>

                                            <div className="widget-body">
                                                <div className="d-flex justify-content">
                                                    <p>{collection.description}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-9 column">
                                        <div>
                                            <PatchList />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn >
        );
    }
}

export default CollectionPage;