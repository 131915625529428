import React, { Component } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { language } from 'config/localization';
import YouTube from 'react-youtube';
import ProfilePhoto from 'components/common/ProfilePhoto';
import PatchInfoWidget from 'components/synthShare/PatchInfoWidget';
import { getChallenge, associateChallengeWithPatch } from 'services/challengewsao';
import NotificationEngine from 'engines/notification/NotificationEngine';
import FadeIn from 'react-fade-in/lib/FadeIn';
import Header from 'components/common/Header';
import Widget from 'components/profile/Widget';
import capitalize from 'capitalize';
import TitleController from 'components/common/TitleController';
import { withRouter } from 'react-router-dom';
import Commento from 'components/common/Commento';
import StartEndControls from "components/challenge/StartEndControls";

class SolveChallengeTab extends Component {

    _isMounted = false;

    state = {
        challenge: {
            song: {
                url: '',
                platform: ''
            },
            song_start: 0,
            song_end: 0
        },
        showChallengePanel: true,
        hideUserCreated: false

    }

    componentDidMount() {
        this._isMounted = true;
        let { match: { params } } = this.props;

        let challengeId = this.props.id || params.id

        this.loadChallenge(challengeId);
    }

    loadChallenge(challengeId) {


        getChallenge(challengeId || 'random', this.state.hideUserCreated).then(challenge => {
            if (this._isMounted) {

                // we get an empty object if there is no challenge
                if (Object.entries(challenge).length === 0 && challenge.constructor === Object) {
                    this.setState({ showChallengePanel: false })
                } else {

                    // redirect to patch url if no id was passed in (i.e its a random challenege selected by api)
                    if (!challengeId || challengeId === 'random') {
                        this.props.history.push('/challenge/solve/' + challenge._id);
                    }


                    this.setState({
                        challenge,
                        id: challengeId,
                        showChallengePanel: true
                    });
                }

                window.scrollTo(0, 0);
            }
        }).catch(e => {
            NotificationEngine.error('Something went wrong loading the challenge');
        });
    }


    youtube_parser(url) {

        // eslint-disable-next-line
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : '';
    }

    _onReady(e) {
        this.setState({ player: e.target });
    }


    render() {

        let { song_end, song_start, user, song, requestedBy, title, date_created, notes, type, _id } = { ...this.state.challenge };
        type = type && capitalize(type)
        const videoId = this.youtube_parser(song.url);
        const playerOptions = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                origin: "synthcity.io",
                start: song_start,
                end: song_end,
                loop: true
            }
        };
        return (

            <FadeIn>
                <TitleController title={title && requestedBy ? title + " requested by " + requestedBy + " | Recipes" : "Recipes"} />
                <Header text={<span><span className="important">{type}</span> Challenge</span>} />
                {!this.state.showChallengePanel ? <Widget body="None left holmes! Try coming back later for more" /> : <div>
                    <div className="widget has-shadow">

                        {/* Begin Main Challenge Panel */}
                        <div className="form-group  row d-flex align-items-center mb-5">

                            <div className="widget-header bordered d-flex align-items-center">
                                <div className="user-image">{user && <ProfilePhoto user={user} width={55} clickable />}</div>

                                <div className="d-flex flex-column mr-auto">
                                    <div className="title">
                                        <span className="username">{title}</span>
                                    </div>
                                    <div>{requestedBy}</div>
                                    <div className="time"><span className="">{type} challenge</span></div>
                                    <div className="time">{date_created && <ReactTimeAgo date={new Date(date_created)} locale={language} />}</div>
                                </div>

                            </div>


                            {/* Begin Notes */}
                            {/* <div className="widget-header bordered no-actions d-flex align-items-center">
                            </div> */}
                            <div className="widget-header">{notes}</div>
                            {/* End Notes */}
                            <div className="embed-responsive embed-responsive-16by9 ">
                                <YouTube
                                    videoId={videoId}
                                    opts={playerOptions}
                                    onReady={this._onReady.bind(this)}
                                    onStateChange={(e) => {

                                        // if reached end, immediately loop to start
                                        if (e.data === 0) {
                                            this.state.player.seekTo(song_start);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/* End Main Challenge Panel */}

                        <StartEndControls start={song_start} end={song_end} player={this.state.player}/>


                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            {/* <h4>Challenge Notes - Remake the <span className="important">{type}</span></h4> */}
                        </div>
                        <div className="widget widget-body"><h4>Challenge - Remake the <span className="important">{type}</span></h4></div>

                    </div>
                    <PatchInfoWidget isChallenge challengeId={_id} onComplete={(patchId) => {
                        NotificationEngine.success({ text: "Nice work! Your solution will be reviewed shortly", timeout: 5000 })
                        associateChallengeWithPatch(_id, patchId).then()
                            .catch(() => NotificationEngine.error("Something went wrong submitting your recipe for review"));
                        this.loadChallenge();
                    }} />

                </div>}

                {_id && <Commento id={_id} />}
            </FadeIn>
        );
    }
}

export default withRouter(SolveChallengeTab);
