import React, { Component } from 'react';
import RhythmPlayer from 'engines/sound/RhythmPlayer';
import FadeIn from 'react-fade-in';
import FriendsTab from 'components/profile/FriendsTab';
import { day1EasterEggStop } from 'actions/easteregg-actions';
import { connect } from "react-redux";

class PatronEasterEgg extends Component {


    patrons = [{ username: 'bsmick97', profilePhoto: true },
    { username: 'Phtichek', profilePhoto: true },
    { username: 'ryanpridgeon', profilePhoto: true },
    { username: 'Toby', profilePhoto: true },
    { username: 'losethetooth', profilePhoto: true },
    ]

    // patrons = [{
    //     username: 'AnnaPandarina',
    //     profilePhoto: 'AnnaPandarina.jpg',
    //     displayName: "Anna O'hara",
    //     generalInfo: {
    //         aboutMe: "Oh wow! Friends 'n' stuffs!"
    //     }
    // },
    // { username: 'Zeeno', profilePhoto: true },
    // { username: 'TestUserOne', profilePhoto: true },
    // { username: 'TestUserTwo', profilePhoto: true },
    // { username: 'wesakicus', profilePhoto: true }]


    componentWillUnmount() {

        // stop showing easter egg
        RhythmPlayer.stop();
        this.props.onNavigateAway();
    }

    componentDidMount() {
        RhythmPlayer.animate();
    }
    render() {
        return (

            <FadeIn>
                <div className="row flex-row justify-content-md-center mb-4">
                    <button className="btn btn-outline-danger btn-lg" onClick={() => RhythmPlayer.stop()}>STOP DANCING!</button>
                </div>
                <FriendsTab users={this.patrons} />
            </FadeIn>
        );
    }
}

const mapActionsToProps = {
    onNavigateAway: day1EasterEggStop
}

export default connect(null, mapActionsToProps)(PatronEasterEgg);