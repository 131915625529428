// styling modules
import './config/style';

// base React modules
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './config/serviceWorker';

// routing and data management modules
import { Provider } from 'react-redux';
import { store, persistor } from './config/configureStore';
import { PersistGate } from 'redux-persist/integration/react';

// localization modules
import { IntlProvider} from 'react-intl';
import {language, messages} from './config/localization';

ReactDOM.render(
  <IntlProvider locale={language} messages={messages}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </IntlProvider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
