import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';
import { checkPostAchievements } from 'services/achievementAwarder';

const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

// URL endpoints

export function getSynthList() {
    return new Promise((resolve, reject) => {
        axios.get(uri + "list")
            .then(function (response) {
                // handle success
                resolve(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                reject(error);
            });
    });
}

export async function getSynthInfo(s3Key) {
    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        let res = await axios({
            url: `${uri}patch/${s3Key}`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });


        return res.data;

    } catch (e) {
        console.log(e);
        throw e;
    }
}

/**
 * Uploads the synthPatch to the synthManager API which in turn
 * speaks with teh database and posts the file to s3
 * @param {Patch} metadata
 */
export async function uploadSynth(metadata, file, previewFile) {
    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        // Get signed URL from API
        let resourceResponse = await axios({
            url: `${uri}patch/resourcetoken`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        let uploadURL = resourceResponse.data.url;
        let s3Key = resourceResponse.data.s3Key;

        // upload preset to S3
        await axios({
            url: uploadURL,
            method: 'PUT',
            data: file
        });

        // add patch metadata to db
        metadata.s3Key = s3Key;
        await axios({
            url: `${uri}patch`,
            method: 'POST',
            data: metadata,
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        //TODO move this into seperate function and add check for challenge achievements
        checkPostAchievements();

        // upload Preview file to s3 if exists
        if (!(Object.keys(previewFile).length === 0 && previewFile.constructor === Object)) {
            await uploadPreview(s3Key, previewFile);
        }

        return s3Key;

    } catch (e) {
        console.log(e);
        throw e;
    }
}

/**
 * Uploads a preset preview file to amazon S3
 * @param {*} s3Key
 * @param {*} file
 */
export async function uploadPreview(s3Key, file) {
    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        // Get signed URL for Uploading from API
        let resourceResponse = await axios({
            url: `${uri}patch/${s3Key}/preview/uploadToken`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        let uploadURL = resourceResponse.data.url;

        // upload preview to S3
        await axios({
            url: uploadURL,
            method: 'PUT',
            data: file,
            headers: {
                "Content-Type": file.type
            }
        });

        let patchInfo = { s3KeyPreview: s3Key };

        // update patch with the preview metadata in db
        await axios({
            url: `${uri}patch/${s3Key}`,
            method: 'PUT',
            data: patchInfo,
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        return;
    } catch (e) {

        console.log(e);
        throw e;
    }
}

/**
 *  Finds the patch with the corresponding ID and edits its info in the DB
 */
export async function editPatch(s3Key, patchInfo, previewFile) {
    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        // update patch metadata in db
        await axios({
            url: `${uri}patch/${s3Key}`,
            method: 'PUT',
            data: patchInfo,
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        // upload Preview file to s3 if exists
        if (!(Object.keys(previewFile).length === 0 && previewFile.constructor === Object)) {
            await uploadPreview(s3Key, previewFile);
        }

        return;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export function downloadSynth(key) {
    return new Promise((resolve, reject) => {
        axios({
            url: uri + "download?key=" + key,
            method: 'GET',
            responseType: 'blob',
        })
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                reject(error);
            });
    });
}

export async function getPatchesForUser(username, next, max) {

    try {

        let limit = max ? 1000 : 6;

        let patchStreamURL = uri + "user/" + username + "/uploads?limit=" + limit;

        // pass the pagination param if filled out
        if (next) {
            patchStreamURL += "&next=" + next;
        }

        let authTokens = retrieveAuthTokens();

        let response = await axios({
            url: patchStreamURL,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }

        });

        return response.data;

    } catch (e) {
        console.log('An error occurred while getting patch stream', e);
        throw e;
    }
}

export async function getUserFeed(username, next) {
    try {
        let patchStreamURL = uri + "user/" + username + "/feeds/presets?limit=" + 4;

        // pass the pagination param if filled out
        if (next) {
            patchStreamURL += "&next=" + next;
        }

        let authTokens = retrieveAuthTokens();

        let response = await axios({
            url: patchStreamURL,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }

        });

        return response.data;

    } catch (e) {
        console.log('An error occurred while getting patch stream', e);
        throw e;
    }
}

export async function getSearchFeed(username, next, query = '') {
    try {

        // don't call if there's no query passed
        if (query === '') {
            return [];
        }

        let patchStreamURL = uri + "patch/search?limit=" + 4 + "&query=" + query;

        // pass the pagination param if filled out
        if (next) {
            patchStreamURL += "&next=" + next;
        }

        let authTokens = retrieveAuthTokens();

        let response = await axios({
            url: patchStreamURL,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }

        });

        return response.data;

    } catch (e) {
        console.log('An error occurred while getting patch stream', e);
        throw e;
    }
}

export async function downloadPatch(patchID, fileName, extension) {
    try {
        let patchURI = `${uri}patch/${patchID}/download`;
        let authTokens = retrieveAuthTokens();

        let s3DownloadURL = await axios({
            url: patchURI,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }
        });

        s3DownloadURL = s3DownloadURL.data;

        // hack to check if the file exists first. Highly inefficient
        // TODO make it load the file from the response
        await axios({
            url: s3DownloadURL,
            method: 'GET',
        });

        const link = document.createElement('a');
        link.href = s3DownloadURL;

        document.body.appendChild(link);

        // trigger download
        link.click();

        // clean up download tag
        document.body.removeChild(link);

        return s3DownloadURL;

    } catch (e) {
        console.log('An error occurred while getting the patch.', e);
        throw e;
    }
}

export async function getSynthPreview(patchID) {
    try {
        let previewURI = `${uri}patch/${patchID}/preview`;

        let authTokens = retrieveAuthTokens();

        let s3PreviewURL = await axios({
            url: previewURI,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }
        });

        return s3PreviewURL.data;
    } catch (e) {
        console.log('An error occurred while loading the preview')
        throw e;
    }
}

export async function getSynthWhitelist() {
    try {
        let whitelistURI = `${uri}synthesizer/supported`;

        let response = await axios({
            url: whitelistURI,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.data;

    } catch (e) {
        throw e;
    }
}

export async function likePatch(s3Key) {
    try {
        let likeURI = `${uri}patch/${s3Key}/like`;

        let authTokens = retrieveAuthTokens();

        let response = await axios({
            url: likeURI,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: authTokens.accessToken,
                id_token: authTokens.idToken
            }
        });

        return response.data;
    } catch (e) {
        console.log('An error occurred while liking the patch');
        throw e;
    }
}
