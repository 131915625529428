import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import 'react-rater/lib/react-rater.css'
import { downloadPatch, getSynthPreview, likePatch } from 'services/synthwsao';
import PatchEditModal from '../patch/PatchEditModal';
import NotificationEngine from "engines/notification/NotificationEngine"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import SoundEngine from 'engines/sound/SoundEngine';
import ProfilePhoto from 'components/common/ProfilePhoto';
import FadeIn from 'react-fade-in';
import { connect } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import { language } from 'config/localization';
import capitalize from 'capitalize';
import Linkify from 'react-linkify';
import Modal from 'components/common/Modal';
import $ from 'jquery';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    RedditShareButton,
    RedditIcon,
    TumblrShareButton,
    TumblrIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

class PatchWidget extends Component {

    constructor(props) {
        super(props);

        this.state = {
            likes: props.patch.likes || [],
            showEditModal: false,
            liked: false
        }
    }

    showEditModal = () => {
        this.setState({ showEditModal: true })
    };

    toggleEditModal = () => {
        this.setState({ showEditModal: !this.state.showEditModal })
    };

    render() {

        // null check
        if (!this.props.patch) {
            console.error("Patch object not passed to props. PatchWidget Component will not render!");
            return null;
        }

        if (!this.props.user) {
            console.error("User object not passed to props. PatchWidget Component will not render!");
            return null;
        }

        // default set this widget size to small
        var widgetSize = !this.props.large ? 'col-xl-4 col-xl-3' : 'col-xl-12';

        let likes = this.state.likes;

        var LikesUserAvatars = (props) => {

            // check to see if users were passed
            if (!props.likes) {
                return null;
            }

            let iconList = [];
            let extraUserCount = Math.max(props.likes.length - (props.maxToShow || 3), 0);

            // if there are extra users, generate a link to all of them
            let viewExtraUsersLink = extraUserCount > 0 ? <a className="view-more" href="/" onClick={(e) => { e.preventDefault() }}>+{extraUserCount} </a> : null;

            // generate user face icons
            for (var i = 0; i < Math.min(props.maxToShow || 3, props.likes.length); i++) {

                iconList.push(
                    <Link to={`/citizen/${props.likes[i].user.username}`} key={i}>
                        <ProfilePhoto key={"p" + i} user={props.likes[i].user} />
                    </Link>
                );
            }

            for (var j = 0; j < props.likes.length; j++) {
                if (props.likes[j].user.username === props.user.username) {
                    if (!this.state.liked) {
                        this.setState({ liked: true });
                    }
                }
            }

            return (
                <FadeIn>
                    <div className="users-like">
                        {iconList}
                        {viewExtraUsersLink}
                    </div>
                </FadeIn>
            );
        }

        const clickbait = "Checkout this free platform to share synth presets";

        return (
            <div className={widgetSize}>
                <Modal title="Share this preset" body={<div>
                    <FacebookShareButton quote={clickbait} url="https://www.synthcity.io">

                        <FacebookIcon size={50} round={true} />

                    </FacebookShareButton>
                    <TwitterShareButton title={clickbait} url="https://www.synthcity.io">

                        <TwitterIcon size={50} round={true} />

                    </TwitterShareButton>
                    <RedditShareButton quote="Checkout this free platform to share synth presets" url="https://www.synthcity.io">

                        <RedditIcon size={50} round={true} />

                    </RedditShareButton>
                    {/* <PinterestShareButton quote={clickbait} url="https://www.synthcity.io">

                        <PinterestIcon size={50} round={true} />

                    </PinterestShareButton> */}
                    <TumblrShareButton quote={clickbait} url="https://www.synthcity.io">

                        <TumblrIcon size={50} round={true} />

                    </TumblrShareButton>
                    <WhatsappShareButton title={clickbait} url="https://www.synthcity.io" separator=" ">

                        <WhatsappIcon size={50} round={true} />

                    </WhatsappShareButton>
                    <EmailShareButton quote={clickbait} url="https://www.synthcity.io">

                        <EmailIcon size={50} round={true} />

                    </EmailShareButton>
                </div>}
                    modalId={"nya"} noConfirm />

                <PatchEditModal show={this.state.showEditModal} patch={this.props.patch} toggle={this.toggleEditModal} onPatchChange={this.props.onPatchChange} />
                {/* Begin Widget */}
                <div className="widget has-shadow">
                    {/* Begin Widget Header */}
                    <div className="widget-header d-flex align-items-center">
                        <div className="user-image">
                            <a href={`/citizen/${this.props.patch.uploadedBy}`}>
                                {/* TODO change profilePhoto below to be something thats... just not shit */}
                                <ProfilePhoto user={{ username: this.props.patch.uploadedBy, profilePhoto: 'I Need to Make this better damn lol' }} />
                            </a>
                        </div>
                        <div className="d-flex flex-column mr-auto">

                            <div>
                                {this.props.patch.type && capitalize(this.props.patch.type)} ~ <span className="username">{this.props.patch.name}</span>
                            </div>
                            <div className="time">{this.props.patch.synthesizer}</div>
                            {/* <div className="time">{this.props.patch.type}</div> */}
                            <div className="time">{this.props.patch.dateAdded && <ReactTimeAgo date={new Date(this.props.patch.dateAdded)} locale={language} />}</div>
                        </div>

                        <div className="widget-options">
                            {/* {this.props.patch.rating ? <AnimatedRater interactive={true} rating={this.props.patch.rating} /> : 'No Rating'} */}

                            {/* Display the edit button only if the user uploaded this patch */}
                            {this.props.editable ?
                                <button className="btn bg-transparent nav-link" onClick={this.showEditModal}>
                                    <i className="la la-pencil la-2x" />
                                </button> : null
                            }

                        </div>
                        {/* Right side of widget */}
                        <div className="widget-options">
                            <button className="button-clear"><i className="ti ti-sharethis ti-2x" onClick={() => { $('#nya').modal('show'); }} /></button>
                        </div>
                    </div>
                    {/* End Widget Header */}
                    {/* Begin Widget Body */}
                    {
                        this.props.patch.description ?
                            <div className="widget-body">
                                <p>
                                    <Linkify properties={{ target: '_blank' }}>{this.props.patch.description}</Linkify>
                                </p>
                            </div> : null
                    }
                    {/* End Widget Body */}
                    {/* Begin Widget Recipe */}
                    {
                        this.props.patch.recipe && this.props.showRecipe ?
                            <div className="widget-body widget-footer">
                                <p>
                                    <Linkify properties={{ target: '_blank' }}>{this.props.patch.recipe}</Linkify>
                                </p>
                            </div> : null
                    }
                    {/* End Widget Recipe */}
                    {/* Begin Widget Footer */}
                    <div className="widget-footer d-flex align-items-center">
                        <div className="col-xl-8 col-md-8 col-5 no-padding d-flex justify-content-start">
                            <LikesUserAvatars likes={likes} user={this.props.user} />
                        </div>
                        <div className="col-xl-4 col-md-4 col-7 no-padding d-flex justify-content-end">
                            {/* <FadeIn> */}
                            <div className="meta">
                                <ul>
                                    {/* {this.props.patch.s3Key && <li>
                                        <a href={this.props.patch.s3Key}>
                                            <i className="la la-play-circle" />
                                        </a>
                                    </li>} */}
                                    <li>

                                        <Link to={"/patch/" + this.props.patch.s3Key}><i className="la la-comment" /></Link>
                                    </li>
                                    <li>
                                        <a href={"/patch/" + this.props.patch.s3Key + "/like"} onClick={(e) => {
                                            e.preventDefault();
                                            likePatch(this.props.patch.s3Key)
                                                .then(SoundEngine.play(SoundEngine.SFX.like))
                                                .then(() => {

                                                    if (this.state.liked) {

                                                        let newLikes = this.state.likes.filter((val) => {
                                                            return val.user.username !== this.props.user.username
                                                        });

                                                        this.setState({ liked: false, likes: newLikes })
                                                    } else {

                                                        let newLikes = [...this.state.likes];
                                                        newLikes.push({ user: this.props.user });

                                                        this.setState({ liked: true, likes: newLikes })
                                                    }
                                                })
                                                .catch(err => { "Failed to Like oof" });
                                        }}>
                                            <span className="numb">{this.state.likes && this.state.likes.length}</span> {this.state.liked === false ? <i className="la la-heart-o" /> : <i className="la la-smile-o"></i>}
                                        </a>
                                    </li>
                                    <li>
                                        <Link to={`/patch/${this.props.patch.s3Key}`} onClick={(e) => {
                                            e.preventDefault();

                                            downloadPatch(this.props.patch.s3Key).catch(() => {
                                                NotificationEngine.error({ text: "Oopsie doopsie! I couldn't get the file!" });
                                                NotificationEngine.error({ text: "Tweet @deztructicus and he'll fix this! :-)" });
                                            });
                                        }}>
                                            <span className="numb">{this.props.patch.downloads}</span> <FontAwesomeIcon icon={faDownload} />
                                        </Link>
                                    </li>
                                    <li>
                                        {this.props.patch.s3KeyPreview && <a href="/preview" onClick={(e) => {
                                            e.preventDefault();

                                            getSynthPreview(this.props.patch.s3Key)
                                                .then(url => SoundEngine.play(url))

                                        }}>
                                            <i className="la la-play-circle" />
                                        </a>}
                                    </li>
                                </ul>
                            </div>
                            {/* </FadeIn> */}
                        </div>
                    </div>
                    {/* End Widget Footer */}
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(PatchWidget);