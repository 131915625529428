import userReducer from "./user-reducer.js";
import notificationReducer from "./notificationReducer.js";
import authenticationReducer from "./authentication-reducer";
import settingsReducer from "reducers/settings-reducer";
import { combineReducers } from "redux";
import eastereggReducer from "./easteregg-reducer.js";

export default () =>
  combineReducers({
    user: userReducer,
    notifications: notificationReducer,
    loggedIn: authenticationReducer,
    settings: settingsReducer,
    easterEggs: eastereggReducer
  });
