import React, { Component } from 'react';
import logo from "assets/img/alt-logo.png";
import { FormattedMessage } from 'react-intl';
import { Sticky } from 'react-sticky';

class NewsFeed extends Component {
    render() {


        // let Groups = () => {

        //     return <div className="widget has-shadow">
        //         <div className="widget-header bordered no-actions d-flex align-items-center">
        //             <h5>Popular Groups</h5>
        //         </div>
        //         <div className="widget-body p-3">
        //             <ul className="pop-groups list-group w-100">
        //                 {/* 01 */}
        //                 <li className="list-group-item">
        //                     <div className="media">
        //                         <div className="media-left align-self-center mr-3">
        //                             <img src={logo} alt="..." style={{ width: '50px' }} />
        //                         </div>
        //                         <div className="media-body align-self-center">
        //                             <h5 className="m-0">synthCity</h5>
        //                             <small className="m-0">
        //                                 Great Template
        //                                                 </small>
        //                         </div>
        //                         <div className="media-right align-self-center">
        //                             <i className="la la-heart-o" data-toggle="tooltip" data-placement="top" title="Add To Favs" />
        //                         </div>
        //                     </div>
        //                 </li>
        //                 {/* End 01 */}
        //                 {/* 02 */}
        //                 <li className="list-group-item">
        //                     <div className="media">
        //                         <div className="media-left align-self-center mr-3">
        //                             <img src="assets/img/icone-01.png" alt="..." style={{ width: '50px' }} />
        //                         </div>
        //                         <div className="media-body align-self-center">
        //                             <h5 className="m-0">Love Ballons</h5>
        //                             <small className="m-0">
        //                                 Company
        //                                                 </small>
        //                         </div>
        //                         <div className="media-right align-self-center">
        //                             <i className="la la-heart-o" data-toggle="tooltip" data-placement="top" title="Add To Favs" />
        //                         </div>
        //                     </div>
        //                 </li>
        //                 {/* End 02 */}
        //             </ul>
        //         </div>
        //     </div>
        // }

        // let Suggestions = () => {
        //     return <div className="widget has-shadow">
        //         <div className="widget-header bordered no-actions d-flex align-items-center">
        //             <h5>Suggestions</h5>
        //         </div>
        //         <div className="widget-body p-3">
        //             <ul className="pop-groups list-group w-100">
        //                 {/* 01 */}
        //                 <li className="list-group-item">
        //                     <div className="media">
        //                         <div className="media-left align-self-center mr-3">
        //                             <img className="rounded-circle" src="assets/img/avatar/avatar-07.jpg" alt="..." style={{ width: '50px' }} />
        //                         </div>
        //                         <div className="media-body align-self-center">
        //                             <h5 className="m-0">Lisa Garett</h5>
        //                         </div>
        //                         <div className="media-right align-self-center">
        //                             <button className="btn btn-gradient-01">Add</button>
        //                         </div>
        //                     </div>
        //                 </li>
        //                 {/* End 01 */}
        //                 {/* 02 */}
        //                 <li className="list-group-item">
        //                     <div className="media">
        //                         <div className="media-left align-self-center mr-3">
        //                             <img className="rounded-circle" src="assets/img/avatar/avatar-08.jpg" alt="..." style={{ width: '50px' }} />
        //                         </div>
        //                         <div className="media-body align-self-center">
        //                             <h5 className="m-0">Peter Collins</h5>
        //                         </div>
        //                         <div className="media-right align-self-center">
        //                             <button className="btn btn-gradient-01">Add</button>
        //                         </div>
        //                     </div>
        //                 </li>
        //                 {/* End 02 */}
        //                 {/* 03 */}
        //                 <li className="list-group-item">
        //                     <div className="media">
        //                         <div className="media-left align-self-center mr-3">
        //                             <img className="rounded-circle" src="assets/img/avatar/avatar-09.jpg" alt="..." style={{ width: '50px' }} />
        //                         </div>
        //                         <div className="media-body align-self-center">
        //                             <h5 className="m-0">Michael Bradley</h5>
        //                         </div>
        //                         <div className="media-right align-self-center">
        //                             <button className="btn btn-gradient-01">Add</button>
        //                         </div>
        //                     </div>
        //                 </li>
        //                 {/* End 03 */}
        //             </ul>
        //         </div>
        //     </div>
        // }

        let RegisterLoveMark = () => {
            return <div className="widget no-bg text-center">
                <img className="d-block mx-auto" src={logo} alt="..." style={{ width: '100px' }} />
                <h3 className="text-primary mt-4"><FormattedMessage id="Home.welcome" defaultMessage="welcome to synthCity!" /></h3>
                <p>Share Presets - Practice Sound Design</p>
                {/* <a href="pages-register.html" className="btn btn-shadow mt-4">Create Account!</a> */}
            </div>
        }

        return (
            <div className="col-xl-3 column news-feed">
                <Sticky>

                    {({ isSticky }) => {

                        let classes = '';
                        if (isSticky) {
                            classes = 'sticky is-sticky';
                        }

                        return <div className={classes}>
                            {/* <Groups /> */}
                            {/* <Suggestions />  */}
                            <RegisterLoveMark />
                        </div>
                    }}
                </Sticky>
            </div>);
    }
}

export default NewsFeed;