import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { getChallengesForUser, getSuggestedChallengesForUser } from 'services/challengewsao';
import NotificationEngine from 'engines/notification/NotificationEngine';
import { connect } from 'react-redux';
import ChallengeHistoryTab from './ChallengeHistoryTab';
import SolveChallengeTab from './SolveChallengeTab';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
// import thumbnail from 'assets/img/thumbnails/skifonixThumbnail.jpg';
// import ReactGA from 'react-ga';
import Header from 'components/common/Header';
import Widget from 'components/profile/Widget';
import ChallengeReviewTab from './ChallengeReviewTab';
import Modal from 'components/common/Modal';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import SubHeader from 'components/common/SubHeader';
import ChallengeWidget from './ChallengeWidget';
import twoTokensImg from 'assets/img/badges/2Token.png';
import TitleController from 'components/common/TitleController';

class ChallengePage extends Component {

    _isMounted = false;
    state = {
        solvedChallenges: [],
        createdChallenges: [],
        suggestedChallenges: [],
        tokens: 2,
        untilNext: 3,
        player: {}
    }

    constructor(props) {
        super(props);

        this.loadChallengeHistory = this.loadChallengeHistory.bind(this);
    }

    loadChallengeHistory(username) {

        getChallengesForUser(username).then(challenges => {
            this._isMounted && this.setState({ createdChallenges: challenges });
        }).catch(e => {
            NotificationEngine.error('Could not load your challenge history');
        });
    }

    loadSuggestedChallenges(username){
        
        getSuggestedChallengesForUser(username).then(challenges => {
            this._isMounted && this.setState({ suggestedChallenges: challenges });
        }).catch(e => {
            NotificationEngine.error('Could not load the suggested challenges.');
        });
    }
    
    componentDidMount() {

        this._isMounted = true;

        
        if(localStorage.getItem("seenTokenPrompt") !== "true"){
            $('#free-token').modal('show');

            localStorage.setItem("seenTokenPrompt", "true"); 
        }

        
        this.loadSuggestedChallenges(this.props.user.username);
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(next) {
        // TODO move these loading to their own Components, this shouldn't be in this page
        this.loadChallengeHistory(next.user.username);
        this.loadSuggestedChallenges(next.user.username);
    }

    render() {

        const { createdChallenges, suggestedChallenges } = { ...this.state };
        const { tokens = 0, totalSolved = 0 } = { ...this.props.user.challengeStats }
        const untilNext = ((totalSolved % 3) - 3) * -1;
        let tokenStyle = tokens > 0 ? "" : "text-danger"


        let SuggestedChallengeWidgets = (props, i) => {

            let { challenges, review, solve } = { ...props };

            let ChallengeWidgets = [];

            challenges.forEach((challenge, i) => {
                ChallengeWidgets.push(<ChallengeWidget key={i} challenge={challenge} review={review} solve={solve} />);
            });
            return <FadeIn>{ChallengeWidgets}</FadeIn>;

        }

        return (
            <FadeIn>
                <TitleController title="Recipes" />
                <div className="page">
                    <Modal title="Who are you challenging?"
                        body="With challenges you can either post it for the community to solve, or solve it yourself to earn points. Who would you like to solve this challenge?"
                        yesText="Personal Challenge"
                        medium
                        blue
                        modalId="challenge-create-modal"
                        noText="Community Challenge"
                        onAccept={() => this.props.history.push('/challenge/create?t=self')}
                        onReject={() => this.props.history.push('/challenge/create?t=community')}
                    />

                    <Modal title="Here you go! 💖"
                        body={<div> <div className="text-center"><img src={twoTokensImg} alt="two token icon" /> <br/><br/><br/></div> <div className="text-center">On the house! <span aria-label="smiley face" role="img">😀</span></div></div>}
                        confirmation
                        medium
                        modalId="free-token"
                        />

                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-11">
                                {/* Main Page area */}
                                <div className="row ">

                                    {/* Start Left Side bar (top when on mobile) */}
                                    <div className="col-xl-2 column">
                                        {/* Start Challenge Button */}
                                        <div className="has-shadow">
                                            <div className="widget-body">
                                                <div className="d-flex justify-content-center">
                                                    <Link className="btn btn-shadow btn-gradient-01 " to="/challenge/create" onClick={(e) => {
                                                        e.preventDefault();
                                                        $('#challenge-create-modal').modal('show');
                                                    }}>Create Challenge</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Button */}

                                        {/* Start tabs */}
                                        <div className="widget has-shadow">
                                            <div className="widget-body p-0 d-flex align-items-center d-flex justify-content-center">
                                                <ul className="nav nav-tabs nav-left flex-column" role="tablist" aria-orientation="vertical">
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="/challenge/overview">Home</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="/challenge/solve" >Solve Challenges</Link>
                                                    </li>
                                                    {/* <li className="nav-item">
                                                        <a className="nav-link" id="vert-tab-3" data-toggle="tab" href="#v-tab-3" role="tab" aria-controls="v-tab-3" aria-selected="false">Explore Challenges</a>
                                                    </li> */}
                                                    <li className="nav-item">
                                                        <Link to="/challenge/history" className="nav-link" >Challenges I Created</Link>
                                                    </li>
                                                    {this.props.user.permissions.includes('reviewer') && <li className="nav-item">
                                                        <Link to="/challenge/review" className="nav-link" >Review</Link>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End tabs */}

                                        {/* Start Stats */}
                                        <div className="widget has-shadow widget-header align-items-centered">
                                            <div className="user-image">Tokens: <span className={tokenStyle}>{tokens}</span></div>
                                            <div className="user-image">Until Next Token: {untilNext}</div>
                                        </div>
                                        {/* End Stats */}
                                    </div>
                                    {/* End Left side bar */}

                                    {/* Start mid section (should have all the challenge info and stuff) */}
                                    <div className="tab-content d-flex column col-xl-7">

                                        <div className="col-12">
                                            <Switch>
                                                <Route exact path={"/challenge/overview"} render={() => <FadeIn><div className="tab-pane fade show active col-12">

                                                    <Header text="Overview" />
                                                    <Widget body={<div><p>Learn and improve your sound design by solving challenges. Every 3 challenges you solve, you get a token to challenge others</p> <p>Good luck and have fun learning!</p></div>} />

                                                    <Widget
                                                        header="How Do I Request a Recipe?"
                                                        body="Click the 'Create Challenge' Button to get started. Every challenge you create costs a token."
                                                    />

                                                    <Widget header="How Do I Earn Tokens?" body={<div>
                                                        <p>Earn tokens by helping others! In every Challenge you will be presented with a Song, a short description of the challenge and Time indicators showing where the sound starts and ends. Solve the challenge by uploading a preset in the preset panel and describing the recipe.
                                                        In your recipe make sure to include the following.</p>
                                                        <ol>
                                                            <li>1. The oscillator(s) and their waveform(s)/wavetable(s)</li>
                                                            <li>2. Mix between the Oscillators</li>
                                                            <li>3. Filters</li>
                                                            <li>4. Any Modulation</li>
                                                        </ol>
                                                        <p>
                                                            Solutions are reviewed daily and you get your tokens shortly after review.
                                                            Good luck synthCitizen!
                                                        </p>
                                                        <h5>PROTIP!</h5>
                                                        
                                                        <p>
                                                        Feeling intimidated by community requests? No problem! Just create your own Challenge appropriate to your level. 
                                                    
                                                        </p>
                                                        <p>
                                                            
                                                        Select <i>Create Challenge</i> then <i>Personal Challenge</i> to get started. 
                                                        
                                                        </p>
                                                        <p>
                                                        Personal Challenges costs no Tokens and are only solvable by you. Its an easy way to rack up Tokens while still practicing sound design. 
                                                        </p>
                                                        </div>} />
                                                    <Widget header="FAQ" body={<div>
                                                        <h5>Can I solve my own challenges?</h5>
                                                        Yes you can and you'll also earn tokens same as any other challenge. The goal ultimately is to learn by doing so feel free to solve your own challenges.

                                                        <br /><br />
                                                        <h5>Does solving my own challenges spend tokens?</h5>
                                                        No it doesn't. If you create a challenge and solve it yourself, you do not lose any tokens. You however must also submit the solution as you create it. If you submit the challenge without the solution you lose the Token.

                                                        </div>} />



                                                    {/* <figure className="img-hover-02">
                                                        <img src={thumbnail} className="img-fluid" alt="feature update thumbnail" />
                                                        <i className="ion-play" />
                                                        <a href={"https://www.youtube.com/watch?v=vwHdjCna0To"} onClick={() => {
                                                            ReactGA.event({
                                                                category: 'Engagement',
                                                                action: 'Watched Challenge Video',
                                                                value: 1
                                                            });
                                                        }} data-lity></a>
                                                    </figure> */}
                                                </div> </FadeIn>} />


                                                {/* Begin Challenge Widget */}

                                                <Route exact path={`/challenge/solve`} render={() => <Redirect to={`/challenge/solve/random`} />} />
                                                <Route path={"/challenge/solve/:id"} component={SolveChallengeTab} />


                                                <Route exact path={"/challenge/review"} render={() => <Redirect to={`/challenge/review/random`} />} />
                                                <Route path={"/challenge/review/:id"} component={ChallengeReviewTab} />


                                                {/* Challenges created  */}
                                                <Route exact path={"/challenge/history"} render={() => <ChallengeHistoryTab challenges={createdChallenges} />} />

                                                {/* Start Challenges solved  */}
                                                {/* <Route exact path={"/challenge/solved"} render={() => <ChallengeHistoryTab challenges={createdChallenges} />} /> */}


                                            </Switch>
                                        </div>
                                    </div>
                                    {/* End Mid Section */}
                                    {/* Start Right Side Bar (suggested Challenges) */}
                                    <div className="col-xl-3" >
                                        
                                        {/* <h3>Suggested Challenges</h3> */}
                                        <SubHeader text="Suggested Challenges" />
                                        <Switch>
                                            <Route path={"/challenge/review"} render={() => <SuggestedChallengeWidgets challenges={suggestedChallenges} review />} />
                                            <Route path={"/challenge/solve"} render={() => <SuggestedChallengeWidgets challenges={suggestedChallenges} solve/>} />
                                            <Route path={"/challenge/overview"} render={() => <SuggestedChallengeWidgets challenges={suggestedChallenges} solve/>} />
                                            <Route path={"/challenge/history"} render={() => <SuggestedChallengeWidgets challenges={suggestedChallenges} solve/>} />
                                        </Switch>
                                    </div>
                                        
                                </div>
                                {/* End Main page area */}
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn >
        );
    }
}

const mapStateToProps = (state, props) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(withRouter(ChallengePage));