import React from 'react';

function Button(props) {
    let { text, onClick, type, hasShadow } = { ...props };
    let buttonStyle = ""
    let shadow = "";

    switch (type) {
        case "gradient":
            buttonStyle = 'btn-gradient-01';
            break;
        case "info":
            buttonStyle = 'btn-info';
            break;
        case "shadow":
            buttonStyle = "btn-shadow"
            break;
        default:
            buttonStyle = "btn-gradient-01";

    }

    if (hasShadow) {
        shadow = "btn-shadow";
    }


    return <button className={"btn mt-3 mb-3 " + buttonStyle + " " + shadow} onClick={onClick} {...props}>{text}</button>
}

export default Button;