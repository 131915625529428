export const TRIGGER_DAY_1_PATRON_EASTER_EGG = "easteregg:triggerDay1PatronEasterEgg";

export const triggerEasterEgg = (show) => {
    return {
        type: TRIGGER_DAY_1_PATRON_EASTER_EGG,
        payload: {
            day1: show
        }
    };
};

export const day1EasterEgg = () => {
    return triggerEasterEgg(true);
};

export const day1EasterEggStop = () => {
    return triggerEasterEgg(false);
};