import React, { PureComponent } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import defaultProfilePhoto from "assets/img/no-photo.png";
import Footer from 'components/common/Footer';
import { Link } from 'react-router-dom';
import { uploadProfilePhoto } from 'services/userwsao'
import ReactGA from 'react-ga';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FormattedMessage, injectIntl,intlShape, defineMessages } from 'react-intl';
import NotificationEngine from "engines/notification/NotificationEngine"

class ProfileEditPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            displayName: '',
            name: '',
            email: '',
            website: '',
            city: '',
            aboutMe: this.props.user.generalInfo.aboutMe,

            hideProfile: this.props.user.settings.hideProfile ? true : false,
            wantsEmailNotifications: this.props.user.settings.wantsEmailNotifications ? true : false, 

            file: {
                data: null,
                type: ''
            },
            src: null,
            croppedImageUrl: this.props.user.profilePhoto,
            crop: {
                aspect: 1,
                width: 50,
                x: 0,
                y: 0,
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(e) {
        var oldData = this.props.user
        var payload = {
            username: oldData.username,
            name: this.state.name || oldData.name,
            email: this.state.email || oldData.email,
            displayName: this.state.displayName || oldData.displayName,
            generalInfo: {
                website: this.state.website || oldData.generalInfo.website,
                city: this.state.city || oldData.generalInfo.city,
                aboutMe: this.state.aboutMe || oldData.generalInfo.aboutMe
            },
            settings: {
                hideProfile: this.state.hideProfile || oldData.hideProfile,
                wantsEmailNotifications: this.state.wantsEmailNotifications || oldData.wantsEmailNotifications
            }
        }

        if (this.state.file.type) {
            payload.profilePhoto = oldData.username + this.state.file.type;
        }

        // upload file to S3 if user changed photo
        if (this.state.file.data) {
            uploadProfilePhoto(this.props.user.username, payload.profilePhoto, this.state.file.data).then(() => {

                // save to db and update store
                this.props.saveProfileChanges(payload);

                NotificationEngine.info("Updated your profile!");

                ReactGA.event({
                    category: 'Social',
                    action: 'User Edited Profile Photo',
                    value: 1
                });

            }).catch(err => {
                NotificationEngine.error("Something went wrong while trying to update your profile");
            });
            // user didn't change photo but we still need to update data
        } else {

            this.props.saveProfileChanges(payload);
            NotificationEngine.info("Updated your profile!");
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {

            let fileType = e.target.files[0].name.split('.').pop();

            this.setState({
                file: {
                    data: e.target.files[0],
                    type: '.' + fileType,
                }
            });

            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result }),
            );
            reader.readAsDataURL(e.target.files[0]);
            this.displayModal()
        }
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        this.setState({ crop });

    };

    async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg',
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                let fileType = this.state.file.type;

                this.setState({
                    file: {
                        data: blob,
                        type: fileType
                    }
                });
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    render() {
        const { crop, src } = this.state;
        const intl = this.props.intl;
        const messages = defineMessages({
            displaynameplaceholder: {
                id: 'ProfileEditPage.displaynameplaceholder',
                defaultMessage: 'your profile name',
            },
            nameplaceholder:{
                id:"ProfileEditPage.nameplaceholder"
            },
            emailplaceholder:{
                id:"ProfileEditPage.emailplaceholder"
            },
            websiteplaceholder:{
                id:"ProfileEditPage.websiteplaceholder"
            },
            cityplaceholder:{
                id:"ProfileEditPage.cityplaceholder"
            },
            aboutmeplaceholder:{
                id:"ProfileEditPage.aboutmeplaceholder"
            }
        });

        return (
            <FadeIn>
                {/* Begin Content */}
                    <div className="container-fluid">
                        {/* Begin Page Header*/}
                        <div className="row">
                            <div className="page-header">
                                <div className="d-flex align-items-center">
                                    <h2 className="page-header-title"><FormattedMessage id="ProfileEditPage.profile" defaultMessage="Profile" /></h2>
                                    <div>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/"><i className="ti ti-home" /></Link></li>
                                            <li className="breadcrumb-item"><Link to={"/citizen/"+this.props.user.username}><FormattedMessage id="ProfileEditPage.profile" defaultMessage="Profile" /></Link></li>
                                            <li className="breadcrumb-item active"><FormattedMessage id="ProfileEditPage.edit" defaultMessage="Edit" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Page Header */}
                        <div className="row flex-row">
                            <div className="col-xl-3">
                                {/* Begin Widget */}
                                <div className="widget has-shadow">
                                    <div className="widget-body">
                                        <p className="text-center">
                                            <button className="btn btn-shadow btn-gradient-01" ><label htmlFor="profileImageUpload"><FormattedMessage id="ProfileEditPage.changephoto" defaultMessage="Change Photo" /></label></button>
                                            <input type="file" id="profileImageUpload" onChange={this.onSelectFile} name="profileImageUpload" accept="image/jpeg, image/png" hidden />
                                        </p>
                                        <div className="mt-5">
                                            {/* <div className="relative">
                                                <a href='#' className="editCog">
                                                    <i className="la la-gear"></i>
                                                </a>
                                            </div> */}
                                            <img src={this.state.croppedImageUrl || defaultProfilePhoto} alt="..." style={{ width: '120px' }} className="avatar rounded-circle d-block mx-auto" crossOrigin="anonymous" />

                                        </div>


                                        <h3 className="text-center mt-3 mb-1">{this.props.user.displayName}</h3>
                                        <p className="text-center">@{this.props.user.username}</p>
                                        <div className="em-separator separator-dashed" />
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                {/* <a className="nav-link" href="javascript:void(0)"><i className="la la-bell la-2x align-middle pr-2" />Notifications</a> */}
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link className="nav-link" to="/profile/edit"><i className="la la-bolt la-2x align-middle pr-2" />Activity<sup className="text-warning">coming soon</sup></Link>
                                            </li> */}
                                            <li className="nav-item">
                                                {/* <a className="nav-link" href="javascript:void(0)"><i className="la la-comments la-2x align-middle pr-2" />Messages</a> */}
                                            </li>
                                            <li className="nav-item">
                                                {/* <a className="nav-link" href="javascript:void(0)"><i className="la la-bar-chart la-2x align-middle pr-2" />Statistics</a> */}
                                            </li>
                                            <li className="nav-item">
                                                {/* <a className="nav-link" href="javascript:void(0)"><i className="la la-clipboard la-2x align-middle pr-2" />Tasks</a> */}
                                            </li>
                                            <li className="nav-item">
                                                {/* <a className="nav-link" href="javascript:void(0)"><i className="la la-gears la-2x align-middle pr-2" />Settings</a> */}
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="https://www.mazinomedia.com/synthcity#faq" data-lity rel="noopener noreferrer" ><i className="la la-question-circle la-2x align-middle pr-2" />FAQ</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Widget */}
                            </div>
                            <div className="col-xl-9">
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4><FormattedMessage id="ProfileEditPage.updateprofile" defaultMessage="Update Profile" /></h4>
                                    </div>
                                    <div className="widget-body">
                                        <form className="form-horizontal" >

                                            <div className="col-10 ml-auto">
                                                <div className="section-title mt-3 mb-3">
                                                    <h4>01. <FormattedMessage id="ProfileEditPage.account" defaultMessage="Account" /></h4>
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.displayname" defaultMessage="Display Name" /></label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" value={this.state.displayName} name="displayName" onChange={this.handleChange} placeholder={this.props.user.displayName || intl.formatMessage(messages.displaynameplaceholder) } />
                                                </div>
                                            </div>


                                            <div className="col-10 ml-auto">
                                                <div className="section-title mt-3 mb-3">
                                                    <h4>02. <FormattedMessage id="ProfileEditPage.details" defaultMessage="Deets" /></h4>
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.name" defaultMessage="Full Name" /></label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" value={this.state.name} name="name" onChange={this.handleChange} placeholder={this.props.user.name ||  intl.formatMessage(messages.nameplaceholder)} />
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.email" defaultMessage="Email" /></label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" value={this.state.email} name="email" onChange={this.handleChange} placeholder={this.props.user.email ||  intl.formatMessage(messages.emailplaceholder)} />
                                                </div>
                                            </div>
                                            {/* <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end">Phone</label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" placeholder={this.props.user.phonenumber || 'your number'} />
                                                </div>
                                            </div> */}
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.website" defaultMessage="Website" /></label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" value={this.state.website} name="website" onChange={this.handleChange} placeholder={this.props.user.generalInfo.website ||  intl.formatMessage(messages.websiteplaceholder)} />
                                                </div>
                                            </div>
                                        </form>
                                        {/* <div className="col-10 ml-auto">
                                            <div className="section-title mt-3 mb-3">
                                                <h4>02. Address Information</h4>
                                            </div>
                                        </div> */}
                                        <form className="form-horizontal">
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.city" defaultMessage="City" /></label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" onChange={this.handleChange} value={this.state.city} name="city" placeholder={this.props.user.generalInfo.city ||  intl.formatMessage(messages.cityplaceholder)} />
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"><FormattedMessage id="ProfileEditPage.aboutme" defaultMessage="About Me" /></label>
                                                <div className="col-lg-6">
                                                    <textarea rows="6" type="text" className="form-control" value={this.state.aboutMe} name="aboutMe" onChange={this.handleChange} placeholder={this.props.user.generalInfo.aboutMe ||  intl.formatMessage(messages.aboutmeplaceholder)} />
                                                </div>
                                            </div>

                                            <div className="col-10 ml-auto">
                                                <div className="section-title mt-3 mb-3">
                                                    <h4>03. Settings</h4>
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"></label>
                                                <div className=" col-lg-6 custom-control custom-checkbox styled-checkbox">
                                                    <input className="custom-control-input" type="checkbox" name="hideProfile" id="check-1" onChange={this.handleChange} checked={this.state.hideProfile} />
                                                    <label className="custom-control-descfeedback" htmlFor="check-1"><FormattedMessage id="ProfileEditPage.hidedeets" defaultMessage="Hide Deets on profile" /></label>
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end"></label>
                                                <div className=" col-lg-6 custom-control custom-checkbox styled-checkbox">
                                                    <input className="custom-control-input" type="checkbox" name="wantsEmailNotifications" id="wantsEmailNotifications" onChange={this.handleChange} checked={this.state.wantsEmailNotifications} />
                                                    <label className="custom-control-descfeedback" htmlFor="wantsEmailNotifications">Notify me when I get a message</label>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="em-separator separator-dashed" />
                                        <div className="text-right">
                                            <Link className="btn btn-shadow" to={`/citizen/${this.props.user.username}`}><FormattedMessage id="ProfileEditPage.back" defaultMessage="back" /></Link>
                                            <button className="btn btn-shadow btn-gradient-01 " type="submit" onClick={this.handleSubmit}><FormattedMessage id="ProfileEditPage.save" defaultMessage="save" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="button" className="btn btn-success" data-toggle="modal" data-target="#success-modal" ref={button => this.launchModalButton = button} hidden>Launch Modal</button>
                            <div id="success-modal" className="modal fade">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <div className="section-title mt-5 mb-2">
                                                <h2 className="text-gradient-02"><FormattedMessage id="ProfileEditPage.smile" defaultMessage="Smile for the cam!" /></h2>
                                            </div>
                                            <p className="mb-5"><FormattedMessage id="ProfileEditPage.adjust" defaultMessage="Adjust your photo" /></p>
                                            <div>
                                                {src && (
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                    />
                                                )}
                                            </div>
                                            <button type="button" className="btn btn-shadow mb-3" data-dismiss="modal"><FormattedMessage id="ProfileEditPage.done" defaultMessage="Donezo!" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* End Row */}
                    </div>
                    {/* End Container */}
                    <Footer />
                    {/* End Page Footer */}
                    <button href="#" className="go-top"><i className="la la-arrow-up" /></button>

                {/* End Content */}
            </FadeIn>
        );
    }

    displayModal = (e) => {
        this.launchModalButton.click();
    }
}

ProfileEditPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ProfileEditPage);
