import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

class GoogleAnalytics extends Component {
    componentDidUpdate({ location, history }) {

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH' && process.env.NODE_ENV === "production") {
            ReactGA.pageview(window.location.pathname);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);;