import React, { Component } from 'react';
import ProfilePage from './ProfilePage';
import { loadUser } from 'services/userwsao.js';
import { Redirect } from 'react-router-dom';

class ProfileLoader extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { redirect: false, user: {} };
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/NotFound' />
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const { match: { params } } = this.props;

        loadUser(params.username)
            .then(user => {
                this._isMounted && this.setState({ user });
            })
            .catch(err => {
                // can't find this so might as well 404
                if (err && err.status === 404) {
                    this._isMounted && this.setState({ redirect: true });
                }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div>
                {this.renderRedirect()}
                {this.state && this.state.user && <ProfilePage user={this.state.user} preview />}
            </div>
        );
    }
}

export default ProfileLoader;