// All these imports are trash, I should really redesign this...
import 'assets/css/elisyam-1.5.css';
import 'assets/vendors/js/base/core.js';
import 'datatables-buttons';
import 'datatables/media/css/jquery.dataTables.min.css';
import 'datatables';
import 'assets/vendors/js/base/tables.js';
import 'assets/vendors/js/base/nicescroll.js';
import 'assets/vendors/js/app/app.js';
import 'assets/js/pages/newsfeed.js';

// this are aaight
import React, { Component } from 'react';
import SynthCityNav from 'components/common/SynthCityNav'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import Library from 'components/synthLibrary/Library';
import UploadPage from '../synthShare/UploadPage';
import ProfileEditPage from 'components/profile/edit/ProfileEditPage';
import ProfilePage from 'components/profile/ProfilePage';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/user-actions'
import NotFoundPage from '../common/NotFoundPage';
import queryString from 'query-string';

// cept these two... they're cunts
import 'lity/dist/lity.js';
import 'lity/dist/lity.css';

import ProfileLoader from '../profile/ProfileLoader';
import AdminPage from '../profile/AdminPage';
import SideBarNav from '../common/SideBarNav';
import Title from 'components/common/TitleController';
import LandingPage from 'components/home/LandingPage';
import TutorialEngine from 'engines/tutorial/TutorialEngine';
import GoogleAnalytics from 'components/common/GoogleAnalytics'
import SearchPage from 'components/synthLibrary/SearchPage';
import CollectionPage from 'components/patch/CollectionPage';
import SynthRequest from 'components/synthrequest/SynthRequest';
import ChallengePage from 'components/challenge/ChallengePage';
import ChallengeTokenOrderPage from 'components/challenge/BuyChallengeTokenPage';

import { Elements, StripeProvider } from 'react-stripe-elements';
import PatchPage from 'components/patch/PatchPage';
// import SSOPage from './SSOPage';

class Home extends Component {
    constructor(props) {
        super(props)
        this.onUpdateProfile = this.onUpdateProfile.bind(this)
    }

    onUpdateProfile(userUpdate) {
        this.props.onUpdateProfile(userUpdate)
    }

    render() {

        // Component used to redirect to particular page based on URL 
        const Redirector = () => {

            const destinationURL = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).r;
            let destination = null;

            if (destinationURL) {
                destination = <Redirect to={destinationURL} />;
            }

            return destination;
        }

        return (
            <div className='Home'>

                <Router>
                    <div className="page db-social">
                        <Title title="synthCity" />
                        <SynthCityNav user={this.props.user} />
                        <SideBarNav user={this.props.user} />

                        <div className="page-content d-flex align-items-stretch">
                            <div className="content-inner compact">

                                <TutorialEngine />
                                <Redirector />


                                <Switch>

                                    {/* Pages that require users being loggedIn */}
                                    <Route exact path="/" render={() => <LandingPage name={this.props.user.name} user={this.props.user} />} />
                                    <Route path="/admin" component={AdminPage} />
                                    <Route exact path="/synthLibrary" component={Library} />
                                    <Route exact path="/challenge/create" component={SynthRequest} />
                                    <Route path="/challenge" component={ChallengePage} />
                                    <Route exact path="/search" render={() => <SearchPage user={this.props.user} />} />
                                    <Route exact path="/synthShare" render={() => <UploadPage user={this.props.user} />} />
                                    <Route exact path="/profile/edit" render={() => <ProfileEditPage user={this.props.user} saveProfileChanges={this.onUpdateProfile} />} />
                                    <Route path={`/citizen/${this.props.user.username}`} render={() => <ProfilePage user={this.props.user} />} />
                                    <Route path="/collection/:id" component={CollectionPage} />
                                    <Route path="/patch/:id" component={PatchPage} />
                                    {/* <Route path="/sso/" component={SSOPage} /> */}

                                    <Route path="/buy/challenge_tokens" render={() => {

                                        return <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                                            <Elements>
                                                <ChallengeTokenOrderPage />
                                            </Elements>
                                        </StripeProvider>
                                    }} />

                                    <Route path="/NotFound" component={NotFoundPage} />
                                    <Route path="/citizen/:username" component={ProfileLoader} />

                                    {/* Default route if nothing else works */}
                                    <Redirect to="/NotFound" />
                                </Switch>
                            </div>
                        </div>

                        <GoogleAnalytics />
                    </div>
                </Router>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    user: state.user,
});

const mapActionsToProps = {
    onUpdateProfile: updateProfile,
}

export default connect(mapStateToProps, mapActionsToProps)(Home);