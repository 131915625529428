import React, { Component } from 'react';
import Select from 'react-select'
import NotificationEngine from 'engines/notification/NotificationEngine';
import { editCollection } from 'services/collectionwsao';
import SuperArray from 'super-array';
import { withRouter } from 'react-router-dom';

class EditCollectionModal extends Component {

    state = {
        name: this.props.name,
        file: {},
        description: this.props.description,
        submitted: false,
        submitting: false
    }

    patchSelectComponent = React.createRef();
    closeButton = React.createRef();

    handleFile = (e) => {
        if (e.target.files[0]) {
            this.setState({
                file: e.target.files[0]
            });
        }
    }

    reset = () => {
        this.setState({
            name: '',
            file: {},
            description: '',
        });
        this.patchSelectComponent.current.state.value = [];
    }

    submit = () => {

        let patches = this.patchSelectComponent.current.state.value;
        let patch_ids = [];
        let valid = true;

        /* Begin validations */

        if (this.state.submitted) {
            NotificationEngine.warn("Can't submit twice");
            return;
        }
        if (!this.state.name) {
            valid = false;
            NotificationEngine.error("Name your collection homie!");
        }

        if (!patches || patches.length < 1) {
            valid = false;
            NotificationEngine.error("Can't have an empty collection homie!")
        }
        /* END VALIDATIONS */

        if (valid) {

            // extra s3key (id) names from patches
            for (let i = 0; i < patches.length; i++) {
                patch_ids.push(patches[i].value);
            }

            let payload = { ...this.state, patch_ids };

            this.setState({ submitting: true })

            // submit collection to api
            editCollection(this.props._id, payload).then(() => {

                // prevent further submission
                this.setState({ submitted: true, submitting: false });
                NotificationEngine.success("Donezo!");

                // close modal window
                this.closeButton.current.click();

                // redirect to collection page
                this.props.history.push('/collection/' + this.props._id);
            }).catch(() => {
                NotificationEngine.error('Something went wrong editing your collection :(');
            })
        }
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        let selectedPatches = [];

        let enrichedPatchArry = new SuperArray(JSON.parse(JSON.stringify(this.props.patches)), function (item) { return item.s3Key });

        if (this.props.patch_ids) {
            for (let i = 0; i < this.props.patch_ids.length; i++) {
                let patch = enrichedPatchArry.get(this.props.patch_ids[i]);

                if (patch) {

                    selectedPatches.push({ value: this.props.patch_ids[i], label: patch.name });
                }
            }
            this.patchSelectComponent.current.state.value = selectedPatches;
        }
    }

    render() {
        let patches = [];

        for (let i = 0; i < this.props.patches.length; i++) {
            patches.push({ value: this.props.patches[i].s3Key, label: this.props.patches[i].name })
        }
        return (

            <div id={this.props.modalId} className="modal fade">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Collection</h4>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="needs-validation" noValidate>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Name *</label>
                                    <div className="col-lg-5">
                                        <input type="text" value={this.state.name} onChange={this.handleChange.bind(this)} className="form-control" name="name" placeholder="collection name" required />
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Collection Art</label>
                                    <div className="col-lg-5">
                                        <input type="file" onChange={this.handleFile} name="file" accept=".jpg,.jpeg" />
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Description</label>
                                    <div className="col-lg-5">
                                        <textarea rows="7" type="text" value={this.state.description} onChange={this.handleChange.bind(this)} className="form-control" name="description" placeholder="describe your collection" required />
                                    </div>
                                </div>


                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Patches *</label>
                                    <div className="col-lg-5">
                                        <Select options={patches} isMulti name="patches" ref={this.patchSelectComponent} />
                                        {/* <input type="text" value={this.state.name} onChange={this.handleChange} className="form-control" name="name" placeholder="Patch Name" required /> */}
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-shadow" data-dismiss="modal" onClick={this.reset.bind(this)} ref={this.closeButton}>Close</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.submitting} onClick={this.submit.bind(this)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditCollectionModal);