import photo from 'assets/img/zeeno_avatar.jpg';
import gameImg from "assets/img/albums/game.jpg";
import coolImg from "assets/img/background/06.jpg";

export let generateStubPatch = () => {
    return {
        id: 1,
        name: 'Cool Patch.fxp',
        type: 'bass',
        rating: 5,
        s3Key: 'TxAjNbZNl',
        uploadedBy: 'TestUserOne',
        downloads: 12,
        displayImg: photo,
        synthesizer: 'serum',
        description: 'This Patch sounds very very naaais',
        recipe: "Pint of eggs, 2 sugars",
        likes: [
            {
                user: { username: 'Zeeno', profilePhoto: photo }
            }, {
                user: { username: 'TestUserTwo', profilePhoto: photo }
            }, {
                user: { username: 'TestUserOne', profilePhoto: photo }
            }, {
                user: { username: 'wesakicus', profilePhoto: photo }
            }, {
                user: { username: 'Zeeno', profilePhoto: photo }
            }, {
                user: { username: 'Zeeno', profilePhoto: photo }
            },

        ]
    };
}

export let generateStubPatches = () => {
    var patchStub = {
        id: 1,
        name: 'Cool Patch.fxp',
        type: 'bass',
        rating: 5,
        s3Key: 'TxAjNbZNl',
        uploadedBy: 'TestUserOne',
        downloads: 12,
        displayImg: photo,
        synthesizer: 'serum',
        description: 'This Patch sounds very very naaais',
        likes: [
            {
                user: { username: 'Zeeno', profilePhoto: photo }
            }, {
                user: { username: 'TestUserTwo', profilePhoto: photo }
            }, {
                user: { username: 'TestUserOne', profilePhoto: photo }
            }, {
                user: { username: 'wesakicus', profilePhoto: photo }
            }, {
                user: { username: 'Zeeno', profilePhoto: photo }
            }, {
                user: { username: 'Zeeno', profilePhoto: photo }
            },

        ]
    }

    var patchStubList = [];

    for (var i = 0; i < 700; i++) {
        let patch = { ...patchStub }
        patch.s3Key = patch.s3Key + i;
        patch.name = patch.name + "-" + i;
        patchStubList.push(patch);
    }

    return patchStubList;
}

export let collectionsStub = () => {

    return [
        {
            _id: 'jaVw2TfUy',
            name: "Video Game SFX and More!",
            img: gameImg,
            likes: [{}, {}, {}, {}, {}, {},],
            description: "Video Game Patches and more used for stuff"
        },
        {
            id: '1234',
            name: "Lo-Fi and Chill Pads",
            img: coolImg,
            likes: [{}, {}, {}, {},]
        },
        {
            id: '1234'
        },
        {
            id: '1234'
        },
        {
            id: '1234'
        }
    ]

}

export let ipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisis volutpat est velit egestas dui id. Mattis rhoncus urna neque viverra justo nec ultrices. Iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Purus sit amet luctus venenatis. Non quam lacus suspendisse faucibus. Faucibus et molestie ac feugiat. Quam lacus suspendisse faucibus interdum. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Sollicitudin ac orci phasellus egestas tellus rutrum tellus. Vitae proin sagittis nisl rhoncus. Pellentesque diam volutpat commodo sed egestas. Consequat nisl vel pretium lectus. Venenatis urna cursus eget nunc scelerisque. Vitae congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque.

Sollicitudin tempor id eu nisl nunc mi ipsum. Libero enim sed faucibus turpis in eu mi. Eleifend mi in nulla posuere sollicitudin aliquam ultrices. Pulvinar pellentesque habitant morbi tristique senectus et netus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Facilisi nullam vehicula ipsum a arcu cursus vitae. Fermentum posuere urna nec tincidunt praesent semper feugiat nibh. Quis varius quam quisque id diam. Auctor augue mauris augue neque gravida in. Augue lacus viverra vitae congue eu consequat ac felis donec. Bibendum neque egestas congue quisque egestas diam in. Ut tellus elementum sagittis vitae et leo. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Blandit libero volutpat sed cras ornare. Nec dui nunc mattis enim ut tellus elementum sagittis.`