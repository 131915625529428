import React from 'react';

function Widget(props) {

    let { header, body, footer } = { ...props }
    return (


        <div className="widget has-shadow">
            {header && <div className="widget-header">
                <h4>{header}</h4>
            </div>}
            {body && <div className="widget-body">
                {body}
            </div>}
            {footer && <div className="widget-footer">
                {footer}
            </div>}
        </div>
    );
}

export default Widget;