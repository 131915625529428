import React, { Component } from "react";
import logo from "assets/img/alt-logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "actions/user-actions";
import { logout as sessionLogout } from "../../services/authentication-wsao";
import defaultPhoto from "assets/img/no-photo.png";
import { FormattedMessage } from 'react-intl';
import notificationImg from 'assets/img/notifications/01.jpg';
import ReactTimeAgo from 'react-time-ago';
import classnames from 'classnames';
import { onNotificationSeen } from "actions/notificationActions";
import { language } from 'config/localization';
import { login } from 'actions/user-actions';
import queryString from 'query-string';
import { day1EasterEgg } from "actions/easteregg-actions";
import { withRouter } from 'react-router-dom';
// import ProfilePhoto from "./ProfilePhoto";

class SynthCityNav extends Component {

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.onLogoutRequest = this.onLogoutRequest.bind(this);
    this.onClickNotificationBell = this.onClickNotificationBell.bind(this);

    this.state = {
      query: ''
    }

  }

  toggleNavbar() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  onLogoutRequest() {
    this.props.onLogoutRequest();
  }

  onClickNotificationBell = () => {
    let notifications = this.props.notifications.unseen;

    // mark the latest 5 as see if already seen
    for (let i = 0; i < Math.min(notifications.length, 100); i++) {
      if (!notifications[i].dateSeen) {

        this.props.onNotificationSeen(notifications[i]);
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    let searchQuery = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).query;

    if (searchQuery) {
      this.setState({ query: searchQuery });
    }
  }

  runSearch(e) {
    // User is launching Easter Egg
    if (this.state.query.startsWith("\\sc")) {
      e.preventDefault();
      console.log('Easter Egg time! ^o^');

      let command = this.state.query.split("\\sc");
      switch (command[1].trim()) {
        case 'day1':
          this.props.onDay1EasterEggTriggered();
          this.props.history.push('/');
          break;

        // invalid command, do default action
        default:
          document.getElementById("searchForm").submit()
      }

    } else {
      document.getElementById("searchForm").submit()
    }
  }

  render() {
    let notifications = this.props.displayedNotifications;
    let unseenCount = this.props.unseenCount;
    let hasNotification = unseenCount > 0;

    let NotificationItems = () => {

      let items = []

      notifications.forEach((notification, i) => {

        // don't show more than 5 notifications
        if (i >= 5) {
          return;
        }

        let icon;
        switch (notification.topic) {
          case 'system':
            icon = "ti ti-harddrives";
            break;
          case 'feedback':
            icon = "la la-gift"
            break;
          case 'feature':
            icon = "la la-lightbulb-o";
            break;
          case 'event':
            icon = "la la-calendar-check-o";
            break;
          case 'milestone':
            icon = "la la-flag";
            break;
          case 'announcement':
            icon = "ti ti-comments";
            break;
          default:
            icon = "la la-info-circle";
            break;
        }

        items.push(
          <li key={i}>
            
            <a href={notification.link}>
              <div className="message-icon">
                <i className={icon} />
              </div>
              <div className="message-body">
                <div className="message-body-heading">
                  {notification.heading}
                </div>
                <span className="date"><ReactTimeAgo date={new Date(notification.dateSent)} locale={language} /></span>
              </div>
            </a>
          </li>
        );
      });

      return items;
    }

    return (
      /* Begin Header */
      <header className="header">
        <nav className="navbar fixed-top">
          {/* Begin Search Box*/}
          {/* <div className="search-box">
            <button className="dismiss">
              <i className="ion-close-round" />
            </button>
            <form id="searchForm" action="/search" role="search">
              <input
                type="search"
                value={this.state.query}
                name="query"
                onChange={this.handleChange.bind(this)}
                placeholder="Search something ..."
                className="form-control"
              />
            </form>
          </div> */}
          {/* End Search Box*/}
          {/* Begin Topbar */}
          <div className="navbar-holder d-flex align-items-center align-middle justify-content-between">
            {/* Begin Logo */}
            <div className="navbar-header col-lg-2 col-md-3 col-sm-4 col-6">
              <a href="/" className="navbar-brand">
                <div className="brand-image brand-big">
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "70px" }}
                    className="logo-big"
                  />
                </div>
                <div className="brand-image brand-small">
                  <img src={logo} alt="logo" className="logo-small" />
                </div>
              </a>
              {/* Toggle Button */}
              <a id="toggle-btn" href="/" className="menu-btn active">
                <span />
                <span />
                <span />
              </a>
              {/* End Toggle */}
            </div>

            <div className="col-lg-5 col-md-4 col-sm-4">
              <form className="form-inline" id="searchForm" onSubmit={this.runSearch.bind(this)} action="/search" role="search">
                <input className="form-control mr-sm-2 col-lg-10 col-sm-10" type="search" placeholder="Search" aria-label="Search"
                  value={this.state.query}
                  name="query"
                  onChange={this.handleChange.bind(this)} /><li className="nav-item d-flex align-items-center">

                  {/* <a id="search" href="/" onClick={this.runSearch.bind(this)}><i className="la la-search" /></a> */}
                  </li>
              </form>
            </div>
            {/* End Logo */}
            {/* Begin Navbar Menu */}

            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right">
              {/* Search */}
              {/* <li className="nav-item d-flex align-items-center"><a id="search" href="/" onClick={(e) => e.preventDefault()}><i className="la la-search" /></a></li> */}
              {/* End Search */}
              {/* Begin Notifications */}
              <li className="nav-item dropdown">
                <a id="notifications" rel="nofollow" href="/" onClick={(e) => { e.preventDefault(); this.onClickNotificationBell() }}
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link">
                  <i className={classnames("la", "la-bell", "animated", "infinite", { "swing": hasNotification })} /><span className={classnames({ "badge-pulse": hasNotification })} />
                </a>
                <ul aria-labelledby="notifications" className="dropdown-menu notification">
                  <li>
                    <div className="notifications-header">
                      <div className="title">Notifications ({unseenCount})</div>
                      <div className="notifications-overlay" />
                      <img src={notificationImg} alt="..." className="img-fluid" />
                    </div>
                  </li>
                  <NotificationItems />
                  <li>
                    {/* <a rel="nofollow" href="/" onClick={(e) => e.preventDefault()} className="dropdown-item all-notifications text-center">View All Notifications</a> */}
                    <a rel="nofollow" href="/" onClick={(e) => e.preventDefault()} className="dropdown-item all-notifications text-center"><i className="ion ion-chevron-up"></i></a>
                  </li>
                </ul>
              </li>
              {/* End Notifications */}
              {/* User */}
              <li className="nav-item dropdown">
                <a
                  id="user"
                  rel="nofollow"
                  data-target="#"
                  href={"/" + this.props.user.username}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="nav-link"
                  onClick={e => e.preventDefault()}
                >
                  <img
                    src={this.props.user.profilePhoto || defaultPhoto}
                    alt="user avatar"
                    className="avatar rounded-circle"
                    crossOrigin="anonymous"
                    onError={(ev) => {
                      ev.target.src = defaultPhoto;
                      this.props.reloadProfile(this.props.user.username)
                    }}
                  />
                  {/* <ProfilePhoto user={this.props.user} avatar /> */}
                </a>
                <ul
                  aria-labelledby="user"
                  className="user-size dropdown-menu"
                >
                  <li className="welcome">
                    <Link to="/profile/edit" className="edit-profil">
                      <i className="la la-gear" />
                    </Link>
                    <img
                      src={this.props.user.profilePhoto || defaultPhoto}
                      alt="profile"
                      className="rounded-circle"
                      crossOrigin="anonymous"
                    />

                    {/* <ProfilePhoto user={this.props.user} width={50} /> */}
                  </li>
                  <li>
                    <Link to={"/citizen/" + this.props.user.username} className="dropdown-item">
                      <FormattedMessage id="Nav.profile" defaultMessage="profile" />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/citizen/" + this.props.user.username + "/about"} className="dropdown-item">
                      badges
                    </Link>
                  </li>
                  <li>
                    <Link to={"/citizen/" + this.props.user.username + "/collections"} className="dropdown-item">
                      collections
                    </Link>
                  </li>
                  <li className="separator" />
                  <li>
                    <a href="https://www.mazinomedia.com/terms-privacy" data-lity className="dropdown-item no-padding-top">
                      <FormattedMessage id="Nav.policy" defaultMessage="policy" />
                    </a>
                  </li>
                  <li>
                    <Link
                      rel="nofollow"
                      to=""
                      onClick={() => {
                        this.onLogoutRequest();
                        sessionLogout();
                      }}
                      className="dropdown-item logout text-center"
                    >
                      <i className="ti-power-off" />
                    </Link>
                  </li>
                </ul>
              </li>
              {/* End User */}
              {/* Begin Quick Actions */}
              {/* <li className="nav-item"><a href="#off-canvas" className="open-sidebar"><i className="la la-ellipsis-h" /></a></li> */}
              {/* End Quick Actions */}
            </ul>
          </div>
          {/* End Navbar Menu */}
          {/* End Topbar */}
        </nav>
      </header>
      /* End Header */
    );
  }
}

const mapActionsToProps = {
  onLogoutRequest: logout,
  onNotificationSeen,
  reloadProfile: login,
  onDay1EasterEggTriggered: day1EasterEgg
}

const mapStateToProps = (state) => {

  let notificationLimit = 5;

  // get up to the first 5 notifications that are unseen
  let displayedNotifications = state.notifications.unseen.slice(0, notificationLimit);

  // if unseen is less than notification limit, fill the remaining space with seen messages
  if (displayedNotifications.length < notificationLimit) {
    displayedNotifications.push(...state.notifications.seen.slice(0, notificationLimit - displayedNotifications.length));
  }

  return {
    notifications: state.notifications,
    notificationLimit,
    displayedNotifications,
    unseenCount: state.notifications.unseen.length
  }
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(SynthCityNav));
