let ImageCache = function () {
    this.users = {}
}

ImageCache.prototype = {
    get: function (username) {
        let self = this;
        let user = null;

        if (!self.users) {
            self.users = {};
        }

        user = self.users[username];
        return user && user.img;
    },
    cache: function (username, img) {

        let self = this;
        
        if (!self.users) {
            self.users = {};
        }

        self.users[username] = { img };

    }
}

let cache = new ImageCache();

export default {
    get: cache.get,
    cache: cache.cache
}