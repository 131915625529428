import React, { Component } from 'react';

class DelayModal extends Component {

    render() {

        let { header, subHeader, modalId} = { ...this.props }

        return (
            <div id={modalId} className="modal fade" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                                <span className="sa-line sa-tip animateSuccessTip" />
                                <span className="sa-line sa-long animateSuccessLong" />
                                <div className="sa-placeholder" />
                                <div className="sa-fix" />
                            </div>
                            <div className="section-title mt-5 mb-2">
                                <h2 className="text-gradient-01">{header}</h2>
                            </div>
                            <p className="mb-5">{subHeader}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DelayModal;