import React, { Component } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import PatchWidget from './PatchWidget';
import Commento from 'components/common/Commento';
import ProfilePhoto from 'components/common/ProfilePhoto';
import { Link } from "react-router-dom";
import defaultPhoto from "assets/img/albums/collection_default.jpg";
import { getSynthInfo } from 'services/synthwsao';
import { withRouter } from 'react-router-dom';
import TitleController from 'components/common/TitleController';

class PatchPage extends Component {


    _isMounted = false;

    state = { patch: {} };


    componentDidMount() {
        this._isMounted = true;

        const { match: { params } } = this.props;

        params.id && getSynthInfo(params.id).then(patch => {

            if (!patch) {
                // redirect to 404                
                this.props.history.push('/NotFound')
            }

            this._isMounted && this.setState({ patch });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let patch = this.state.patch;
        let { name, uploadedBy, id, description } = { ...this.state.patch };
        return (
            <FadeIn>
                <TitleController title={name + " by " + uploadedBy}/>
                <div className="page">

                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-11">

                                <div className="row flex-row">
                                    <div className="col-xl-3 column">
                                        <div className="widget has-shadow">
                                            {/* Download Button */}
                                            <div className="widget-body">
                                                <div className="d-flex justify-content-center">
                                                    {/* <button className="btn btn-shadow btn-gradient-01 " type="submit" onClick={this.downloadCollection.bind(this)}>download</button> */}
                                                </div>
                                            </div>
                                            {/* End Download Button */}
                                            {/* Display Photo */}
                                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                                <figure className="img-hover-01 has-shadow">
                                                    <Link to="#"> <img src={defaultPhoto} className="img-fluid collection-cover" alt={name + " Cover"} /></Link>
                                                    <div>
                                                        <Link to="#">
                                                            <i className="la la-camera"></i>
                                                        </Link>
                                                    </div>
                                                </figure>
                                            </div>
                                            {/* End Display Photo */}
                                            {/* Collection Info */}
                                            <div className="widget-body">
                                                <div className="d-flex justify-content">
                                                    <h2>{name}</h2>
                                                </div>
                                            </div>

                                            <div className="widget-header d-flex align-items-centered">
                                                <div className="user-image">{uploadedBy && <ProfilePhoto user={{ username: patch.uploadedBy, profilePhoto: 'I Need to Make this better damn lol' }} width={55} clickable />}</div>
                                                <div className="d-flex flex-column mr-auto title username">by {uploadedBy}</div>
                                            </div>

                                            <div className="widget-body">
                                                <div className="d-flex justify-content">
                                                    <p>{description}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-xl-9 column">
                                        <div>
                                            <PatchWidget patch={patch} large showRecipe />
                                            <Commento id={id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
        );
    }
}

export default withRouter(PatchPage);