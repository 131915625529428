import React, { Component } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import ReactTimeAgo from 'react-time-ago';
import { getChallenge, submitReview } from 'services/challengewsao';
import NotificationEngine from 'engines/notification/NotificationEngine';
import ProfilePhoto from 'components/common/ProfilePhoto';
import YouTube from 'react-youtube';
import { language } from 'config/localization';
import Header from 'components/common/Header';
import PatchWidget from 'components/patch/PatchWidget';
import Button from 'components/common/Button';
import Widget from 'components/profile/Widget';
import { getCurrentUser } from 'services/authentication-wsao';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StartEndControls from "components/challenge/StartEndControls";


class ChallengeReviewTab extends Component {

    _isMounted = false;

    state = {
        challenge: {
            song: {
                url: '',
                platform: ''
            },
            song_start: 0,
            song_end: 0
        },
        showChallengePanel: true,
        reviewed: []

    }

    componentDidMount() {
        this._isMounted = true;
        let { match: { params } } = this.props;

        let challengeId = this.props.id || params.id

        this.loadChallenge(challengeId);
    }

    loadChallenge(challengeId) {

        getChallenge(challengeId || 'random').then(challenge => {
            if (this._isMounted) {

                // we get an empty object if there is no challenge
                if (Object.entries(challenge).length === 0 && challenge.constructor === Object) {
                    this.setState({ showChallengePanel: false })
                } else {
                    let filteredPatches = challenge.patches.filter((patch, i) => {

                        let reviewers = patch.reviewers || [];

                        // don't include patches user has already reviewed
                        return !reviewers.includes(getCurrentUser());
                    });

                    challenge.patches = filteredPatches;

                    this.setState({
                        challenge,
                        id: challengeId
                    });
                }

                window.scrollTo(0, 0);
            }
        }).catch(e => {
            NotificationEngine.error('Something went wrong loading the challenge');
        });
    }


    youtube_parser(url) {

        // eslint-disable-next-line
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : '';
    }

    _onReady(e) {
        this.setState({ player: e.target });
    }


    render() {

        let { song_end, song_start, user, song, requestedBy, title, date_created, notes, patches = [], _id } = { ...this.state.challenge };
        const isAdmin = this.props.permissions.includes('admin');
        const videoId = this.youtube_parser(song.url);
        const playerOptions = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                origin: "synthcity.io",
                start: song_start,
                end: song_end,
                loop: true
            }
        };


        let PatchReviewPanel = (props) => {

            let { patch, onRight, onWrong } = { ...props }

            let component = null;

            if (patch) component = <div>
                <PatchWidget large patch={patch} showRecipe />

                {isAdmin && <div className="text-right">
                    <Button text="Its Correct" type="info" onClick={onRight} /> <Button text="Its Not Correct" onClick={onWrong} />
                </div>}
            </div>;

            return component;
        }

        let AllPatches = (props) => {
            let ReviewPanels = [];
            let { patches = [] } = { ...props };
            patches.forEach((patch, i) => {
                ReviewPanels.push(<h3>Recipe by {patch.uploadedBy}</h3>)
                ReviewPanels.push(<PatchReviewPanel patch={patch} key={i} onRight={() => {
                    submitReview(_id, patch.s3Key, true).then(() => {
                        let newPatches = this.state.challenge.patches.filter((val) => {
                            return val.s3Key !== patch.s3Key;
                        });

                        let newChallenge = this.state.challenge;
                        newChallenge.patches = newPatches;

                        this.setState({ challenge: newChallenge });

                        NotificationEngine.success("Review submitted")
                    }).catch(() => NotificationEngine.error("Something went wrong"));
                }} onWrong={() => {
                    submitReview(_id, patch.s3Key, false).then(() => {
                        let newPatches = this.state.challenge.patches.filter((val) => {
                            return val.s3Key !== patch.s3Key;
                        });

                        let newChallenge = this.state.challenge;
                        newChallenge.patches = newPatches;

                        this.setState({ challenge: newChallenge });

                        NotificationEngine.success("Review submitted")
                    }).catch(() => NotificationEngine.error("Something went wrong"));
                }} />);
            });
            return ReviewPanels;
        }

        return (
            <FadeIn>
                <Header text="Review Challenges" />
                {!this.state.showChallengePanel ? <Widget body="None left holmes! Try coming back later for more" /> : <div>
                    <div className="widget has-shadow">
                        <div className="form-group row d-flex align-items-center mb-5">

                            <div className="widget-header d-flex align-items-center">
                                <div className="user-image">{user && <ProfilePhoto user={user} width={55} clickable />}</div>

                                <div className="d-flex flex-column mr-auto">
                                    <div className="title">
                                        <span className="username">{title}</span>
                                    </div>
                                    <div>{requestedBy}</div>
                                    <div className="time">{date_created && <ReactTimeAgo date={new Date(date_created)} locale={language} />}</div>
                                </div>

                            </div>
                            <div className="embed-responsive embed-responsive-16by9 ">
                                <YouTube
                                    videoId={videoId}
                                    opts={playerOptions}
                                    onReady={this._onReady.bind(this)}
                                    onStateChange={(e) => {

                                        // if reached end, immediately loop to start
                                        if (e.data === 0) {
                                            this.state.player.seekTo(song_start);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <StartEndControls start={song_start} end={song_end} player={this.state.player}/>

                        {/* Begin Notes */}
                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            <h4>Challenge Notes</h4>
                        </div>
                        <div className="widget widget-body">{notes}</div>
                        {/* End Notes */}

                    </div>
                </div>}

                {/* <PatchReviewPanel patch={patch} onRight={() => {
                    submitReview(_id, patch.s3Key, true).then(() => NotificationEngine.success("Review submitted")).catch(() => NotificationEngine.error("Something went wrong"));
                }} onWrong={() => {
                    submitReview(_id, patch.s3Key, false).then(() => NotificationEngine.success("Review submitted")).catch(() => NotificationEngine.error("Something went wrong"));
                }} /> */}

                <AllPatches patches={patches} />
            </FadeIn>
        );
    }
}

const mapStateToProps = (state, props) => ({
    permissions: state.user.permissions,
});

export default connect(mapStateToProps, null)(withRouter(ChallengeReviewTab));
