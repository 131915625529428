import { CognitoAuth } from 'amazon-cognito-auth-js';
import { logout as cleanup } from 'actions/user-actions';
import { logUserIn } from 'actions/authentication-actions';

// redux, state store etc
import { store } from 'config/configureStore';

import axios from 'axios';


const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

var authData = {
    ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID, // Your client id here'
    AppWebDomain: process.env.REACT_APP_AWS_AppWebDomain,
    TokenScopesArray: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    RedirectUriSignIn: process.env.REACT_APP_AWS_RedirectUriSignIn,
    RedirectUriSignOut: process.env.REACT_APP_AWS_RedirectUriSignOut,
    IdentityProvider: process.env.REACT_APP_AWS_IdentityProvider, // e.g. 'Facebook',
    UserPoolId: process.env.REACT_APP_AWS_UserPoolId, // Your user pool id here
    AdvancedSecurityDataCollectionFlag: false, // e.g. true
    // Storage: '<TODO the storage object>' // OPTIONAL e.g. new CookieStorage(), to use the specified storage provided
};

var auth = new CognitoAuth(authData);

export function initUserHandler(onSuccess, onFailure, onSuccessExchangeForToken) {
    auth.userhandler = {
        onSuccess: function (result) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // console.log("Sign in success whoop whoop");
                // console.log(auth.getCachedSession())
            }

            // Set app state as logged in and trigger loading user profile
            if (!store.getState().loggedIn) {
                store.dispatch(logUserIn());
                onSuccess(result.accessToken.payload.username)
            }

        },
        onFailure: function (err) {
            console.log("Error!");
            console.log(err);
            cleanup();
            logout();

        },
        onSuccessExchangeForToken: () => {
            console.log('got code token thing')
        }
    };
}

auth.useCodeGrantFlow();

export function getUserSession() {
    if (auth.isUserSignedIn()) {
        auth.getSession();
    }
}

export function getCachedSession() {
    return auth.getCachedSession();
}

export function retrieveAuthTokens(preventRefresh) {
    // Tbh this still has a little bug where the token would be invalid for the first time 
    // the user tries to use it after its expired but... MEH! lol close enough
    // I guess I could set a timeout of some sort to check for the token when it expires or something
    getCurrentUser();

    // return tokens
    var result = auth.getCachedSession();

    return {
        accessToken: result.accessToken.jwtToken,
        idToken: result.idToken.jwtToken,
        username: result.idToken.payload["cognito:username"],

        // these are really only used in debug
        accessTokenFull: result.accessToken,
        idTokenFull: result.idToken
    }
}

export function parseURLForTokenChallenge() {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // console.log(auth.getCurrentUser() || 'NO ONE', 'is authenticated!');
        console.log(auth.getCachedSession());
    }

    let session = auth.getCachedSession();
    let tokenExpired = () => {
        let expiryDate = new Date(session.accessToken.payload.exp * 1000);
        // console.log("current date", new Date(), "expires", expiryDate)
        return new Date() > expiryDate;
    }

    // there is a valid session, refresh
    if (session.accessToken.jwtToken && tokenExpired()) {
        // alert('refreshing token ' + tokenExpired())
        retrieveAuthTokens();
    }

    if (!auth.getCurrentUser()) {
        var curUrl = window.location.href;
        auth.parseCognitoWebResponse(curUrl);
    }
}

export function getCurrentUser() {
    auth.getSession();
    return auth.getCurrentUser();
}

export function refreshSession() {
    auth.refreshSession(auth.getCachedSession().getRefreshToken().getToken())
}

export function userSignedIn() {
    return auth.isUserSignedIn();
}

export function logout() {
    auth.signOut();
}

/**
* Verify's SSO hash with API
*/
export async function verifySSO(payload, hmac) {

    let url = `${uri}sso?hmac=${hmac}`;

    try {
        // call notification access endpoint
        let ssoResponse = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            },

        });

        return ssoResponse.data;
    } catch (e) {
        console.log(e);
        throw e
    }


}

export async function authenticateSSO(payload, hmac) {
    let url = `https://commento.io/api/oauth/sso/callback?hmac=${hmac}`;

    try {
        // call notification access endpoint
        let ssoResponse = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json"
            },
            data: payload

        });

        return ssoResponse.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}