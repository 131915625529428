import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';


const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;
/**
 * Gets Collection from Database
 */
export async function getCollection(id) {


    try {
        let url = `${uri}collection/${id}`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function createCollection(collection) {


    try {
        let url = `${uri}collection`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: collection
        });

        console.log(res.data)

        // upload cover photo to s3 if exists
        if (!(Object.keys(collection.file).length === 0 && collection.file.constructor === Object)) {
            await uploadCollectionImg(res.data.id, collection.file);
        }

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function editCollection(id, collection) {


    try {
        let url = `${uri}collection/${id}`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call collection edit access endpoint
        let res = await axios({
            url: url,
            method: 'PATCH',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: collection
        });

        // upload cover photo to s3 if exists
        if (!(Object.keys(collection.file).length === 0 && collection.file.constructor === Object)) {
            await uploadCollectionImg(id, collection.file);
        }

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function uploadCollectionImg(id, file) {
    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        // Get signed URL for Uploading from API
        let resourceResponse = await axios({
            url: `${uri}collection/${id}/photo/upload_token`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        let uploadURL = resourceResponse.data.url;

        // upload cover photo to S3
        await axios({
            url: uploadURL,
            method: 'PUT',
            data: file,
            headers: {
                "Content-Type": file.type
            }
        });

        let collectionInfo = { img: id };

        // update collection with the preview metadata in db
        await axios({
            url: `${uri}collection/${id}`,
            method: 'PATCH',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: collectionInfo
        });

        return;
    } catch (e) {

        console.log(e);
        throw e;
    }

}

export async function deleteCollection(id, collection) {


    try {

        let url = `${uri}collection/${id}`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'DELETE',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}

export async function getUserCollection(username, next) {

    try {

        let url = `${uri}user/${username}/collections?limit=${4}`;

        if (next) {
            url += "&next=" + next;
        }
        let { accessToken, idToken } = retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            }
        });

        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }
}