import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import YouTube from 'react-youtube';
import TimeFormat from 'hh-mm-ss';
import NotificationEngine from 'engines/notification/NotificationEngine';
import { createChallenge } from 'services/challengewsao';
import Modal from 'components/common/Modal';
import { getSynthWhitelist } from 'services/synthwsao';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from 'actions/user-actions';
import queryString from 'query-string';
import PatchInfoWidget from 'components/synthShare/PatchInfoWidget';
import { associateChallengeWithPatch } from 'services/challengewsao';
import $ from 'jquery';

class SynthRequest extends Component {

    init = {
        platform: '',
        url: '',
        start: 0,
        end: 0,
        title: '',
        player: {},
        notes: '',
        type: '',
        synthesizer: '',
        synthesizers: [],
        challengeType: '',
        isSelfChallenge: false,
        challengeId: ''
    }

    patchSubmitWidget = React.createRef();

    constructor(props) {
        super(props);

        this.state = this.init;
        this.handleChange = this.handleChange.bind(this);
        this.youtube_parser = this.youtube_parser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validatePatchSubmission = () => {
        return !this.patchSubmitWidget.current.validate();
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    resetForm() {
        this.setState(this.init);
    }

    handleSubmit(e, onComplete) {
        let { end, start, url, platform, notes, type, synthesizer, isSelfChallenge } = { ...this.state };

        // Validations
        if (Math.floor(end) === 0) {

            NotificationEngine.error("Please select an end time");

        } else if (!type) {
            NotificationEngine.error("Please select the sound type");
        }

        else {

            // Make request
            let payload = { song_end: end, song_start: start, song: { url, platform }, notes, type, synthesizer, isSelfChallenge };
            payload.title = this.state.player.getVideoData().title;

            createChallenge(payload).then(id => {

                !isSelfChallenge && NotificationEngine.success("Your challenge is up!");

                // refreshes user stats by loading the user from teh API 
                this.props.refreshUserStats(this.props.user.username);

                // redirect to collection page
                !isSelfChallenge && this.props.history.push('/challenge/solve/' + id);

                onComplete && onComplete(id);

            }).catch((err) => {

                if (err.response && err.response.status === 403) {
                    $('#challenge-modal').modal('show');

                    // NotificationEngine.warn({ text: "You don't have enough tokens to challenge the community. Earn more by solving challenges", layout: 'top', timeout: false });
                } else {
                    NotificationEngine.error('Something went wrong sending your request');
                }
                return false;
            });
        }
    }

    youtube_parser(url) {

        // eslint-disable-next-line
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    _onReady(e) {
        this.setState({ player: e.target });
    }

    componentDidMount() {
        // get synth whitelist from API
        getSynthWhitelist().then(list => {

            let synthesizers = [];

            list.forEach((synth) => {
                synthesizers.push(synth.name[0]);
            });

            this.setState({
                synthesizers
            });

        });

        let challengeType = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).t;

        let isSelfChallenge = challengeType === 'self' ? true : false;
        this.setState({ challengeType, isSelfChallenge })
    }

    render() {

        // synth Request/Challenge Variables
        const { url, start, end, challengeType, challengeId } = { ...this.state };

        // patch Upload style variables (this is shit code)
        let showUploadPanel = false;
        let showSynthesizerOption = true;
        let showChallengeWidgetUpload = true;
        let isSelfChallenge = false;

        switch (challengeType) {
            case 'self':
                showUploadPanel = true;
                showSynthesizerOption = false;
                showChallengeWidgetUpload = false;
                isSelfChallenge = true;
                break;
            default:
                showUploadPanel = false;
                showSynthesizerOption = true;
                showChallengeWidgetUpload = true;
        }

        const videoId = this.youtube_parser(url);

        // challenger stats variables
        const { tokens = 0, totalSolved = 0 } = { ...this.props.user.challengeStats }
        const untilNext = ((totalSolved % 3) - 3) * -1;

        const playerOptions = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                origin: "synthcity.io",
                start,
                end,
                loop: true
            }
        };

        const SynthesizerOptions = () => {
            let synths = [];

            this.state.synthesizers.forEach((synth, i) => {
                synths.push(<option value={synth} key={i}>{synth}</option>);
            });

            return synths;
        }

        return (
            <FadeIn>
                <section id="request-page" >

                    <Modal
                        modalId="challenge-modal"
                        title="Not Enough Tokens"
                        body={
                            <p>
                                Earn Tokens by helping others in the community <br /><br />

                                {/* You can also get Tokens by Buying them */}

                                {/* Just <strong>{untilNext}</strong> more challenges to go!<br /> */}
                            </p>
                        }
                        medium
                        blue
                        // yesText="Buy Tokens?"
                        //onAccept={() => this.props.history.push('/buy/challenge_tokens')}
                        noText="Not Yet"
                        yesText={"Help " + untilNext + (untilNext === 1 ? " Person?" : " People?")}
                        onAccept={() => this.props.history.push('/challenge/solve')}
                        // noText={"Help " + untilNext + (untilNext === 1 ? " Person?" : " People?")}
                        // onReject={() => this.props.history.push('/challenge/solve')}
                    />
                    <div className="container" >
                        <div className="page-header">
                            <div className="d-flex align-items-center">
                                {isSelfChallenge ? <h2 className="page-header-title">Challenge Yourself</h2> : <h2 className="page-header-title">Create Challenge</h2>}
                            </div>
                        </div>

                        <div className="widget has-shadow">
                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                <h4>Song Details</h4>
                            </div>
                            <div className="widget-body">
                                <div className="needs-validation" noValidate>
                                    <div className="form-group row d-flex align-items-center mb-5">

                                        {process.env.NODE_ENV === "development" && <p><button onClick={(e) => {
                                            e.preventDefault(); this.setState({
                                                url: 'https://www.youtube.com/watch?v=rpGIXmaQ2Ak',
                                                platform: 'youtube',
                                                end: 10,
                                                type: 'pad'

                                            })
                                        }}>test</button>
                                            <button onClick={this.validatePatchSubmission}>validate</button> </p>}

                                        <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">YouTube URL *</label>
                                        <div className="col-lg-5">
                                            <input type="text" value={this.state.url} onChange={this.handleChange}
                                                className="form-control" name="url" placeholder="Song URL" required />
                                        </div>
                                    </div>

                                    {videoId && <div id="selector">

                                        {/* Begin YouTube Player */}
                                        <div className="form-group row d-flex align-items-center mb-5">
                                            {/* <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Youtube Player *</label> */}
                                            <div className="embed-responsive embed-responsive-16by9 ">
                                                <YouTube
                                                    videoId={videoId}
                                                    opts={playerOptions}
                                                    onReady={this._onReady.bind(this)}
                                                    onStateChange={(e) => {

                                                        // if reached end, immediately loop to start
                                                        if (e.data === 0) {
                                                            this.state.player.seekTo(this.state.start);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* End YouTube Player */}

                                        {/* Start and End Controls */}
                                        <div className="form-group row d-flex align-items-center mb-5">
                                            <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">
                                                <button className="btn btn-primary" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ start: this.state.player.getCurrentTime() });
                                                }}>Set Start </button>
                                            </label>
                                            <div className="col-lg-2">
                                                <input type="text" value={TimeFormat.fromS(Math.floor(start), 'mm:ss')} onChange={this.handleChange}
                                                    className="form-control" name="start" placeholder="start" required readOnly />
                                            </div>
                                            <label className="col-lg-2  form-control-label d-flex justify-content-lg-end">
                                                <button className="btn btn-primary" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ end: this.state.player.getCurrentTime() });
                                                }}>Set End </button>
                                            </label>
                                            <div className="col-lg-2">
                                                <input type="text" value={TimeFormat.fromS(Math.floor(this.state.end), 'mm:ss')} onChange={this.handleChange}
                                                    className="form-control" name="end" placeholder="end" required readOnly />
                                            </div>
                                        </div>
                                        {/* End Start and End Controls */}

                                        {/* Synthesizer */}
                                        <div className="form-group row d-flex align-items-center mb-5">
                                            <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Type*</label>
                                            <div className="col-lg-5">
                                                <select onChange={this.handleChange} value={this.state.type} name="type" className="custom-select form-control" required>
                                                    <option value="">Select Type</option>
                                                    <option value="pad">pad</option>
                                                    <option value="bass">bass</option>
                                                    <option value="pluck">pluck</option>
                                                    <option value="drums">drums</option>
                                                    <option value="lead">lead</option>
                                                    <option value="other">other</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* End Synthesizer */}

                                        {/* Synthesizer */}
                                        {showSynthesizerOption && <div className="form-group row d-flex align-items-center mb-5">
                                            <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Synthesizer</label>
                                            <div className="col-lg-5">
                                                <select onChange={this.handleChange} value={this.state.synthesizer} name="synthesizer" className="custom-select form-control" required>
                                                    <option value="">Any</option>
                                                    <SynthesizerOptions />
                                                </select>
                                            </div>
                                        </div>}
                                        {/* End Synthesizer */}

                                        {/* Notes */}
                                        <div className="form-group row d-flex align-items-center mb-5">
                                            <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Notes</label>
                                            <div className="col-lg-5">
                                                <textarea type="text" value={this.state.description} onChange={this.handleChange} rows={4}
                                                    className="form-control" name="notes" placeholder="Describe what you need to recreate this" />
                                            </div>
                                        </div>
                                        {/* End Notes */}


                                        {/* Submission Buttons */}
                                        {showChallengeWidgetUpload && <div>
                                            <div className="em-separator separator-dashed" />
                                            <div className="text-right widget">
                                                <button className="btn btn-gradient-01" onClick={this.handleSubmit} disabled={this.state.isUploading}>Challenge!</button>
                                                <button className="btn btn-shadow" type="reset" onClick={this.resetForm.bind(this)}>Reset</button>
                                            </div>
                                        </div>}
                                        {tokens > 0 && <div className="text-right">
                                            Tokens Remaining: {tokens}
                                        </div>}
                                        {/* End Submission Buttons */}
                                    </div>}
                                </div>
                            </div>

                            {(showUploadPanel && videoId) && <PatchInfoWidget ref={this.patchSubmitWidget} isChallenge onSubmit={(e) => {

                                e.preventDefault();

                                let isValidPatch = this.validatePatchSubmission();

                                if (isValidPatch) {

                                    // create challenge
                                    this.handleSubmit(e, (challengeId) => {

                                        this.setState({ challengeId });
                                        this.patchSubmitWidget.current.handleSubmit(e, challengeId);
                                    });
                                }
                            }}
                                onComplete={(patchId) => {

                                    NotificationEngine.success({ text: "Nice work! Your solution will be reviewed shortly", timeout: 5000 })
                                    associateChallengeWithPatch(challengeId, patchId).then(() => {

                                        // redirect to collection page
                                        this.props.history.push('/challenge/solve/' + challengeId);
                                    })
                                        .catch(() => NotificationEngine.error("Something went wrong submitting your recipe for review"));

                                }} />}
                        </div>

                    </div> {/* END header*/}
                </section>
            </FadeIn>
        );
    }
}

const mapStateToProps = (state, props) => ({
    user: state.user,
});

const mapActionsToProps = {
    refreshUserStats: login
}
export default connect(mapStateToProps, mapActionsToProps)(withRouter(SynthRequest));