import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';

const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

export async function orderItem(item_id) {

    try {
        let url = `${uri}order`;
        let { accessToken, idToken } = await retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: { item_id }
        });

        return res.data.session_id;
    } catch (e) {
        console.log(e);
        throw e
    }
}