export const UPDATE_LOGIN_STATUS = 'authentication:updateLoginStatus';
export const USER_LOGOUT = 'authentication:logoutUser';
export const USER_LOGIN = 'authentication:loginUser';
export const AUTHENTICATION_ERROR = 'authentication:onError';

function updateLoginStatus(loggedIn, type = 'UPDATE_LOGIN_STATUS') {
    return {
        type: type,
        payload: {
            loggedIn: loggedIn
        }
    }
}

export function logUserOut() {
    return updateLoginStatus(false, USER_LOGOUT);
}

export function logUserIn() {
    return updateLoginStatus(true, USER_LOGIN)
}