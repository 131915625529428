import React, { Component } from 'react';
import SuperArray from 'super-array';
import NotificationEngine from "engines/notification/NotificationEngine";
import ReactGA from 'react-ga';
import { uploadSynth, getSynthWhitelist } from 'services/synthwsao';
import { FormattedMessage } from 'react-intl';
import FadeIn from 'react-fade-in';

class PatchInfoWidget extends Component {
    // initialize form
    constructor(props) {
        super(props);

        let acceptedPreviewFormats = ".mp3";

        this.state = {
            name: '',
            synthesizer: '',
            type: '',
            submitted: false,
            file: {},
            previewFile: {},
            isUploading: false,
            description: '',
            synthesizers: [],
            acceptedFormats: '',
            cancelled: false,
            recipe: '',
            acceptedPreviewFormats
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handlePreviewFile = this.handlePreviewFile.bind(this);
    }

    componentDidMount() {

        // get synth whitelist from API
        getSynthWhitelist().then(list => {

            let acceptedFormats = "";
            list.forEach(synth => {
                acceptedFormats += "." + synth.format + ",";
            });

            let synthesizers = new SuperArray(list, function (item) { return item.format });

            this.setState({
                synthesizers,
                acceptedFormats
            });

            // Populate the file data from parent
            let file = this.props.file;
            if (file) {

                let filetype = file.name.split('.').pop();
                let synthName = synthesizers.get(filetype);

                if (synthName) {
                    synthName = synthName.name[0];
                }

                this.setState({
                    file: file,
                    name: file.name.split('.')[0],
                    type: '',
                    submitted: false,
                    filetype,
                    synthesizer: synthName || ''
                });

            }

        });
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    resetForm() {
        this.setState({
            name: '',
            synthesizer: '',
            type: '',
            file: {},
            previewFile: {},
            description: '',
            recipe: '',
            submitted: false,
            cancelled: false
        });
    }

    handleFile(event) {
        if (event.target.files[0]) {

            let filetype = event.target.files[0].name.split('.').pop();
            let synthname = this.state.synthesizers.get(filetype);

            if (synthname) {
                synthname = synthname.name[0];
            }

            this.setState({
                file: event.target.files[0],
                name: event.target.files[0].name.split('.')[0],
                type: '',
                submitted: false,
                filetype,
                synthesizer: synthname || ''
            });

        }
    }

    handlePreviewFile(e) {
        if (e.target.files[0]) {
            this.setState({
                previewFile: e.target.files[0]
            });
        }
    }

    validate() {
        var invalid = false;
        if (Object.keys(this.state.file).length === 0 && this.state.file.constructor === Object) { // Validate file is added
            NotificationEngine.error(<FormattedMessage id="SynthShare.nofile" defaultMessage="You didn't add a file!" />)
            invalid = true;

        } else if (!this.state.synthesizers.some(synth => synth.format.toUpperCase() === this.state.filetype.toUpperCase())) { // validate synthesizers are there
            NotificationEngine.error("Patch " + this.props.id + " (" + this.state.name + ") is not supported");
            invalid = true;
        } else {
            if (this.state.submitted) {
                NotificationEngine.warn(<FormattedMessage id="SynthShare.another" defaultMessage='Please select another file to upload' />);
                return;
            }
            if (this.state.type === "") {
                NotificationEngine.error(<FormattedMessage id="SynthShare.select" defaultMessage='Select a patch type' />)
                invalid = true;
            }

            if (Object.keys(this.state.file).length === 0 && this.state.file.constructor === Object) {
                NotificationEngine.error(<FormattedMessage id="SynthShare.nofile" defaultMessage="You didn't add a file!" />)
                invalid = true;

            }
            if (this.state.name === "") {
                NotificationEngine.error(<FormattedMessage id="SynthShare.noname" defaultMessage="Please input a name for the patch" />);
                invalid = true;
            }
            if (this.props.isChallenge && this.state.recipe === "") {
                NotificationEngine.error("You forgot to add a recipe!");
                invalid = true;
            }
            if (Object.keys(this.state.previewFile).length === 0 && this.state.previewFile.constructor === Object) {
                NotificationEngine.error("You didn't add a preview file");
                invalid = true;
            }
            else if (!(this.state.acceptedPreviewFormats.toUpperCase() === "." + this.state.previewFile.name.split(".")[1].toUpperCase())) {
                NotificationEngine.error("You can only upload previews in mp3 format");
                invalid = true;
            }
        }

        return invalid;
    }

    // call ws when submitted
    handleSubmit(event, challengeId) {
        event.preventDefault();

        let invalid = this.validate();

        if (invalid) return;

        this.setState({ isUploading: true });

        NotificationEngine.info(<FormattedMessage id="SynthShare.uploading" defaultMessage='Immortalizing your creation... *Beep Boop*' />);

        let metadata = {
            name: this.state.name,
            type: this.state.type,
            synthesizer: this.state.synthesizer,
            filetype: this.state.filetype,
            description: this.state.description,
            challengeId: challengeId || this.props.challengeId,
            recipe: this.state.recipe
        };

        uploadSynth(metadata, this.state.file, this.state.previewFile)
            .then((patchId) => {
                this.setState({
                    submitted: true,
                    isUploading: false
                });

                // don't show any notification if its a challenge page
                !this.props.isChallenge && NotificationEngine.success({ clearOthers: false, text: "All done!", sound: NotificationEngine.SoundEffect.upload });
                ReactGA.event({
                    category: 'engagement',
                    action: 'uploadPatch',
                    label: this.state.name,
                    value: 1
                });

                // call lifecycle function
                this.props.onComplete && this.props.onComplete(patchId);

                // reset the form if this is on challenge page
                this.props.isChallenge && this.resetForm();
            })
            .catch(() => {
                NotificationEngine.error({ text: "Yikes! Could not upload the file!", clearOthers: true });
                this.setState({ isUploading: false });
            });
    }

    render() {
        let { isChallenge } = { ...this.props };
        return (
            <div className="widget has-shadow">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>{`Patch ${this.props.id || ""}${this.state.name ? " :" : ""} ${this.state.name}`} </h4>

                    {/* <div className="text-right">
                        <button className="btn btn-shadow" type="reset" onClick={this.cancel}>Cancel</button>
                    </div> */}
                </div>
                {!this.state.submitted &&
                    <FadeIn>
                        <div className="widget-body">
                            <div className="needs-validation" noValidate>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">File *</label>
                                    <div className="col-lg-5">
                                        <input type="file" onChange={this.handleFile} name="file" accept={this.state.acceptedFormats} required />
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Name *</label>
                                    <div className="col-lg-5">
                                        <input type="text" value={this.state.name} onChange={this.handleChange} className="form-control" name="name" placeholder="Patch Name" required />
                                    </div>
                                </div>

                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Type *</label>
                                    <div className="col-lg-5">
                                        <div className="select">
                                            <select onChange={this.handleChange} value={this.state.type} name="type" className="custom-select form-control" required>
                                                <option value="">Please Select</option>
                                                <option value="pad">pad</option>
                                                <option value="bass">bass</option>
                                                <option value="pluck">pluck</option>
                                                <option value="drums">drums</option>
                                                <option value="lead">lead</option>
                                                <option value="other">other</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please select an option
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Synthesizer *</label>
                                    <div className="col-lg-5">
                                        <input type="text" value={this.state.synthesizer} onChange={this.handleChange} className="form-control" name="synthesizer" placeholder="Auto-filled when you select a file" required readOnly />
                                    </div>
                                </div>

                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Preview *</label>
                                    <div className="col-lg-5">
                                        <input type="file" onChange={this.handlePreviewFile} name="previewFile" accept={this.state.acceptedPreviewFormats} required />
                                    </div>
                                </div>

                                {isChallenge && <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Recipe*</label>
                                    <div className="col-lg-5">
                                        <textarea rows="7" className="form-control" placeholder="Explain the process of creating this patch. More is better..." required value={this.state.recipe} name="recipe" onChange={this.handleChange} />
                                        <div className="invalid-feedback">
                                            Please enter a Recipe
                                        </div>
                                    </div>
                                </div>}

                                <div className="form-group row d-flex align-items-center mb-5">
                                    <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Description</label>
                                    <div className="col-lg-5">
                                        <textarea className="form-control" placeholder="Describe your patch here..." required value={this.state.description} name="description" onChange={this.handleChange} />
                                        <div className="invalid-feedback">
                                            Please enter a description
                                            </div>
                                    </div>
                                </div>
                                <div className="em-separator separator-dashed" />
                                <div className="text-right">
                                    <button className="btn btn-gradient-01" onClick={this.props.onSubmit || this.handleSubmit} disabled={this.state.isUploading}><FormattedMessage id="SynthShare.upload" defaultMessage="Upload Patch" /></button>
                                    <button className="btn btn-shadow" type="reset" onClick={this.resetForm}>Reset</button>
                                </div>
                                <div className="em-separator separator-dashed" />
                                <div className="text-right small">
                                    By uploading you confirm that your presets conform with our <a target="_blank" rel="noopener noreferrer" data-lity href="https://www.mazinomedia.com/terms-privacy#sharing-on-synthCity">Terms of Use</a> and you don't infringe on anyone else's rights
                                </div>
                            </div>
                        </div>
                    </FadeIn>}

                {this.state.submitted && <FadeIn><div className='alert alert-success'>Upload Complete</div></FadeIn>}
                {this.state.cancelled && <FadeIn><div className='alert alert-danger'>Cancelled</div></FadeIn>}
            </div>
        );
    }
}

export default PatchInfoWidget;
