import React, { Component } from 'react';
import Header from 'components/common/Header';
import one from 'assets/img/badges/uploads_1.png';
import five from 'assets/img/badges/uploads_5.png';
import fifteen from 'assets/img/badges/uploads_15.png';
import fourty from 'assets/img/badges/uploads_40.png';
import { orderItem } from 'services/orderwsao';
import { injectStripe } from 'react-stripe-elements';
import FadeIn from 'react-fade-in'
import NotificationEngine from 'engines/notification/NotificationEngine';
import DelayModal from 'components/common/DelayModal';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';

class ChallengeTokenOrderPage extends Component {


    componentDidMount() {
        if (window.location.href.indexOf('complete') > 0) {

            $('#delay-modal').modal('show');
            $('#delay-modal').on('hidden.bs.modal', () => {
                this.props.history.push('/challenge/overview')
            });
        }
    }

    render() {

        let products = [
            {
                img: one,
                header: "SYNTH ",
                sub_header: "1 Token",
                price: 0.79,
                description: "1 Token",
                item_id: "sc_challenge_tokens_1"
            },
            {
                img: five,
                header: "SYNTHCITIZEN",
                sub_header: "5 Tokens",
                price: 3.69,
                description: "5 Tokens",
                item_id: "sc_challenge_tokens_5"
            },
            {
                img: fifteen,
                header: "SYNTHLORD",
                sub_header: "15 Tokens",
                price: 8.99,
                description: "15 Tokens",
                item_id: "sc_challenge_tokens_15"
            },
            {
                img: fourty,
                header: "SYNTHGOD",
                sub_header: "40 Tokens",
                price: 18.49,
                description: "40 Tokens",
                item_id: "sc_challenge_tokens_40"
            }
        ]
        let TokenTierWidget = (props) => {
            let widgets = [];
            let items = props.items;

            items.forEach((details, i) => {

                let { img, header, sub_header, price, description, purchase_text, item_id } = { ...details };

                let button_action = () => {

                    orderItem(item_id).then(session_id => {
                        let stripe = this.props.stripe;

                        stripe.redirectToCheckout({
                            // Make the id field from the Checkout Session creation API response
                            // available to this file, so you can provide it as parameter here
                            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                            sessionId: session_id
                        }).then((a, b) => {
                            console.log(a)
                            console.log(b)
                        });
                    }).catch((e) => {
                        NotificationEngine.error("Something went wrong placing your order. Please try again later");
                    });

                }

                let item = <div className="col-lg-3 no-padding" key={i}>
                    <div className="pricing-tables-01 pricing-wrapper last">
                        <div className="inner-container">
                            <div className="pricing-image">
                                <img src={img} alt={header} />
                            </div>
                            <div className="title">{header}</div>
                            <div className="container-text">{sub_header}</div>
                            <div className="main-number">{price}</div>
                            <div className="pricing-list">
                                <p>
                                    {description}
                                </p>
                            </div>
                        </div>
                        <button className="btn btn-lg btn-primary" onClick={button_action}>{purchase_text || "Purchase"}</button>
                    </div>
                </div>

                widgets.push(item);
            });

            return widgets;
        }

        return (
            <FadeIn>
                <div className="container-fluid" >

                    <DelayModal header="Thank you!" subHeader="Order completed successfully" confirmation modalId="delay-modal" onComplete={() => {

                    }} />

                    <Header text="Buy Tokens" />
                    <div className="widget has-shadow">
                        {/* Begin Widget Header */}
                        <div className="widget-header bordered no-actions d-flex align-items-center">
                            <h2>Pick a Bundle</h2>
                        </div>
                        {/* End Widget Header */}
                        {/* Begin Widget Body */}
                        <div className="widget-body">
                            <div className="row">
                                <TokenTierWidget items={products} />
                            </div>
                        </div>
                        {/* End Widget Body */}
                    </div>

                </div>
            </FadeIn>
        );
    }
}

export default injectStripe(withRouter(ChallengeTokenOrderPage));