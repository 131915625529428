import React, { Component } from 'react';
import Footer from './Footer';

class NotFound extends Component {
    render() {
        return (
            <div>
                {/* Begin Container */}

                <div className="container-fluid no-padding h-100">
                    <div className="row justify-content-center align-items-center h-100">
                        {/* Begin Left Content */}
                        <div className="col-xl-4 col-lg-4 no-padding d-flex justify-content-center">
                            {/* Begin Error */}
                            <div className="error-02 mx-auto mb-3 text-center">
                                <h1 className="text-gradient-03">404</h1>
                                <h2>This Page Doesn't Exist! </h2>
                                <p>Not sure how you got here but... Here's an otter for your troubles <span alt="upside down head emoji" role="img" aria-label="emoji">🙃</span></p>
                                <a href="/" className="btn btn-gradient-01">Go Home</a>
                            </div>
                            {/* End Error */}
                        </div>
                        {/* End Left Content */}
                        {/* Begin Right Content */}
                        <div className="col-xl-8 col-lg-8 d-none d-sm-block no-padding">
                            <div className="elisyam-bg bg-error-02">
                            </div>
                        </div>
                        {/* End Right Content */}
                    </div>
                    {/* End Row */}
                </div>
                <Footer />
            </div>
        );
    }
}

export default NotFound;