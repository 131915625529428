import React, { Component } from 'react';
import UserWidget from './UserWidget';

class FriendsTab extends Component {

    state = {
        users: [{
            username: 'AnnaPandarina',
            profilePhoto: 'AnnaPandarina.jpg',
            displayName: "Anna O'hara",
            generalInfo: {
                aboutMe: "Oh wow! Friends 'n' stuffs!"
            }
        },
        { username: 'Zeeno', profilePhoto: true },
        { username: 'TestUserOne', profilePhoto: true },
        { username: 'TestUserTwo', profilePhoto: true },
        { username: 'wesakicus', profilePhoto: true }]
    }

    render() {

        let friends = this.props.users || this.state.users;

        let FriendWidgets = () => {
            let widgets = [];

            for (let i = 0; i < friends.length; i++) {
                widgets.push(<UserWidget position={i} key={i} username={friends[i].username} />)
            }

            return widgets;
        }
        return (
            <div className="row flex-row justify-content-md-center">
                <FriendWidgets />
            </div>
        );
    }
}

export default FriendsTab;