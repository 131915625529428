import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';
const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

export async function addBadgeToUser(username, badge) {

    try {
        let url = `${uri}user/${username}/badges`;
        let { accessToken, idToken } = retrieveAuthTokens();

        // call notification access endpoint
        let res = await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                "Content-Type": "application/json",
                access_token: accessToken,
                id_token: idToken
            },
            data: badge
        });
        return res.data;
    } catch (e) {
        console.log(e);
        throw e
    }

}
