import React, { Component } from 'react';
import PresetFeed from './PresetFeed';
// import TrendingCitizensFeed from './TrendingCitizensWidget';
import NewsFeed from './NewsFeed';
import { StickyContainer } from 'react-sticky';

class Feed extends Component {

    render() {

        let user = this.props.user;
        return (
            <StickyContainer>
                <div className="container-fluid newsfeed">
                    <div className="row justify-content-center">
                        <div className="col-xl-11">
                            <div className="row">
                                <NewsFeed />
                                <PresetFeed user={user} />
                                {/* <TrendingCitizensFeed /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </StickyContainer>
        );
    }
}

export default Feed;