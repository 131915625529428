import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "../reducers/all-reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

/** Store Enhancer Section */
// init local storage object
const persistConfig = {
  key: "root",
  storage
};

// local storage reducer
const persistedReducer = persistReducer(persistConfig, createRootReducer());

const allStoreEnhancers = compose(
  applyMiddleware(
    thunk // for async action objects
  ),
  // this line of code enables dev tools for react
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose
);

/** Store initialization section */
export const store = createStore(
  // reducers for store
  persistedReducer,
  // initial state of store
  {
    user: {
      username: "LOADING USERNAME...",
      name: "LOADING NAME...",
      generalInfo: {},
      notifications: [],
      settings:{},
      permissions:[]
    },
    notifications: {
      all:[],
      unseen:[],
      seen:[]
    },
    loggedIn: false,
    easterEggs:{
      day1: false
    }
  },
  allStoreEnhancers
);

export const persistor = persistStore(store);

persistor.purge().then(val => {
  // console.log(val)
});
