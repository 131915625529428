import React, { Component } from 'react';

class Modal extends Component {
    render() {
        let { title, body, onAccept, onReject, modalId, medium, confirmation, yesText = "Yes", noText = "No", blue, noConfirm } = { ...this.props };

        let modalStyle = "modal-dialog modal-dialog-centered";
        let type = "btn-shadow"
        if (medium) {
            // modalStyle += "modal-lg"
        } else {
            modalStyle += "modal-sm";
        }

        if (blue) {
            type = "btn-info"
        }
        let footerButtons;

        if (confirmation) {
            footerButtons =
                <div className="modal-footer">
                    <button type="button" className="btn btn-gradient-01" data-dismiss="modal" onClick={onAccept}>OK</button>
                </div>

        } else {
            footerButtons = <div className="modal-footer">

                <button type="button" className={"btn " + type} data-dismiss="modal" onClick={onReject}>{noText}</button>
                {/* <Button onClick={onReject} type={type} text={noText} />  */}
                <button type="button" className="btn btn-gradient-01" data-dismiss="modal" onClick={onAccept}>{yesText}</button>
            </div>
        }

        if (noConfirm) {
            footerButtons = null;
        }

        return (
            <div id={modalId} className="modal fade">
                <div className={modalStyle}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                        {footerButtons}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;