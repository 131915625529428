import React from 'react';
import DropZone from 'react-dropzone'

function DragAndDrop(props) {
    return (
        <DropZone onDrop={props.onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {

                let classes = "alert alert-outline-info dashed";
                if (isDragActive) {
                    classes = 'alert alert-outline-secondary dashed';
                }

                return (<div className="dragndrop">
                    <div {...getRootProps()} className={classes} role="alert" style={{ height: 200 }}>
                        <input {...getInputProps()} />
                        <p style={{
                            fontSize: "2.5rem",
                            "fontWeight": 600,
                            "textAlign": "center"
                        }}>Click or Drag 'n' Drop here</p>
                    </div>
                </div>)
            }}
        </DropZone>
    );
}

export default DragAndDrop;