import { UPDATE_LOGIN_STATUS, USER_LOGOUT, USER_LOGIN } from '../actions/authentication-actions'

export default function userReducer(state = false, { type, payload }) {

    switch (type) {
        case UPDATE_LOGIN_STATUS:
        case USER_LOGOUT:
        case USER_LOGIN:
            return payload.loggedIn;
        default: return state;
    }
}