import Helmet from "react-helmet";
import React, { Component } from "react";
import { connect } from "react-redux";

class Title extends Component {
  render() {
    let { title = "synthCity" } = { ...this.props };

    if (this.props.title !== "synthCity") {
      title = this.props.title + " | synthCity";
    }

    let notificationCount = this.props.notifications.unseen.length;
    let notificationPart = "";
    if (notificationCount > 0) {
      notificationPart = `(${notificationCount}) `;
    }
    let defaultTitle = "synthCity";
    return (
      <Helmet>
        <title>{title ? notificationPart + title : defaultTitle}</title>
      </Helmet>
    );
  }
}

const mapStateToProps = (state, props) => ({
  notifications: state.notifications
});

export default connect(
  mapStateToProps,
  null
)(Title);
