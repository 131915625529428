import React, { Component } from 'react';
import { getProfilePhoto } from 'services/userwsao';
import defaultPhoto from "assets/img/no-photo.png";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ProfilePhoto extends Component {

    _isMounted = false;

    state = {
        url: defaultPhoto
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.user && this.props.user.profilePhoto && getProfilePhoto(this.props.user.username).then(url => {

            this._isMounted && this.setState({ url });
        }).catch(() => {
            this._isMounted && this.setState({ url: this.props.placeholder })
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let url = this.state.url;
        let styling = "rounded-circle";

        if (this.props.avatar) {
            styling += ' avatar'
        }

        let Photo = () => <img src={url || this.props.placeholder || defaultPhoto} className={styling} alt={`${this.props.user.username}'s profile`} width={this.props.width || 50} />
        let EnrichedPhoto = () => <Photo />

        if (this.props.clickable) {
            EnrichedPhoto = () => <Link to={"/citizen/" + this.props.user.username}><Photo /></Link>
        }
        return (
            <EnrichedPhoto />
        );
    }
}

ProfilePhoto.propTypes = {
    user: PropTypes.object,
    /* URL to image to use in place of this */
    placeholder: PropTypes.string,
    /* If this image should have the avatar class property or not (makes it small i think) */
    avatar: PropTypes.bool,
    /* Make this a clickable image that takes to user's profile */
    clickable: PropTypes.bool
};

export default ProfilePhoto;