import BadgeEngine from 'engines/BadgeEngine';
import { addBadgeToUser } from 'services/badgewsao';
import { store } from 'config/configureStore';
import NotificationEngine from "engines/notification/NotificationEngine"

//Todo to save on api calls add support for multiple badges to be unlocked at once, this will require modifying the existing badge awarding endpoint to accept an array of badges

export function checkPostAchievements() {

    //The current share wont have been counted in the total so increment the current value by 1

    let shares = store.getState().user.shares + 1;

    if(shares >= 1 && !hasBadge(BadgeEngine.Badges.FIRST_UPLOAD)){
        awardBadge(BadgeEngine.Badges.FIRST_UPLOAD)
    }

    if(shares >= 5 && !hasBadge(BadgeEngine.Badges.FIVE_UPLOADS)){
        awardBadge(BadgeEngine.Badges.FIVE_UPLOADS)
    }

    if(shares >= 10 && !hasBadge(BadgeEngine.Badges.TEN_UPLOADS)){
        awardBadge(BadgeEngine.Badges.TEN_UPLOADS)
    }

    if(shares >= 15 && !hasBadge(BadgeEngine.Badges.FIFTEEN_UPLOADS)){
        awardBadge(BadgeEngine.Badges.FIFTEEN_UPLOADS)
    }
}

function hasBadge(badge) {
    let hasBadge = false;
    let badges = store.getState().user.badges;
    badges.forEach((userBadges) => {
        if (badge.name === userBadges.name){
            hasBadge = true
        }
    });
    return hasBadge;
}

async function awardBadge(badge) {
    let user = store.getState().user;
    await addBadgeToUser(user.username,{ name: badge.name }).then(() => {
        NotificationEngine.success(`🏆 You Unlocked a New Badge! "${badge.name}"`)
    });
}
