import React, { Component } from 'react';
import { StickyContainer } from 'react-sticky';
import PresetFeed from 'components/home/PresetFeed';
import TrendingCitizensWidget from 'components/home/TrendingCitizensWidget';
import { Sticky } from 'react-sticky';
import { getSynthWhitelist } from 'services/synthwsao';

class SearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: '',
            synthesizers: [],
            synthesizer: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleOptionChange = function (changeEvent) {
        const name = changeEvent.target.name;

        this.setState({
            [name]: changeEvent.target.value
        });
    }

    componentDidMount() {
        // get synth whitelist from API
        getSynthWhitelist().then(list => {

            let synthesizers = [];

            list.forEach((synth) => {
                synthesizers.push(synth.name[0]);
            });

            this.setState({
                synthesizers
            });

        });
    }

    render() {

        let user = this.props.user;

        const FilterPanel = () => {

            const SynthesizerOptions = () => {
                let options = [];
                options.push(<div className = "form-check" >
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="synthesizer" value="" onClick={this.handleOptionChange} checked={this.state.synthesizer === ''} />All
                    </label>
            </div >);

        this.state.synthesizers.forEach(synth => {
            options.push(<div className="form-check">
                <label className="form-check-label">
                    <input type="radio" className="form-check-input" name="synthesizer" value={synth} onClick={this.handleOptionChange} checked={this.state.synthesizer === synth} />{synth}
                </label>
            </div>);
        });
        return options;
    }
    return(
                <div className = "col-xl-3 column news-feed" >
            <Sticky>
                {({ isSticky }) => {

                    let classes = '';
                    if (isSticky) {
                        classes = 'sticky is-sticky';
                    }

                    return <div className={classes}>
                        <div className="widget no-bg">
                            <h3 className="text-primary mt-4">Filter Results</h3> <br />

                            {/* Begin synthesizer filter */}
                            <p>Synthesizer</p>
                            <SynthesizerOptions />

                            {/* End Synthesizer Filter */}
                            <br />

                            {/* Begin Type Filter */}
                            <p>Type</p>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="" name="type" checked={this.state.type === ''}
                                        onChange={this.handleOptionChange} />All
                                        </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="pad" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'pad'} />pad
                                        </label>
                            </div>
                            <div className="form-check ">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="bass" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'bass'} />bass
                                        </label>
                            </div>
                            <div className="form-check ">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="pluck" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'pluck'} />pluck
                                        </label>
                            </div>
                            <div className="form-check ">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="drums" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'drums'} />drums
                                        </label>
                            </div>
                            <div className="form-check ">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="lead" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'lead'} />lead
                                        </label>
                            </div>
                            <div className="form-check ">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input"
                                        value="other" name="type" onChange={this.handleOptionChange}
                                        checked={this.state.type === 'other'} />other
                                        </label>
                            </div>
                            {/* End Type Filter */}
                        </div>
                    </div>
                }}
            </Sticky>
                </div>);
        }
return (
    <StickyContainer>
        <div className="container-fluid newsfeed">
            <div className="row justify-content-center">
                <div className="col-xl-11">
                    <div className="row">
                        {/* <NewsFeed /> */}
                        <FilterPanel />
                        <PresetFeed user={user} search filter_synthesizer={this.state.synthesizer} filter_type={this.state.type} />
                        <TrendingCitizensWidget />
                    </div>
                </div>
            </div>
        </div>
    </StickyContainer>
);
    }
}

export default SearchPage;