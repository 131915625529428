import { markNotificationAsSeen } from "services/userwsao";

export const MARK_NOTIFICATION_READ = "notifications:markNotificationsRead";
export const MARK_NOTIFICATION_SEEN = "notifications:markNotificationsSeen";
export const UPDATE_NOTIFICATIONS = "notifications:updateNotifications";
export const ADD_NOTIFICATIONS = "notifications:removeNotifications";

export const updateNotifications = newNotifications => {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: {
      notifications: { all: [...newNotifications] }
    }
  };
};

export const updateNotificationSeen = notificationId => {
  return {
    type: MARK_NOTIFICATION_SEEN,
    payload: {
      notificationId
    }
  };
};

export const onNotificationSeen = notification => {
  return dispatch => {
    markNotificationAsSeen(notification._id)
      .then(() => {
        dispatch(updateNotificationSeen(notification._id));
      })
      .catch(err => {
        // TODO handle errors with notifications gracefully.
        console.log("oops something went wrong while marking the notification as seen");
      });
  };
};
