import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import Header from 'components/common/Header';
import ChallengeWidget from './ChallengeWidget';
import TitleController from 'components/common/TitleController';

class ChallengeHistoryTab extends Component {
    render() {

        let createdChallenges = this.props.challenges;

        let CreatedChallengesWidgets = (props, i) => {

            let { challenges } = { ...props };

            let ChallengeWidgets = [];

            challenges.forEach((challenge, i) => {
                ChallengeWidgets.push(<ChallengeWidget key={i} challenge={challenge} />);
            });
            return ChallengeWidgets;

        }

        return (

            <FadeIn>

                <TitleController title="Challenge History" />
                <Header text="Challenge History" />
                {createdChallenges.length < 1 && "You've not created any Challenges"}
                <CreatedChallengesWidgets challenges={createdChallenges} />
            </FadeIn>
        );
    }
}

export default ChallengeHistoryTab;