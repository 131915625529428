import { Howl} from 'howler';
import hello from 'assets/audio/sfx/hello-friend.mp3';
import like from 'assets/audio/sfx/likeButton.mp3';

let SoundEngine = function () {
    this.currentSound = {};
}

SoundEngine.prototype = {

    play: function (source) {

        let self = this;
        let sound;

        // load cached sound if cached
        if (self.currentSound) {
            self.currentSound.stop();

        }
        sound = self.currentSound = new Howl({
            src: [source],
            html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
            onplay: function () {
                // Display the duration.

                // Start upating the progress of the track.

                // Start the wave animation if we have already loaded
            },
            onload: function () {
                // Start the wave animation.
            },
            onend: function () {
                // Stop the wave animation.
            },
            onpause: function () {
                // Stop the wave animation.
            },
            onstop: function () {
                // Stop the wave animation.
            },
            onseek: function () {
                // Start upating the progress of the track.
            }
        });

        // sound primed, play it
        sound.play();

    },
    pause: function () {

    },
    stop: function () {
        let self = this;
        self.currentSound.stop();
    }
}

let engine = new SoundEngine();

let SFX = {
    like, hello

}

export default {
    play: engine.play,
    pause: engine.pause,
    stop: engine.stop,
    SFX
}