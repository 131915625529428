import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { loadAllUsers, sendNotification } from 'services/userwsao';
import ReactTimeAgo from 'react-time-ago';
import TitleController from 'components/common/TitleController';
import NotificationEngine from 'engines/notification/NotificationEngine'
import ProfilePhoto from 'components/common/ProfilePhoto';
import Select from 'react-select';
import BadgeEngine from 'engines/BadgeEngine';
import { addBadgeToUser } from 'services/badgewsao';

class AdminPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            notificationPanel: {
                receiver: '',
                topic: '',
                heading: '',
                message: '',
                link: '',
            },
            showUserList: false
        };

        this.receiverSelect = React.createRef();
        this.badgeReceiverSelect = React.createRef();
        this.badgeItemSelect = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.submitNotificationForm = this.submitNotificationForm.bind(this);
    }

    // handle change of form values bound to state
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            notificationPanel: {
                ...this.state.notificationPanel,
                [name]: value
            }
        });
    }

    submitNotificationForm(e) {
        e.preventDefault();
        let notification = this.state.notificationPanel;
        notification.receiver = this.receiverSelect.current.state.value.value;

        notification.sender = 'Zeeno';

        let broadcast = false;
        if (notification.receiver === 'everyone') {
            broadcast = true;
        }

        sendNotification(notification, broadcast).then(() => {
            NotificationEngine.success("Notification Sent");
        }
        ).catch(() => {
            NotificationEngine.error("Error occurred while sending");
        });
    }

    // Assigns a badge to a particular user
    submitAssignUserBadge(e) {
        e.preventDefault();
        let payload = {};
        payload.receiver = this.badgeReceiverSelect.current.state.value.value;
        payload.badge = this.badgeItemSelect.current.state.value.value;

        if (!BadgeEngine.getBadgeByName(payload.badge)) {
            NotificationEngine.error('Invalid badge', payload.badge);
            return;
        }

        if (!payload.receiver) {
            NotificationEngine.error('Please select a user');
            return;
        }

        // fire to API
        addBadgeToUser(payload.receiver, { name: payload.badge }).then(() => {
            NotificationEngine.success("User " + payload.receiver + " has been awarded the " + payload.badge + " badge successfully.");
        }).catch(err => {
            NotificationEngine.error("Could not assign badge to " + payload.receiver + err);
        })

    }

    componentDidMount() {
        loadAllUsers().then((users) => this.setState({
            users: users.sort((a, b) => {
                if (a.username.toLowerCase() > b.username.toLowerCase()) {
                    return 1;
                } else if (b.username.toLowerCase() > a.username.toLowerCase()) {
                    return -1;
                }
                return 0;
            })
        }));
    }

    render() {

        const UserRows = () => {
            let UserRows = [];

            this.state.users.forEach((user, i) => {
                UserRows.push(
                    <tr key={i}>
                        <td>{user.name}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.dateJoined && <ReactTimeAgo date={new Date(user.dateJoined)} />}</td>
                        <td>{user.lastSignIn && <ReactTimeAgo date={new Date(user.lastSignIn)} />}</td>
                        <td className="td-actions">
                            <a href={`citizen/${user.username}`}><i className="ti ti-user edit"></i></a>
                            {user.profilePhoto && <a href={`citizen/${user.username}`}><ProfilePhoto user={user} width={35} />  </a>}
                            <a href={`citizen/${user.username}`}>{user.shares}</a>
                            {/* <a href="#"><i className="la la-close delete"></i></a> */}
                        </td>
                    </tr>
                )
            });
            return UserRows;
        }

        let usernames = [];
        usernames.push({ value: "everyone", label: "everyone" });

        this.state.users.forEach((user) => {
            usernames.push({ label: user.username, value: user.username });
        });

        let badges = [];

        Object.values(BadgeEngine.Badges).forEach(badge => {
            badges.push({ label: badge.name, value: badge.name });
        });

        return (
            <FadeIn>
                <TitleController title="admin" />
                <section id="admin">

                    <div className="container-fluid">
                        {/* Begin Page Header*/}
                        <div className="row">
                            <div className="page-header">
                                <div className="d-flex align-items-center">
                                    <h2 className="page-header-title">Admin</h2>
                                    <div>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/"><i className="ti ti-home" /></a></li>
                                            <li className="breadcrumb-item active">Admin</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">

                                {/* Start Notification Panel */}
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="widget-body">

                                        {/* Form start */}
                                        <form className="form-horizontal">

                                            <div className="form-group row mb-5">
                                                <label className="col-lg-3 form-control-label">To</label>
                                                <div className="col-lg-6 select mb-3">

                                                    <Select options={usernames} name="user" ref={this.receiverSelect} />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-5">
                                                <label className="col-lg-3 form-control-label">Topic</label>
                                                <div className="col-lg-6 select mb-3">
                                                    <select name="topic" onChange={this.handleChange} value={this.state.notificationPanel.topic} className="custom-select form-control">
                                                        <option value=''>please select</option>
                                                        <option value='system'>System</option>
                                                        <option value='feature'>Feature</option>
                                                        <option value='milestone'>Milestone</option>
                                                        <option value='event'>Event</option>
                                                        <option value='announcement'>Announcement</option>
                                                    </select>
                                                </div>
                                                {/* <div className="col-lg-6 offset-lg-3 select">
                                                    <select multiple className="custom-select form-control">
                                                        <option>option 1</option>
                                                        <option>option 2</option>
                                                        <option>option 3</option>
                                                        <option>option 4</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-3 form-control-label">Heading</label>
                                                <div className="col-lg-6">
                                                    <textarea rows="6" onChange={this.handleChange} value={this.state.notificationPanel.heading} name="heading" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-3 form-control-label">Link</label>
                                                <div className="col-lg-6">
                                                    <input type="text" onChange={this.handleChange} value={this.state.notificationPanel.link} name="link" className="form-control" />
                                                </div>
                                            </div>
                                            {/* <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-3 form-control-label">Message</label>
                                                <div className="col-lg-6">
                                                    <textarea rows="6" onChange={this.handleChange} value={this.state.notificationPanel.message} type="text" className="form-control" name="message" placeholder='Message here' />
                                                </div>
                                            </div> */}
                                            <div className="text-right">
                                                <button className="btn btn-shadow btn-gradient-01 " type="submit" onClick={this.submitNotificationForm}>Send</button>
                                            </div>
                                        </form>
                                        {/* Form end */}
                                    </div>
                                </div>
                                {/* End Notification Panel */}

                                {/* Start Badge Award Panel */}
                                <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Badges</h4>
                                    </div>
                                    <div className="widget-body">

                                        {/* Form start */}
                                        <form className="form-horizontal">

                                            <div className="form-group row mb-5">
                                                <label className="col-lg-3 form-control-label">To</label>
                                                <div className="col-lg-6 select mb-3">

                                                    <Select options={usernames} name="user" ref={this.badgeReceiverSelect} />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-5">
                                                <label className="col-lg-3 form-control-label">Badge Name</label>
                                                <div className="col-lg-6 select mb-3">

                                                    <Select options={badges} name="badge" ref={this.badgeItemSelect} />
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <button className="btn btn-shadow btn-gradient-01 " type="submit" onClick={this.submitAssignUserBadge.bind(this)}>Assign Badge</button>
                                            </div>
                                        </form>
                                        {/* Form end */}
                                    </div>
                                </div>
                                {/* End Badge Award Panel */}

                                <button className="btn bth-gradient-01" onClick={() => this.setState({ showUserList: !this.state.showUserList })}>Show User List</button>

                                {/* User List */}
                                {this.state.showUserList && <div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>User List</h4>
                                    </div>
                                    <div className="widget-body">
                                        <div className="table-responsive">
                                            <table id="sorting-table" className="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Username</th>
                                                        <th>email</th>
                                                        <th>Date Joined</th>
                                                        <th>Last Sign In</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <UserRows />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                                {/* End User List */}
                            </div>
                        </div>
                    </div>
                </section>
            </FadeIn>
        );
    }
}

export default AdminPage;