import Rhythm from 'rythm.js';
import music from 'res/music/rythmC.mp3'

let RhythmPlayer = function () {
    this.rhythm = {}
}

RhythmPlayer.prototype = {

    animate: function () {
        let self = this;
        let animation;

        if (self.rhythm) {
            self.rhythm.stop();
        }

        animation = self.rhythm = new Rhythm();
        animation.setMusic(music);

        animation.addRythm("neon1", 'neon', 0, 10);
        animation.addRythm("kern1", 'kern', 0, 10, {
            min: -2,
            max: 5
        });
        animation.addRythm('jump1', 'jump', 150, 40, {
            min: -10,
            max: 10
        })

        animation.addRythm('blur1', 'blur', 0, 10, {
            reverse: true,
            max: 4
        });

        animation.addRythm('swing1', 'swing', 0, 2);

        animation.addRythm('vanish1', 'vanish', 0, 10);

        animation.start();

    },

    stop: function () {

        let self = this;

        // make sure its initialized
        if (!self.rhythm) {
            self.rhythm = new Rhythm();
        }
        self.rhythm.stop();
    }
}

let engine = new RhythmPlayer();

export default {
    animate: engine.animate,
    stop: engine.stop
};