import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PatchWidget from 'components/patch/PatchWidget';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { getPatchesForUser } from 'services/synthwsao'
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { generateStubPatches } from 'components/common/debug/DebugFunctions';

class UploadHistoryTab extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            next: null,
            hasNext: false,
            hasPrevious: false,
            patches: [],
            page: [],
        };

        this.fetchPatches = this.fetchPatches.bind(this);
        this.generateStub = this.generateStub.bind(this);
    }

    componentDidMount() {
        // this.generateStub();
        this._isMounted = true;
        this.fetchPatches(this.props.user.username);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    generateStub = () => {

        var patchStubList = generateStubPatches();

        let updatedPatches = this.state.patches;
        updatedPatches.push(...patchStubList);

        this.setState({ patches: updatedPatches })
    }
    fetchPatches = () => {
        getPatchesForUser(this.props.user.username, this.state.next)
            .then(patches => {
                let updatedPatches = this.state.patches;
                updatedPatches.push(...patches.results);

                if (this._isMounted) {
                    this.setState({
                        patches: updatedPatches,
                        page: patches.results,
                        hasNext: patches.hasNext,
                        hasPrevious: patches.hasPrevious,
                        next: patches.next
                    });
                }
            })
            .catch(e => { console.log('unable to load next page of patches') });
    };

    onPatchChange = (patchUpdate) => {

        let updatedPatches = [];

        updatedPatches = this.state.patches.map((patch, i) => {
            let newPatch = { ...patch };


            if (patchUpdate._id === patch._id) {
                newPatch = patchUpdate;
            }

            updatedPatches.push(newPatch)
            return newPatch;
        });

        this.setState({ patches: updatedPatches });

    }

    render() {

        let PatchList = () => {
            let list = [];

            // Null check for patches existing
            if (this.state.patches) {

                this.state.patches.forEach((patch, i) => {
                    list.push(
                        <PatchWidget patch={patch} key={i} user={this.props.user} onPatchChange={this.onPatchChange} editable={this.props.editable} />
                    )
                });

            }

            return list;
        }

        return (
            <div>
                <FadeIn>
                    <section id="patch-page">
                        <div className="row">
                            <PatchList />
                        </div>
                        <InfiniteScroll
                            dataLength={this.state.patches.length}
                            next={this.fetchPatches}
                            hasMore={this.state.hasNext}
                            loader={
                                <FadeIn>
                                    <div className="col-xl-12">
                                        <div className="widget has-shadow">
                                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                                <h4>Loading...</h4>
                                            </div>
                                        </div>
                                    </div>
                                </FadeIn>
                            }
                            endMessage={
                                // kinda messy but conditionally display a different message if there's nothing
                                this.state.patches.length === 0 ? <div className="col-xl-12">
                                    <div className="widget has-shadow">
                                        <div className="widget-header bordered no-actions d-flex align-items-center">
                                            <h4><FormattedMessage id="ProfilePage.nouploads"
                                                defaultMessage="Nothing here holmes! Why don't you {upload_something}? {emoji}"
                                                values={{
                                                    emoji: <span role="img" aria-label="smile">🙂</span>,
                                                    upload_something: <Link to="/synthShare"><b><FormattedMessage id="ProfilePage.upload_something" defaultMessage="upload something" /></b></Link>
                                                }} /></h4>
                                        </div>
                                    </div>
                                </div> :
                                    <div className="col-xl-12">
                                        <div className="widget has-shadow">
                                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                                <h4><FormattedMessage id="ProfilePage.nomorepatch" defaultMessage="No more to show! {emoji}" values={{ emoji: <span role="img" aria-label="smile">🙂</span> }} /></h4>
                                            </div>
                                        </div>
                                    </div>
                            }>

                        </InfiniteScroll>

                    </section>
                </FadeIn>
            </div>
        );
    }
}

export default UploadHistoryTab;