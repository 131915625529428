import React, { Component } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { FormattedMessage } from 'react-intl';
var parse = require('url-parse');
class UserDetailsWidget extends Component {

    render() {

        let user = this.props.user;

        var parsedSite = new parse(user.generalInfo.website, 'link', true);

        if(!parsedSite.protocol){
            parsedSite.set('protocol', 'http');
        }
        
        let  websiteEnriched = parsedSite.href;

        // fix bug where if you don't add http it'll try to link as a sub page        
        return (

            <div className="col-xl-5">
                {/* Begin Widget */}
                <div className="widget has-shadow">
                    <div className="widget-header bordered no-actions d-flex align-items-center">
                        <h4><FormattedMessage id="ProfilePage.details" defaultMessage="Deets" /> </h4>
                    </div>

                    <div className="widget-body">
                        {user.settings.hideProfile ?
                            <div className="about-infos d-flex flex-column">
                                <div className="about-text">
                                <FormattedMessage id="ProfilePage.private" defaultMessage="This profile is private {emoji}"  values={{emoji:<span role="img" aria-label="ninja emoji">🐱‍👤</span>}} /> 
                                </div>

                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"></div>
                                    <div className="about-text">
                                    </div>
                                </div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"></div>
                                    <div className="about-text">
                                    </div>
                                </div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"></div>
                                    <div className="about-text">
                                    </div>
                                </div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"></div>
                                    <div className="about-text">
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.aboutme" defaultMessage="About me" />:</div>
                                    <div className="about-text">
                                        {user.generalInfo.aboutMe}
                                    </div>
                                </div>
                                {/* <div className="about-infos d-flex flex-column">
                                                                <div className="about-title">Birthday:</div>
                                                                <div className="about-text">{user.generalInfo.birthday}</div>
                                                            </div> */}
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.livesin" defaultMessage="Lives in"/>:</div>
                                    <div className="about-text">{user.generalInfo.city}</div>
                                </div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.joined" defaultMessage="Joined" />:</div>
                                    <div className="about-text">{user.dateJoined && <ReactTimeAgo date={new Date(user.dateJoined)} />}</div>
                                </div>
                                {/* <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.genres" defaultMessage="Genres" />:</div>
                                    <div className="about-text">{user.generalInfo.genres}</div>
                                </div> */}
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.email" defaultMessage="Email" />:</div>
                                    <div className="about-text"><a href={"mailto:" + user.email}>{user.email}</a></div>
                                </div>
                                <div className="about-infos d-flex flex-column">
                                    <div className="about-title"><FormattedMessage id="ProfilePage.website" defaultMessage="Website" />:</div>
                                    <div className="about-text"><a href={websiteEnriched} rel="noopener noreferrer" target="_blank">{user.generalInfo.website}</a></div>
                                </div>
                            </div>
                            }
                        {/* <div className="about-infos d-flex flex-column">
                                                        <div className="about-title">Phone:</div>
                                                        <div className="about-text">{user.generalInfo.phone}</div>
                                                    </div> */}
                    </div>
                </div>
                {/* End Widget */}
            </div>
        );
    }
}

export default UserDetailsWidget;