
import patron from 'assets/img/badges/patron.png';
import early_access from 'assets/img/badges/early_access.png';
import alpha from 'assets/img/badges/alpha.png';
import synthfam from 'assets/img/badges/synthfam.png';
import synthfounder from 'assets/img/badges/synthfounder.png';
import synthcitizen from 'assets/img/badges/synthcitizen.png';
import uploads_1 from 'assets/img/badges/uploads_1.png';
import uploads_5 from 'assets/img/badges/uploads_5.png';
import uploads_10 from 'assets/img/badges/uploads_10.png';
import uploads_15 from 'assets/img/badges/uploads_15.png';

let Badges = {
    PATRON: {
        name: 'patron',
        img: patron
    },
    EARLY_ACCESS: {
        name: 'early_access',
        img: early_access
    },
    ALPHA: {
        name: 'alpha',
        img: alpha
    },
    SYNTHFAM: {
        name: 'synthfam',
        img: synthfam
    },
    SYNTHCITIZEN: {
        name: 'synthcitizen',
        img: synthcitizen
    },
    SYNTHFOUNDER: {
        name: 'synthfounder',
        img: synthfounder
    },
    FIRST_UPLOAD: {
        name: 'first upload',
        img: uploads_1
    },
    FIVE_UPLOADS: {
        name: 'five uploads',
        img: uploads_5
    },
    TEN_UPLOADS: {
        name: 'ten uploads',
        img: uploads_10
    },
    FIFTEEN_UPLOADS: {
        name: 'fifteen uploads',
        img: uploads_15
    }
    // UPLOADS
    // First upload
    // 5th upload
    // 10 uploads
    // 15 uploads
    // 20 uploads
    // 25 uploads
    // 30 uploads
    // 40 uploads
    // 45 uploads
    // 50 uploads
    // 60 uploads
    // 69 uploads
    // 80 uploads
    // 90 uploads
    // 100 uploads
    // 125 uploads
    // 150 uploads
    // 175 uploads
    // 200 uploads
    // 250 uploads
    // 300 uploads
    // 400 uploads
    // 420 uploads ;-)
    // 500 uploads
    // 600 uploads
    // 777 uploads
    // 800 uploads
    // 900 uploads
    // 1000 uploads
    // 1337 uploads
    // 9001 uploads

    // Requests
    // first request
    // 5th request
    // 10th request
    // 20 requests

    // ANSWERING
    // first Challenge
    // 5th Challenge
    // 10th Challenge
    // 15th Challenge
    // 20th Challenge
    // 25th Challenge
    // 30 Challenge
    // 40 Challenge
    // 45 Challenge
    // 50 Challenge
    // 60 Challenge
    // 69 Challenge
    // 80 Challenge
    // 90 Challenge
    // 100 Challenge
    // 125 Challenge
    // 150 Challenge
    // 175 Challenge
    // 200 Challenge
    // 250 Challenge
    // 300 Challenge
    // 400 Challenge
    // 420 Challenge ;-)
    // 500 Challenge
    // 600 Challenge
    // 777 Challenge
    // 800 Challenge
    // 900 Challenge
    // 1000 Challenge
    // 1337 Challenge
    // 9001 Challenge

}

let getBadgeByName = (name) => {

    let badge = {};
    switch (name) {
        case 'patron': badge = Badges.PATRON;
            break;
        case 'early_access': badge = Badges.EARLY_ACCESS;
            break;
        case 'alpha': badge = Badges.ALPHA;
            break;
        case 'synthfam': badge = Badges.SYNTHFAM;
            break;
        case 'synthcitizen': badge = Badges.SYNTHCITIZEN;
            break;
        case 'synthfounder': badge = Badges.SYNTHFOUNDER;
            break;
        case 'first upload': badge = Badges.FIRST_UPLOAD;
            break;
        case 'five uploads': badge = Badges.FIVE_UPLOADS;
            break;
        case 'ten uploads': badge = Badges.TEN_UPLOADS;
            break;
        case 'fifteen uploads': badge = Badges.FIFTEEN_UPLOADS;
            break;
        default: badge = null;
    }

    return badge;
}

let getBadgeImageByName = (name) => {
    let badge = getBadgeByName(name)
    let img = null;
    if (badge) {
        img = badge.img;
    }
    return img;
}

export default {
    Badges,
    getBadgeByName,
    getBadgeImageByName
}