import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import DragAndDrop from 'components/common/DragAndDrop';
import PatchInfoWidget from './PatchInfoWidget';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { getSynthWhitelist } from 'services/synthwsao';
class UploadPage extends Component {

    state = {
        files: [],
        synthesizers: [],
    }

    onDrop(acceptedFiles) {
        this.setState({ files: acceptedFiles });
    }

    componentDidMount() {
        // get synth whitelist from API
        getSynthWhitelist().then(list => {

            let synthesizers = [];

            list.forEach((synth) => {
                synthesizers.push(synth.name[0]);
            });

            this.setState({
                synthesizers
            });

        });
    }

    render() {

        const patches = [];

        for (let i = 0; i < this.state.files.length; i++) {
            patches.push(<PatchInfoWidget user={this.props.user} key={i} id={i + 1} file={this.state.files[i]} />)
        }

        let SupportedSynthsModalBody = () => {

            let synthNames = [];
            this.state.synthesizers.forEach(synth => {
                synthNames.push(<div>- {synth} <br /></div>);
            });

            return <div>{synthNames} <br /> If your synth isn't listed please use the request button below. Make sure to include the <span className="highlight">Synth Name</span> and <span className="highlight">File Extension</span> <br/><br/> (eg Serum - .fxp)</div>;
        }

        return (
            <FadeIn>
                <section id="upload-page" >
                    <div className="container" >

                        {/* Begin Synth Request Modal */}
                        <Modal title="Supported Synths" yesText="Request Synth" noText="Cancel" onAccept={() => { window.location.href = "mailto:info@mazinomedia.com?subject=" + this.props.user.username + " - Please add this synthesizer to synthCity&body=Synthesizer Name is: %0D%0A %0D%0ASynthesizer's Format is: ";}} body={<SupportedSynthsModalBody />} modalId="synth-list-modal" />               
                        {/* End Request Modal */}

                        {/* Begin Header */}
                        <div className="page-header">
                            <div className="d-flex align-items-center">
                                <h2 className="page-header-title"><FormattedMessage id="SynthShare.title" defaultMessage="Upload Patch" /></h2>
                                <Button data-toggle="modal" data-target="#synth-list-modal" text={<span><i class="la la-question-circle" ></i>Supported Synths</span>} type="info" />
                            </div>
                        </div>
                        {/* End Header */}

                        {this.state.files.length <= 0 && <div className="widget has-shadow">
                            <div className="widget-header bordered no-actions d-flex align-items-center">
                                <h4>Select Patches</h4>
                            </div>
                            <div className="widget-body">
                                <DragAndDrop onDrop={this.onDrop.bind(this)} />
                            </div>
                        </div>}

                        {patches}

                    </div> {/* END header*/}
                </section>
            </FadeIn>
        );
    }

}

UploadPage.propTypes = {

};

export default withRouter(UploadPage);