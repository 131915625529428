import axios from 'axios';
import { retrieveAuthTokens } from 'services/authentication-wsao';
import ImageCache from './ImageCache';

const uri = process.env.REACT_APP_API_URL_SYNTH + process.env.REACT_APP_API_VERSION;

/**
 * Loads user from synthCity API. Requires Logged in user
 * @param {String} username 
 */
export function loadUser(username, isLogin) {
    let url = `${process.env.REACT_APP_API_URL_SYNTH}v1/user/${username}`
    if (isLogin) {
        url += `/?login=${isLogin}`;
    }
    return new Promise((resolve, reject) => {
        let { accessToken, idToken } = retrieveAuthTokens(true);
        axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            reject(err.response);
        }) // end catch
    }) // end promise
}

/**
 * Loads user from synthCity API. Requires Logged in user
 * @param {String} username 
 */
export function loadAllUsers() {
    let url = `${process.env.REACT_APP_API_URL_SYNTH}v1/admin/user/`
    return new Promise((resolve, reject) => {
        let { accessToken, idToken } = retrieveAuthTokens();
        axios({
            url: url,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            reject(err.message);
        }) // end catch
    }) // end promise
}

export async function sendNotification(notification, isBroadcast) {
    let url = `${process.env.REACT_APP_API_URL_SYNTH}v1/admin/notification?broadcast=${isBroadcast}`;

    try {
        let { accessToken, idToken } = retrieveAuthTokens();

        // call notification access endpoint
        await axios({
            url: url,
            method: 'POST',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            },
            data: notification
        });
    } catch (e) {
        console.log(e);
        throw e
    }
}

/**
 * Updates the user's profile information via synthCity API
 * @param {User} user the user to update 
 */
export function updateUserProfile(user) {
    let url = `${process.env.REACT_APP_API_URL_SYNTH}v1/user/${user.username}`
    return new Promise((resolve, reject) => {

        let { accessToken, idToken } = retrieveAuthTokens();
        axios({
            url: url,
            method: 'PATCH',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            },
            data: { userUpdate: user }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            reject(err.message);
        }) // end catch
    }) // end promise
}

export async function uploadProfilePhoto(username, s3Key, file) {
    try {

        let { accessToken, idToken } = retrieveAuthTokens();

        // Get signed URL from API
        let resourceResponse = await axios({
            url: `${uri}user/${username}/photo/upload?s3Key=${s3Key}`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        let uploadURL = resourceResponse.data.url;

        // upload to S3
        await axios({
            url: uploadURL,
            method: 'PUT',
            data: file,
            headers: {
                "Content-Type": file.type
            }
        });

        // updateProfile(key);

        return;

    } catch (e) {
        console.log('Could not upload photo to S3', e);
        throw e;
    }
}

export async function getProfilePhoto(username) {

    try {
        // check if image exists in cache. And load from there
        let cache = ImageCache.get(username);
        if (cache) {
            return cache;
        }


        let { accessToken, idToken } = retrieveAuthTokens();

        // Get signed URL from API
        let resourceResponse = await axios({
            url: `${uri}user/${username}/photo/download`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });

        ImageCache.cache(username, resourceResponse.data.photo);

        return resourceResponse.data.photo;

    } catch (e) {
        console.log('Could not get photo URL', e);        
        throw e;
    }
}

export async function markNotificationAsSeen(notificationId) {
    try {
        let { accessToken, idToken, username } = retrieveAuthTokens();

        await axios({
            url: `${uri}user/${username}/notifications/${notificationId}/markseen`,
            method: 'GET',
            responseType: 'json',
            headers: {
                access_token: accessToken,
                id_token: idToken,
                "Content-Type": "application/json"
            }
        });
        return;
    } catch (e) {
        console.log("Couldn't mark the notification as seen", e);
        throw e;
    }
}