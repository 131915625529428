/**
 * This config file is responsible for setting up configuration relating to 
 * languages
 */
import { addLocaleData } from 'react-intl';
import queryString from 'query-string';

// language localization libraries
import en from "react-intl/locale-data/en";
import nl from "react-intl/locale-data/nl";
import localeData from "res/localization.json";

// time formatting localization libraries
import JavascriptTimeAgo from 'javascript-time-ago';
import time_en from 'javascript-time-ago/locale/en';
import time_nl from 'javascript-time-ago/locale/nl';

// redux, state store etc
import { store } from 'config/configureStore';
import { onLanguageChange } from 'actions/settings-actions';

addLocaleData([...en, ...nl]);

export let language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

// retrieve language setting from the redux store
if (store.getState().settings.lang) {
    language = store.getState().settings.lang;
}

let langFromUrl = queryString.parse(window.location.search, { ignoreQueryPrefix: true }).lang;

// override all settings if passed in url
if (langFromUrl) {
    language = langFromUrl;
}

// TODO REMOVE THIS - I'M HARDCODING TO ENGLISH ALWAYS FOR CONSISTENCY
language = "en";

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to 'en'
export let messages = localeData[languageWithoutRegionCode] ||
    localeData[language] ||
    localeData.en;

// add time formatting  
JavascriptTimeAgo.addLocale(time_en);
JavascriptTimeAgo.addLocale(time_nl);

// set language in global Redux store if never set or if the language is coming from the URL
if (!store.getState().settings.lang || langFromUrl) {
    store.dispatch(onLanguageChange(languageWithoutRegionCode));
}