import * as NotificationActions from "../actions/notificationActions";

export default function notificationReducer(state = { all: [], unseen: [] , seen:[]}, { type, payload }) {
  switch (type) {
    case NotificationActions.MARK_NOTIFICATION_SEEN:
      // get index of notification we want to change
      let index = state.all.findIndex(notification => notification._id === payload.notificationId);

      // create new notification list based on old array
      let newNotifications = { all: [], unseen: [], seen: [] };

      newNotifications.all = state.all.map((notification, i) => {
        let newNotification = { ...notification };

        // update the notification's clone in the new array.
        if (i === index) {
          newNotification.dateSeen = new Date();
        }

        // Filter unseen notification to their own sub array
        if (!newNotification.dateSeen) {
          newNotifications.unseen.push(newNotification);
        } else {
          newNotifications.seen.push(newNotification);
        }

        return newNotification;
      });
      return newNotifications;
    case NotificationActions.UPDATE_NOTIFICATIONS:

      // categorize notifications array
      payload.notifications.unseen = [];
      payload.notifications.seen = [];

      // loop through all notifications and pick those that are unseen
      payload.notifications.all.map(notification => {
        if (!notification.dateSeen) {
          payload.notifications.unseen.push(notification);
        }
        else{
          payload.notifications.seen.push(notification);
        }
        return notification;
      });
      return payload.notifications;
    default:
      return state;
  }
}

// const categorizeNotifications = notifications => {
//   // init unseen notifications array
//   let result = {};
//   result.unseen = [];
//   result.seen = [];

//   // loop through all notifications and pick those that are unseen
//   result.all = notifications.map(notification => {
//     if (!notification.dateSeen) {
//       notifications.unseen.push(notification);
//     } else {
//       notifications.seen.push(notification);
//     }
//     return notification;
//   });
// };
